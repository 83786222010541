import { DollarOutlined, DollarTwoTone, DownloadOutlined, FilterFilled, FilterOutlined, LikeTwoTone, RocketTwoTone, ZoomInOutlined } from '@ant-design/icons';
import { Divider, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import * as mime from 'mime-types';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { getConfirmation, getListOfDeals } from '../../../api/NetworkingDeals';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import useWindowSize from '../../../api/hooks/useWindowResize';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import DatePicker from '../../inputs/DatePicker';
import Dropdown from '../../inputs/Dropdown';
import Input from '../../inputs/Input';
import { Deal } from '../../models/Deal';
import DownloadTableRows from '../../shared/DownloadTableRows';
import ModalFilter from '../../shared/ModalFilter';

const TransactionsTable = (props: any) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [deals, setDeals] = useState<Deal[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(30, 'days').format('YYYY/MM/DD'));
  const [momentFromDate, setMomentFromDate] = useState<Moment>(moment().subtract(30, 'days'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY/MM/DD'));
  const [momentToDate, setMomentToDate] = useState<Moment>(moment());
  const [dealType, setDealType] = useState<string>('all');
  const [dealHeaderID, setDealHeaderID] = useState('');
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const size = useWindowSize();

  //rights
  let OL_Disburse = useOnlineRights(OnlineRightsNames.OL_Disburse);
  let OL_DrawdownAddNew = useOnlineRights(OnlineRightsNames.OL_DrawdownAddNew);

  useEffect(() => {
    let stringDealType = dealType;
    if (dealType === 'all') stringDealType = '';
    getListOfDeals(dealHeaderID, stringDealType, '', fromDate, toDate, JSON.parse(localStorage.getItem('UserSession')!).CustomerID, 0, 1, 0).then((response) => {    
      if (response != null && response.deals != null) {
        response.deals.forEach((deal: any) => {
          deal.key = deal.DealID;
        });
        setDeals(response.deals);
      } else {
        setDeals([])
      }
    });
  }, [updating]);

  const IconDealStatus = (status: string) => {
    switch (status) {
      case 'Pending for Approval':
        return (
          <Tooltip title={status} color="#f90" className="column-actions">
            <LikeTwoTone twoToneColor="#f90" />
          </Tooltip>
        );
      case 'Pending':
        return (
          <Tooltip title={status} color="#ff0000" className="column-actions">
            <LikeTwoTone twoToneColor="#ff0000" />
          </Tooltip>
        );
      case 'Accepted':
        return (
          <Tooltip title={status} color="#52c41a" className="column-actions">
            <LikeTwoTone twoToneColor="#52c41a" />
          </Tooltip>
        );
      case 'Voided':
        return (
          <Tooltip title={status} color="#808080" className="column-actions">
            <LikeTwoTone twoToneColor="#808080" />
          </Tooltip>
        );
      case 'Work in Progress':
        return (
          <Tooltip title={status} color="#1f94a1" className="column-actions"> 
            <LikeTwoTone twoToneColor="#1f94a1" />
          </Tooltip>
        );
    }
  };

  const IconDealPaidStatus = (status: string) => {
    switch (status) {
      case 'Partially Paid':
        return (
          <Tooltip title={status} color="#f90" className="column-actions">
            <DollarTwoTone twoToneColor="#f90" />
          </Tooltip>
        );
      case 'Unpaid':
        return (
          <Tooltip title={status} color="#ff0000" className="column-actions">
            <DollarTwoTone twoToneColor="#ff0000" />
          </Tooltip>
        );
      case 'Fully Paid':
        return (
          <Tooltip title={status} color="#52c41a" className="column-actions">
            <DollarTwoTone twoToneColor="#52c41a" />
          </Tooltip>
        );
    }
  };

  const IconDealDisbursementStatus = (status: string) => {
    switch (status) {
      case 'Partially Disbursed':
        return (
          <Tooltip title={status} color="#f90" className="column-actions">
            <RocketTwoTone twoToneColor="#f90" />
          </Tooltip>
        );
      case 'Not Disbursed':
        return (
          <Tooltip title={status} color="#ff0000" className="column-actions">
            <RocketTwoTone twoToneColor="#ff0000" />
          </Tooltip>
        );
      case 'Fully Disbursed':
        return (
          <Tooltip title={status} color="#52c41a" className="column-actions">
            <RocketTwoTone twoToneColor="#52c41a" />
          </Tooltip>
        );
    }
  };

  const columns: ColumnsType<Deal> = [
    {
      title: size[0] > 768 ? getResource('Table_TH_DealNo') : getResource('Deal'),
      dataIndex: 'DealID',
      key: 'deal_dealid',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.DealID - b.DealID
    },
    {
      title: getResource('Table_TH_Type'),
      dataIndex: 'DealType',
      key: 'deal_dealtype',
      align: 'center',
      sorter: (a, b) => a.DealType.localeCompare(b.DealType),
      responsive: ['md']
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_ValueFarDate') : getResource('Table_TH_Date'),
      dataIndex: 'ValueDate',
      key: 'deal_valuedate',
      align: 'center',
      className: 'cell-date',
      sorter: (a, b) => new Date(a.ValueDate).getTime() - new Date(b.ValueDate).getTime()
    },
    {
      title: getResource('Table_TH_IssueDate'),
      dataIndex: 'IssuedDate',
      key: 'deal_issuedDate',
      align: 'center',
      sorter: (a, b) => new Date(a.IssuedDate).getTime() - new Date(b.IssuedDate).getTime(),
      responsive: ['md']
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_BuyAmount') : getResource('Label.Buy'),
      dataIndex: 'SellCurrency',
      key: 'deal_buyamount',
      align: 'center',
      sorter: (a, b) => a.SellAmount! - b.SellAmount!,
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.SellAmount, obj.SellCurrency)} {obj.SellCurrency}
        </span>
      )
    },
    {
      title: getResource('Table_TH_Rate'),
      dataIndex: 'CustomerRate',
      key: 'deal_customerrate',
      align: 'center',
      sorter: (a, b) => a.CustomerRate! - b.CustomerRate!,
      responsive: ['md'],
      render: (value: any, obj: any, index: any) => <span>{obj.CustomerRate}</span>
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_SellAmount') : getResource('Label.Sell'),
      dataIndex: 'BuyCurrency',
      key: 'deal_sellamount',
      align: 'center',
      sorter: (a, b) => a.BuyAmount! - b.BuyAmount!,
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.BuyAmount, obj.BuyCurrency)} {obj.BuyCurrency}
        </span>
      )
    },
    {
      title: getResource('Table_TH_Status'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <div className="column-actions">
          {IconDealStatus(obj.Status)}
          {IconDealPaidStatus(obj.DealPaidStatus)}
          {IconDealDisbursementStatus(obj.DealDisbursementStatus)}
        </div>
      ),
      responsive: ['md']
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('DownloadConfirmation')}>
            <Typography.Link
              className="column-actions"
              onClick={
                async () => {
                  showLoadingModal();
                  await getConfirmation(obj.DealID).then((value) => {
                    const httpStatusCode = value.httpStatusCode;
                    switch (httpStatusCode) {
                      case 200:
                        if (value != null && value.data != null) {
                          let fileName = 'Deal Confirmation ' + obj.DealID + '.pdf';
                          var byteCharacters = atob(value.data);
                          var byteNumbers = new Array(byteCharacters.length);
                          for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                          }
                          var bytes = new Uint8Array(byteNumbers);
                          var blob = new Blob([bytes], {
                            type: mime.lookup(fileName) as string
                          });
                          var link = document.createElement('a');
                          link.href = window.URL.createObjectURL(blob);
                          link.download = fileName;
                          link.click();
                        }
                        break;
                      case 500:
                      case 503:
                        showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + value.Message);
                        break;
                      default:
                        showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
                        break;
                    }
                    hideLoadingModal();
                  });
                }}
              disabled={obj.Status !== 'Accepted'}
            >
              <DownloadOutlined />
            </Typography.Link>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={getResource('Js_Link_ViewDeal')}>
            <Typography.Link
              className="column-actions"
              onClick={() => {
                selectRow(obj);
              }}
            >
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
          {(obj.OutstandingBalance > 0 && obj.Status !== 'Voided') && (
            <>
              <Divider type="vertical" />
              {((OL_Disburse && (obj.DealType === 'Spot' || obj.ForwardType === 'Closed')) || (OL_DrawdownAddNew && obj.ForwardType === 'Open'))
                && <Tooltip title={obj.DealType === 'Forward' && (obj.ForwardType != null && obj.ForwardType === 'Open') ? getResource('Js.Link.Drawdown') : getResource('Js.Link.Disburse')}>
                  <Typography.Link
                    className="column-actions"
                    onClick={obj.DealType === 'Forward' && (obj.ForwardType != null && obj.ForwardType === 'Open') ?
                      () => {
                        props.selectToDrawdown(obj);
                      } :
                      () => {
                        props.selectToDisburse(obj);
                      }}
                  >
                    <DollarOutlined />
                  </Typography.Link>
                </Tooltip>}
            </>
          )}
        </>
      )
    }
  ];

  const selectRow = (record: any) => {
    props.selectRow(record.DealID);
  };

  const onResetFilters = () => {
    setDealHeaderID('');
    setFromDate(moment().subtract(30, 'days').format('YYYY/MM/DD'));
    setMomentFromDate(moment().subtract(30, 'days'));
    setToDate(moment().format('YYYY/MM/DD'));
    setMomentToDate(moment());
    setDealType('all');
    setUpdating(!updating);
  };

  const onChangeDealHeaderID = (event: any) => {
    setDealHeaderID(event.target.value);
  };
  const onChangeFromDate = (date: Moment | null, dateString: string) => {
    setFromDate(dateString);
    setMomentFromDate(date as Moment);
  };

  const onChangeToDate = (date: Moment | null, dateString: string) => {
    setToDate(dateString);
    setMomentToDate(date as Moment);
  };

  const dropdownDealTypeChangeHandler = (item: any, name: string) => {
    setDealType(item.value);
  };

  const closeFilter = () => {
    setOpenFilter(false);
  }

  const filtered = (dealHeaderID && dealHeaderID !== '0') || momentFromDate || momentToDate || dealType

  const filterOptions = <>
    {(!dealHeaderID || dealHeaderID === '0') && !momentFromDate && !momentToDate && !dealType && getResource('Title.FilterOptions')}
    {dealHeaderID && dealHeaderID !== '0' && <span className="input-label">{getResource('Label.DealID')}: {dealHeaderID}</span>}
    {momentFromDate && <span className="input-label">{getResource('FromIssueDate')}: {fromDate}</span>}
    {momentToDate && <span className="input-label">{getResource('ToIssueDate')}: {toDate}</span>}
    {dealType && <span className="input-label">{getResource('Table_TH_DealType')}: {dealType}</span>}
  </>

  const filter = <>
    <div>
      <div>
        <div className="row">
          <span className="input-label">{getResource('Label.DealID')}:</span>
        </div>
        <div className="row">
          <Input type='text' value={dealHeaderID} onChange={onChangeDealHeaderID} />
        </div>
      </div>
      <div>
        <div className="row">
          <span className="input-label">{getResource('FromIssueDate')}:</span>
        </div>
        <div>
          <DatePicker
            id="ratewatch-datepicker-fromdate"
            key="ratewatch-datepicker-fromdate"
            onChange={onChangeFromDate}
            value={momentFromDate}
            submit={true}
            errorMessage={"Required"}
            valueValidation={fromDate}
          />
        </div>
      </div>
      <div>
        <div className="row">
          <span className="input-label">{getResource('ToIssueDate')}:</span>
        </div>
        <div className="row">
          <DatePicker
            id="ratewatch-datepicker-todate"
            key="ratewatch-datepicker-todate"
            onChange={onChangeToDate}
            value={momentToDate}
            submit={true}
            errorMessage={"Required"}
            valueValidation={toDate}
          />
        </div>
      </div>
      <div>
        <div className="row">
          <span className="input-label">{getResource('Table_TH_DealType')}:</span>
        </div>
        <div className="row">
          <Dropdown
            name="settlementMethod"
            title="Select Settlement Method..."
            list={[
              { value: 'all', label: 'All' },
              { value: 'Spot', label: 'Spot' },
              { value: 'Forward', label: 'Forward' }
            ]}
            onChange={dropdownDealTypeChangeHandler}
            select={{ value: dealType }}
          />
        </div>
      </div>
      <div className='row-actions'>
        <Button type="primary" onClick={() => { setUpdating(!updating); closeFilter() }}>
            {getResource('ApplyFilter')}
        </Button>
        <Button type="secondary" onClick={() => { onResetFilters(); closeFilter() }}>
            {getResource('Reset')}
        </Button>
      </div>
    </div>
  </>

  return (
    <React.Fragment>
      <div className="row-actions-1">
        <DownloadTableRows data={deals} fileName={'transactions'} />
        <Tooltip title={filterOptions}>
          <Typography.Link onClick={() => setOpenFilter(true)} style={{ marginRight: '10px' }}>
            {filtered && <FilterFilled />}
            {!filtered && <FilterOutlined />}
          </Typography.Link>
        </Tooltip>

      </div>
      <Table
        className=""
        key={'transactions-table'}
        columns={columns}
        dataSource={deals}
      />
      <ModalFilter open={openFilter} close={closeFilter} panel={filter} title={getResource('Title.FilterOptions')} />
    </React.Fragment>
  );
};

export default TransactionsTable;
