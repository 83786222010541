import { DollarCircleOutlined, FilterFilled, FilterOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Divider, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { getOnlineBeneficiaryPayments } from '../../../api/NetworkingBeneficiaries';
import { formatToCurrency } from "../../../helpers/FormatHelper";
import { getResource } from "../../../helpers/ResourcesHelper";
import Button from '../../inputs/Button';
import DatePicker from '../../inputs/DatePicker';
import { BeneficiaryPayment } from '../../models/BeneficiaryPayment';
import DownloadTableRows from "../../shared/DownloadTableRows";
import ModalFilter from "../../shared/ModalFilter";

const PaymentsTable = (props: any) => {

  const [beneficiaryID, setBeneficiaryID] = useState(props.beneficiaryID);
  const [payments, setPayments] = useState([]);
  const userSession = JSON.parse(localStorage.getItem('UserSession')!);
  const [updating, setUpdating] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>(moment().subtract(30, 'days').format('YYYY/MM/DD'));
  const [momentFromDate, setMomentFromDate] = useState<Moment>(moment().subtract(30, 'days'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY/MM/DD'));
  const [momentToDate, setMomentToDate] = useState<Moment>(moment());
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const selectRow = (record: any) => {
    props.selectRow(record.BeneficiaryPaymentID);
  };

  const selectDealRow = (record: any) => {
    props.selectDealRow(record.DealID);
  };

  const columns: ColumnsType<BeneficiaryPayment> = [
    {
      title: getResource('Label.PaymentID'),
      dataIndex: 'BeneficiaryPaymentID',
      key: 'BeneficiaryPaymentID',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.BeneficiaryPaymentID - b.BeneficiaryPaymentID
    },
    {
      title: getResource('Table_TH_DealID'),
      dataIndex: 'DealID',
      key: 'DealID',
      align: 'center',
      sorter: (a, b) => a.DealID - b.DealID
    },
    {
      title: getResource('Table_TH_ValueDate'),
      dataIndex: 'ValueDate',
      key: 'ValueDate',
      align: 'center',
      sorter: (a, b) => new Date(a.ValueDate).getTime() - new Date(b.ValueDate).getTime()
    },
    {
      title: getResource('Table_TH_Amount'),
      dataIndex: 'Amount',
      key: 'Amount',
      align: 'center',
      sorter: (a, b) => a.Amount - b.Amount,
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.Amount, obj.Currency)} {obj.Currency}
        </span>
      )
    },
    {
      title: getResource('Table_TH_Instrument'),
      dataIndex: 'Instrument',
      key: 'Instrument',
      align: 'center',
      sorter: (a, b) => a.Instrument.localeCompare(b.Instrument),
      responsive: ['md']
    },
    {
      title: getResource('Table_TH_Status'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      sorter: (a, b) => a.Status.localeCompare(b.Status)
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('Js_Link_ViewPayment')}>
            <Typography.Link
              className="column-actions"
              onClick={() => {
                selectRow(obj);
              }}
            >
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
          <Tooltip title={getResource('Js_Link_ViewDeal')}>
            <Typography.Link
              className="column-actions"
              onClick={() => {
                selectDealRow(obj);
              }}
            >
              <DollarCircleOutlined />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];

  const onResetFilters = () => {
    setFromDate(moment().subtract(30, 'days').format('YYYY/MM/DD'));
    setMomentFromDate(moment().subtract(30, 'days'));
    setToDate(moment().format('YYYY/MM/DD'));
    setMomentToDate(moment());
    setUpdating(!updating);
  };

  const onChangeFromDate = (date: Moment | null, dateString: string) => {
    setFromDate(dateString);
    setMomentFromDate(date as Moment);
  };

  const onChangeToDate = (date: Moment | null, dateString: string) => {
    setToDate(dateString);
    setMomentToDate(date as Moment);
  };

  const loadPayments = () => {
    if (userSession != null) {
      getOnlineBeneficiaryPayments(beneficiaryID, fromDate, toDate).then((response) => {
        setPayments(response.BeneficiaryPayments);
      });
    }
  }

  useEffect(() => {
    loadPayments();
  }, [updating]);

  useEffect(() => {
    setBeneficiaryID(props.beneficiaryID);
    loadPayments();
  }, [props.beneficiaryID]);

  const closeFilter = () => {
    setOpenFilter(false);
  }

  const onExitClick = () => {
    if (props.onBack) {
      props.onBack();
    }
  }

  const filtered = momentFromDate || momentToDate

  const filterOptions = <>
    {!momentFromDate && !momentToDate && getResource('Title.FilterOptions')}
    {momentFromDate && <span className="input-label">{getResource('FromIssueDate')}: {fromDate}</span>}
    {momentToDate && <span className="input-label">{getResource('ToIssueDate')}: {toDate}</span>}
  </>

  const filter = <>
    <div>
      <div>
        <div className="row">
          <span className="input-label">{getResource('FromIssueDate')}:</span>
        </div>
        <div className="row">
          <DatePicker
            id="ratewatch-datepicker-fromdate"
            key="ratewatch-datepicker-fromdate"
            onChange={onChangeFromDate}
            value={momentFromDate}
            submit={true}
            errorMessage={"Required"}
            valueValidation={fromDate}
          />
        </div>
      </div>
      <div>
        <div className="row">
          <span className="input-label">{getResource('ToIssueDate')}:</span>
        </div>
        <div className="row">
          <DatePicker
            id="ratewatch-datepicker-todate"
            key="ratewatch-datepicker-todate"
            onChange={onChangeToDate}
            value={momentToDate}
            submit={true}
            errorMessage={"Required"}
            valueValidation={toDate}
          />
        </div>
      </div>
      <div className="row-actions">
        <Button type="primary" onClick={() => { setUpdating(!updating); closeFilter(); }}>
          {getResource('ApplyFilter')}
        </Button>
        <Button type="secondary" onClick={() => { onResetFilters(); closeFilter(); }}>
          {getResource('Reset')}
        </Button>
      </div>
    </div>
  </>

  return (
    <React.Fragment>
      <div className="row-actions-1">
        <DownloadTableRows data={payments} fileName={'payments'} />
        <Tooltip title={filterOptions}>
          <Typography.Link onClick={() => setOpenFilter(true)} style={{ marginRight: '10px' }}>
            {filtered && <FilterFilled />}
            {!filtered && <FilterOutlined />}
          </Typography.Link>
        </Tooltip>
      </div>
      <Table
        className=""
        key={'transactions-table'}
        columns={columns}
        dataSource={payments}
      />
      <Divider />
      <div className="row-actions">
        <Button type="secondary" onClick={onExitClick}>
          {getResource('Btn_Exit')}
        </Button>
      </div>
      <ModalFilter title={getResource('Title.FilterOptions')} open={openFilter} close={closeFilter} panel={filter} />
    </React.Fragment>
  );
}

export default PaymentsTable;