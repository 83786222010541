import { Badge, Button, Dropdown, MenuProps, Space } from "antd";
import React, { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import AppContext from "../../../App.context";
import { getListOfRelatedCustomers } from "../../../api/NetworkingCustomers";
import { logout, updateSession } from '../../../api/NetworkingSecurity';
import useOnlineRights from "../../../api/hooks/useOnlineRights";
import { OnlineRightsNames } from "../../../helpers/OnlineRightsHelper";
import { getCompanyLogo } from "../../../helpers/PackageJsonHelper";
import { getResource } from "../../../helpers/ResourcesHelper";
import DropdownCustom from '../../inputs/Dropdown';
import CNavBar from "../navbar/CNavBar";

const logo = getCompanyLogo();

interface BrandBarProps {
  onLogout: Function;
  onChangeScreen: Function;
  onChangeTab: Function;
  onClickProfile: Function;
  onUpdateSession: (newSession: any) => void;
  currentScreen: string;
  onlineRights: string;
  messagesNumber: number;
}

const BrandBar: FunctionComponent<BrandBarProps> = (props) => {
  const userSession = useMemo(() => JSON.parse(localStorage.getItem('UserSession')!), []);
  const [customerID, setCustomerID] = useState(userSession.CustomerID.toString());
  const [relatedCustomers, setRelatedCustomers] = useState([]);
  const { showLoadingModal, hideLoadingModal, updateMessages } = useContext(AppContext);
  const [messages, setMessages] = useState(0);

  let OL_MyAccountInformation = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation);
  let OL_BeneficiaryUser = useOnlineRights(OnlineRightsNames.OL_BeneficiaryUser);

  useEffect(() => {
    const getRelatedCustomers = async () => {
      const json = await getListOfRelatedCustomers(userSession.CustomerID);
      if (json != null && json.Customers != null) {
        const customers = json.Customers.map((c: any) => ({ ...c, value: c.CustomerID.toString(), label: c.Name, status: c.Status }));
        setRelatedCustomers(customers);
      }
    };
    getRelatedCustomers();
    updateMessages();
  }, [userSession.CustomerID]);

  useEffect(() => {
    setMessages(props.messagesNumber ? props.messagesNumber : 0);
  }, [props.messagesNumber])

  const onChangeCustomer = async (value: any) => {
    if (value != null && value.value != null) {
      if (value.value !== customerID) {
        showLoadingModal();
        const json = await updateSession(value.value, userSession.InstallationID);
        if (json != null && json.OnlineUserSession !== null) {
          props.onUpdateSession(json.OnlineUserSession);
        }
        setCustomerID(value.value);
        hideLoadingModal();
      }
    }
  };
  const onChangeScreenD = () => {
    props.onChangeScreen('dashboard');
  }

  const onChangeScreen = (screen: string) => {
    props.onChangeScreen(screen);
  } 

  const onLogoutClick = () => {
    logout().then((logoutResponse: any) => {
      localStorage.clear();
      props.onLogout();
    })
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {

    if (e.key === '1') {
      props.onChangeScreen('profile');
      props.onChangeTab('my-credentials');
    }
    else if (e.key === '2') {
      props.onChangeScreen('profile');
      props.onChangeTab('my-information');
    }
    else if (e.key === '3') {
      props.onChangeScreen('profile');
      props.onChangeTab('required-documents');
    }
    else if (e.key === '4') {
      props.onChangeScreen('profile');
      props.onChangeTab('my-messages');
    }
    else if (e.key === '5') {
      props.onChangeScreen('profile');
      props.onChangeTab('my-defaults');
    }
    else if (e.key === '6') {
      onLogoutClick();
    }
  };



  const getMyAccountMenu = () => {

    let items: MenuProps['items'] = []

    items.push({
      label: getResource('Tab_MyCredentials'),
      key: '1'
    })
    if (OL_MyAccountInformation) {
      items.push({
        label: getResource('Tab_MyInformation'),
        key: '2'
      })
    }
    if (OL_BeneficiaryUser) {
      items.push({
        label: getResource('Tab_RequiredDocumments'),
        key: '3'
      })
    }

    items.push({
      label: <Badge count={messages} size="small" offset={[15, 8]}>
        <Space>{getResource('Tab_MyMessages')}</Space></Badge>,
      key: '4'
    })

    if (OL_BeneficiaryUser) {
      items.push({
        label: getResource('Tab_MyAccountDefaults'),
        key: '5'
      })
    }
    items.push(
      {
        label: getResource('Login.Logout'),
        key: '6'
      }
    )

    const menuProps = {
      items: items,
      onClick: handleMenuClick,
    };
/*<a onClick={() => {
      props.onChangeScreen('profile');
      props.onChangeTab('my-messages');
    }}>
      */
    return <Badge count={messages} title={getResource('Tab_MyMessages')}>
        <Space>
      <Dropdown menu={menuProps}>
        <Button className="buttonMenu">
          <Space>
            <p className="profile-contact-name">{userSession.ContactName}</p>
          </Space>
        </Button>
      </Dropdown>
    </Space>
    </Badge>

  }

  return (
    <React.Fragment>
      <div className="row brandbar">
        <div className="col-md-1-cnv col-xs-1-cnv none-large">
          <CNavBar onChangeScreen={onChangeScreen} currentScreen={props.currentScreen}
            onlineRights={props.onlineRights} />
        </div>
        <div className="col-md-3 none-medium">
          <span><a href="#"><img className="col-xs-8" src={logo} alt="" onClick={onChangeScreenD} /></a></span>
        </div>
        <div className="col-xs-4 none-medium"></div>
        <div className="col-md-4 col-xs-7" style={{ paddingTop: '15px', marginRight: '-5px', textAlign: 'right' }}>
          <div className="row">
            <div className="col-md-3 none-small" style={{ paddingTop: '5px', textAlign: 'right', cursor: 'default' }}>
              <p className="profile-contact-name none-small">{getResource('Label.SwitchCompany')}</p>
            </div>
            <div className="col-md-5 none-small">
              <DropdownCustom key={'related-customers'} name="related-customers" title="Select a related customer..." select={{ value: customerID }} list={relatedCustomers} onChange={(value: any) => onChangeCustomer(value)} />
            </div>
            <div className="col-md-3 col-xs-12" onClick={() => props.onClickProfile()}>
            {getMyAccountMenu()}            
            </div>       
          </div>   
        </div>
        {/* <div className="col-md-1 col-xs-2 end-xs">
          <span className="material-icons-round navbar-icon profile-logout" onClick={() => onLogoutClick()}>logout</span>
        </div>
         */}

      </div>
    </React.Fragment>
  );
};

export default BrandBar;