import { AutoComplete } from "antd";
import { useEffect, useState } from "react";
import { getAllCountries, getCountryByName } from "../../../../../api/NetworkingCountries";
import useStates from "../../../../../api/hooks/useStates";
import { formatValidInputClass, validInputClass } from "../../../../../helpers/FormatHelper";
import { getResource } from "../../../../../helpers/ResourcesHelper";
import Button from "../../../../inputs/Button";
import Dropdown from "../../../../inputs/Dropdown";
import Input from "../../../../inputs/Input";

export interface ContactAddress
{
  Address1  : string,
  Address2  : string,
  City      : string,
  PostalCode: string,
  Country   : string,
  State     : number,
}

const AddressStep = (props: any) => {

  const [address1, setAddress1] = useState<string>('');
  const [address2, setAddress2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [postalZipCode, setPostalZipCode] = useState<string>('');
  const [countrySelected, setCountrySelected] = useState<string>('');
  const [provinceStateSelected, setProvinceStateSelected] = useState<number>(0);
  const [validations, setValidations] = useState({
    address1: true,
    city: true,
    postalZipCode: true,
    countrySelected: true,
    provinceStateSelected: true,
  });

  //const [countries] = useCountries();
  const states = useStates(countrySelected);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if ((props.do === 'edit' && props.contact !== null) || (props.do === 'add' && props.contact !== null))
    {
      const countryName = props.contact.CountryId.includes('-') ? props.contact.CountryId : (props.contact.CountryId !== '' && props.contact.CountryName !== '' ? props.contact.CountryId + ' - ' + props.contact.CountryName : '');
      setAddress1(props.contact.AddressValue);
      setAddress2(props.contact.Address2);
      setCity(props.contact.City);
      setPostalZipCode(props.contact.ZipCode);
      setCountrySelected(countryName);
      if (props.validForm) {
        setValidations({
          address1: validInputClass(props.contact.AddressValue),
          city: validInputClass(props.contact.City),
          postalZipCode: validInputClass(props.contact.ZipCode),
          countrySelected: validInputClass(countryName),
          provinceStateSelected: validInputClass(props.contact.StateId.toString()),
        });
      }
      
      if(props.contact.StateId !== undefined && props.contact.StateId !== 0)
      {
        setProvinceStateSelected(props.contact.StateId);
      }
      const loadCountries = async () => {
        const json = await getAllCountries();
        if (json != null && json.countries != null) {

          const formattedCountries = json.countries.map((c: any) => ({ ...c, label: `${c.Country} - ${c['Country Name']}`, value: c.Country }));
          formattedCountries.push({ label: getResource('SelectCountry'), value: '' });
          setCountries(formattedCountries);
        }
      }
      loadCountries();
    }
  }, [props.contact]);

  const inputsActions = (event:any, type:string) => {
    let objSetters: { [key: string]: any } = {
      ['address1']: () => {
        setValidations({ ...validations, address1: true });
        setAddress1(event.target.value);
      },
      ['address2']      : () => {setAddress2(event.target.value);},
      ['city']: () => {
        setValidations({ ...validations, city: true });
        setCity(event.target.value);
      },
      ['postalzipcode']: () => {
        setValidations({ ...validations, postalZipCode: true });
        setPostalZipCode(event.target.value);
      },
    }
    return(objSetters[type]())
  }

  const dropdownsActions = (item: any, type: string, option?: any) =>
  {
    let objSetters: { [key: string]: any } = {
      ['country'] : () => {
        if (option != null && !option.label.includes('Select')) {
          setValidations({ ...validations, countrySelected: true });
          setCountrySelected(option.label);
        }
      },
      ['state']: () => {
        setValidations({ ...validations, provinceStateSelected: true });
        setProvinceStateSelected(item.value);
      },
    }
    return(objSetters[type]())
  }

  const onSearch = (value: any) => {
    setCountrySelected(value);
    let newValue = value.split(' -');
    getCountryByName(newValue[0]).then(
      (json) => {
        if (json.countries != null) {
          const formattedCountries = json.countries.map((c: any) => ({ ...c, label: c.CountryIdName, value: c.CountryId }));
          //formattedCountries.push({ label: 'Select a Country...', value: '' });
          setCountries(formattedCountries);
        }
      }
    )
  }

  const validateFields = () => {
    let address1Valid       : boolean = true;
    let cityValid           : boolean = true;
    let postalZipCodeValid  : boolean = true;
    let countryValid        : boolean = true;
    let stateValid          : boolean = true;

    if(address1 === ''){address1Valid = false;}
    if(city === ''){cityValid = false;}
    if(postalZipCode === ''){postalZipCodeValid = false;}
    if(countrySelected === ''){countryValid = false;}
    if(provinceStateSelected === 0){stateValid = false;}

    setValidations({
      address1: validInputClass(address1),
      city: validInputClass(city),
      postalZipCode: validInputClass(postalZipCode),
      countrySelected: validInputClass(countrySelected),
      provinceStateSelected: validInputClass(provinceStateSelected.toString()),
    });

    return(
      address1Valid       &&
      cityValid           &&
      postalZipCodeValid  &&
      countryValid        &&
      stateValid
    );
  }

  return (
    <>
      <div className="row wizard-container-header">
        <div className='col-md-2 col-xs-0'> </div>
        <div className='col-md-8 col-xs-12'>

          <div className="row center-xs">
          {/*Address 1*/}
            <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.Address1')} (*)</span>
            </div>
            <div className="row">
              <Input 
                id="contact-address-1"
                type     = 'text' 
                value    = {address1} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'address1')
                }}
                className={formatValidInputClass(validations.address1)}
              />
            </div>
          </div>
          {/*Address 2*/}
            <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.Address2')}</span>
            </div>
            <div className="row">
              <Input
                id="contact-address-2"
                type     = 'text' 
                value    = {address2} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'address2')
                }}
              />
            </div>
            </div>         
          </div>

          <div className="row center-xs">
            {/*City*/}
            <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.City')} (*)</span>
            </div>
            <div className="row">
              <Input
                id="contact-city"
                type     = 'text' 
                value    = {city} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'city')
                }}
                className={formatValidInputClass(validations.city)}
              />
            </div>
            </div>
          {/*Postal Zip Code*/}
            <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.PostalZip')} (*)</span>
            </div>
            <div className="row">
              <Input
                id="contact-zipcode"
                type     = 'text' 
                value    = {postalZipCode} 
                onChange = {(event:any)=>{
                  inputsActions(event, 'postalzipcode')
                }}
                className={formatValidInputClass(validations.postalZipCode)}
              />
            </div>
          </div>
          {/*Country*/}
            <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.Country')} (*)</span>
            </div>
            <div className="row">
              {/* <Dropdown
                id="contact-country"
                name          = "address-country"
                title         = {'Select a Country...'}
                list          = {countries} select={{ value: countrySelected }}
                onChange      = {(item: any) =>{dropdownsActions(item, 'country')}}
              /> */}
              <AutoComplete
                allowClear
                options={countries}
                className={formatValidInputClass(validations.countrySelected)}
                placeholder={getResource('SelectCountry')}
                notFoundContent={getResource('PlaceHolder.NoResults')}
                value={countrySelected}
                onSelect={(e: any, option: any) => dropdownsActions(e, 'country', option)}
                onSearch={onSearch}
              />
            </div>
          </div>
          {/*Province State*/}
            <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.ProvinceState')} (*)</span>
            </div>
            <div className="row">
              <Dropdown 
                id="contact-state"
                name          = "address-state"
                title={getResource('SelectState')}
                list          = {states} 
                select        = {{ value: provinceStateSelected }}
                onChange      = {(item: any) =>{dropdownsActions(item, 'state')}}
                className={formatValidInputClass(validations.provinceStateSelected)}
                disabled      = {countrySelected === ''}
              />
            </div>
          </div>
        </div>

        </div>
        <div className='col-md-2 col-xs-0'> </div>
      </div>

      {/*Buttons*/}
      <div className="row-actions">
            <Button 
              id="contacts-addresses-cancel"
              type    = "secondary" 
              onClick = {() => { props.onCancel(); }}
        >{getResource('Button.Cancel')}</Button>
            <Button 
              id="contacts-addresses-previous"
              type    = "primary" 
              onClick = {(event: any) => { 
                let returAddressPrev: ContactAddress = {
                  Address1  : address1,
                  Address2  : address2,
                  City      : city,
                  PostalCode: postalZipCode,
                  Country   : countrySelected,
                  State     : provinceStateSelected
                };
                if(validateFields())
                {
                  props.onPreviousStep('finish', returAddressPrev, false);
                }else{
                  props.onPreviousStep('error', returAddressPrev, true);
                }
          }}>{getResource('Button.Previous')}</Button>
            <Button 
              id="contacts-addresses-next"
              type    = "primary" 
              onClick = {(event: any) => { 
                let returAddressNext: ContactAddress = {
                  Address1  : address1,
                  Address2: address2 ? address2 : '',
                  City      : city,
                  PostalCode: postalZipCode,
                  Country   : countrySelected,
                  State     : provinceStateSelected
                };
                if(validateFields())
                {
                  props.onNextStep('finish', returAddressNext, false);
                }else{
                  props.onNextStep('error', returAddressNext, true);
                }
          }}>{getResource('Button.Next')}</Button>
      </div>
    </>
  );
}

export default AddressStep