import { useState } from 'react';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { OnlineRightsNames } from '../../helpers/OnlineRightsHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import PendingBeneficiariesList from './pendingBeneficiaries/PendingBeneficiariesList';
import PendingDealList from './pendingDeals/PendingDealList';


const ApprovalsScreen = (props: any) => {  

  let userSession = JSON.parse(localStorage.getItem('UserSession')!);
  const [currentScreen, setCurrentScreen] = useState(userSession.DealApproveRequired ? 'deals' : 'beneficiaries');
  const OL_DealApprove = useOnlineRights(OnlineRightsNames.OL_DealApprove);
  const OL_BeneficiaryApprove = useOnlineRights(OnlineRightsNames.OL_BeneficiaryApprove);

  const onChangeScreen = (screen: string) => {
    setCurrentScreen(screen);
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case 'deals':
        return userSession.DealApproveRequired && <PendingDealList />;
      case 'beneficiaries':
        return userSession.BeneApproveRequired && <PendingBeneficiariesList />;
      default:
        return <div></div>;
    }
  };

  return (
    <div className="main-container" >
      <div className="title-text"><h2>{getResource('Title.PendingApprovals')}</h2></div>
      <div className="navbar-horizontal">
        <div className="row">
          {userSession.DealApproveRequired && OL_DealApprove
            && <div
            className={currentScreen === 'deals' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('deals');
            }}
          >
            <span>{getResource('Title.Deals')}</span>
            </div>} 
          {userSession.BeneApproveRequired && OL_BeneficiaryApprove
            && <div
            className={currentScreen === 'beneficiaries' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
            onClick={() => {
              onChangeScreen('beneficiaries');
            }}
          >
            <span>{getResource('Tab.Option.Beneficiary')}</span>
            </div>}
        </div>
      </div>
      {renderScreen()}
    </div>
  );
};

export default ApprovalsScreen;
