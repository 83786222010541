import { Modal } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { getInvoicesIssuedForImport, importInvoicesIssued, saveInvoices } from '../../api/NetworkingInvoice';
import useWindowSize from '../../api/hooks/useWindowResize';
import { downloadTemplateCSV, formatDateString } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import UploadModal from '../beneficiaries/UploadModal';
import Button from '../inputs/Button';
import ConfirmationModal from '../others/ConfirmationModal';
import DataGrid from '../table/DataGrid';


export class ResponseGetInvoicing {
    InvoiceNumber: string;
    InvoiceDate: string;
    ExternalCustomerName: string;
    InvoiceDescription: string;
    Total: number;
    ExternalCustomerEmail: string;
    Terms: number;
    BeneficiaryID: number;
    BeneficiaryName: string;
    Amount: number;
    CurrencyID: string;
    Taxes: number;
    InvoiceLink: string;
    ExternalCustomerID: string;


    constructor(amount: number, currencyID: string, taxes: number, invoiceLink: string, invoiceNumber: string, invoiceDate: string, externalCustName: string, invoiceDescription: string, totalInvoice: number, externalCustEmail: string, terms: number, beneficiaryID: number, beneficiary: string, externalCustID: string) {
        this.InvoiceNumber = invoiceNumber;
        this.InvoiceDate = invoiceDate;
        this.ExternalCustomerName = externalCustName;
        this.InvoiceDescription = invoiceDescription;
        this.Total = totalInvoice;
        this.ExternalCustomerEmail = externalCustEmail;
        this.Terms = terms;
        this.BeneficiaryID = beneficiaryID;
        this.BeneficiaryName = beneficiary;
        this.Amount = amount;
        this.CurrencyID = currencyID;
        this.Taxes = taxes;
        this.InvoiceLink = invoiceLink;
        this.ExternalCustomerID = externalCustID;
    }
}
interface InvoiceUploadProps {
    InvoiceType: string;
    onClose: Function;
    onLoadInvoices: Function;
}

const InvoiceUpload: FunctionComponent<InvoiceUploadProps> = (props) => {
    const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
    const [invoiceImportTemplate, setInvoiceImportTemplate] = useState([]);
    const [showUploadTemplate, setShowUploadTemplate] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [invoiceList, setInvoiceList] = useState([],);
    const [fileUploaded, setFileUploaded] = useState<any>();
    const size = useWindowSize();

    useEffect(() => {
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        setFileUploaded(undefined);
        if (props.InvoiceType !== 'Payable') {
            //template
            var invoicesTemplate = [] as any;
            var importColumns = 'Invoice Number,Invoice Date,External Customer ID,External Customer Name,Invoice Description,Amount,Currency,Taxes,External Customer Email,Invoice link,Terms';
            var sampleData = '10050,2023-09-28,Customer01,Nexolink,Test 1,1000,USD,10,aa@aa.com,www.invoice.com,10';

            let columns = importColumns.split(',') || [] as any;
            let sample1Data = sampleData.split(',') || [] as any;

            let sample1 = {} as any;
            sample1Data.forEach((x: any, i: number) => {
                sample1[columns[i]] = x
            })

            let instructions1 = {} as any;
            let instructions2 = {} as any;
            instructions1['Invoice Number'] = '"Required fields: Invoice Number,Invoice Date,External Customer ID,External Customer Name,Invoice Description,Amount,Currency"';
            instructions2['Invoice Number'] = 'Date format: yyyy-mm-dd';

            invoicesTemplate.push(sample1);
            invoicesTemplate.push('');
            invoicesTemplate.push('');
            invoicesTemplate.push(instructions1);
            invoicesTemplate.push(instructions2);
            setInvoiceImportTemplate(invoicesTemplate);
        } else {
            getInvoicesIssuedForImport(userSession.CustomerID).then((json) => {
                if (json != null && json.PayableInvoicesImportRecords != null) {
                    setInvoiceImportTemplate(json.PayableInvoicesImportRecords);
                }
            });
        }
    }, [props.InvoiceType])

    const handleUploadClick = () => {
        setFileUploaded(undefined);
        setShowUploadTemplate(true);
    }

    const handleSaveClick = () => {
        setShowConfirmation(true);
    }
    const onFileChangeHandler = (event: any) => {
        setFileUploaded(event.target.files[0])
    }

    const onUploadFile = () => {
        showLoadingModal();
        const userSession = JSON.parse(localStorage.getItem('UserSession')!)
        readFileAsBase64(fileUploaded).then(
            (data: any) => {
                let fileContent = data.replace(/^data:.+;base64,/, '');
                var model = {
                    'FileContent': fileContent,
                    'UserID': localStorage.getItem('UserID'),
                    'FileName': fileUploaded.name,
                    'CustomerID': userSession.CustomerID,
                    'InvoiceType': props.InvoiceType
                }

                importInvoicesIssued(model).then(
                    (json) => {
                        if (json != null && json.BeneficiaryPayments != null && json.BeneficiaryPayments.BeneficiaryPayment != null) {
                            var invoiceList = json.BeneficiaryPayments.BeneficiaryPayment;
                            if (invoiceList.length > 0) {
                                setInvoiceList(invoiceList);
                                hideLoadingModal();
                                setFileUploaded(undefined);
                                setShowUploadTemplate(false);
                            }
                            else {
                                setInvoiceList([]);
                                hideLoadingModal();
                                setFileUploaded(undefined);
                                setShowUploadTemplate(false);
                            }
                        }
                        else {
                            var message = getResource('SomethingWentWrongTryAgain');
                            if (json.Message !== '' && json.Message != null) {
                                message = json.Message;
                            }
                            hideLoadingModal();
                            setInvoiceList([]);
                            setShowUploadTemplate(false);
                            setFileUploaded(undefined);
                            showModal(getResource('Js_MessageError_TitleError'), message, 'modal-message-breaktext');
                        }
                    }
                )
            });
    }


    const readFileAsBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }
    const closeAlert = () => {
        setShowConfirmation(false);
    }

    const yesAlert = () => {
        showLoadingModal();
        setShowConfirmation(false);

        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        var userID = localStorage.getItem('UserID');
        var customerID = userSession.CustomerID;
        var invoices = [] as any;
        invoiceList.forEach((element: any) => {
            invoices.push({
                InvoiceIssuedId: 0,
                CustomerId: customerID,
                InvoiceNumber: element.InvoiceNumber,
                InvoiceDate: element.InvoiceDate,
                InvoiceDescription: element.InvoiceDescription,
                Amount: element.Amount,
                CurrencyId: element.CurrencyID,
                Taxes: element.Taxes,
                Total: element.Total,
                ExternalCustomerID: element.ExternalCustomerID,
                ExternalCustomerName: element.ExternalCustomerName,
                ExternalCustomerEmail: element.ExternalCustomerEmail,
                InvoiceLink: element.InvoiceLink,
                CreatedBy: userID,
                InvoiceType: props.InvoiceType,
                Terms: element.Terms,
                BeneficiaryID: element.BeneficiaryID,
                Status: 'Pending'
            });
        }
        );
        var model = { Invoices: invoices };

        saveInvoices(model).then((response: any) => {
            if (typeof response !== undefined && response.httpStatusCode === 200) {
                hideLoadingModal();
                showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfully'));
                props.onLoadInvoices();
                onClickExit();
             }
             else {
                hideLoadingModal();
                showModal(getResource('Js_MessageError_TitleError'), getResource('Something went wrong. Please try again later.'));
            }
        });
    }

    const onClickExit = () => {
        props.onClose();
    }

    const invoicingColumnsPayable: ColumnsType<ResponseGetInvoicing> = [
        {
            title: getResource('Label.InvoiceNumber'),
            dataIndex: 'InvoiceNumber',
            key: 'history_invoiceNumber',
            align: 'left',
            sorter: (a, b) => a.InvoiceNumber.localeCompare(b.InvoiceNumber)
        },
        {
            title: getResource('Label.InvoiceDate'),
            dataIndex: 'InvoiceDate',
            key: 'history_invoiceDate',
            align: 'center',
            responsive: ['md'],
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{formatDateString(value)}</span>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a.InvoiceDate).getTime() - new Date(b.InvoiceDate).getTime()
        },
        {
            title: getResource('Label.InvoiceBeneficiaryID'),
            dataIndex: 'BeneficiaryID',
            key: 'history_beneficiaryID',
            align: 'left',
            responsive: ['md'],
            sorter: (a, b) => a['BeneficiaryID'] - b['BeneficiaryID']
        },
        {
            title: getResource('Label.InvoiceBeneficiaryName'),
            dataIndex: 'BeneficiaryName',
            key: 'history_beneficiary',
            align: 'left',
            responsive: ['md'],
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{value}</span>
            ),
            sorter: (a, b) => a['BeneficiaryName'].localeCompare(b['BeneficiaryName'])
        },
        {
            title: getResource('Label.InvoiceDescription'),
            dataIndex: 'InvoiceDescription',
            key: 'history_invoiceDescription',
            sorter: (a, b) => a.InvoiceDescription.localeCompare(b.InvoiceDescription),
            align: 'left',
        },
        {
            title: getResource('Js_TH_Amount'),
            dataIndex: 'Amount',
            key: 'history_amount',
            responsive: ['md'],
            sorter: (a, b) => a.Amount - b.Amount,
            align: 'left',
        },
        {
            title: size[0] > 768 ? getResource('Js_TH_Currency') : "",
            dataIndex: 'CurrencyID',
            key: 'history_currency',
            sorter: (a, b) => a['CurrencyID'].localeCompare(b['CurrencyID']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTaxes'),
            dataIndex: 'Taxes',
            key: 'history_taxes',
            responsive: ['md'],
            sorter: (a, b) => a.Taxes - b.Taxes,
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTotal'),
            dataIndex: 'Total',
            key: 'history_totalInvoice',
            sorter: (a, b) => a.Total - b.Total,
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTerms'),
            dataIndex: 'Terms',
            key: 'history_terms',
            responsive: ['md'],
            sorter: (a, b) => a.Terms - b.Terms,
            align: 'left',
        },
    ];
    const invoicingColumnsReceivable: ColumnsType<ResponseGetInvoicing> = [
        {
            title: getResource('Label.InvoiceNumber'),
            dataIndex: 'InvoiceNumber',
            key: 'history_invoiceNumber',
            align: 'left',
            sorter: (a, b) => a.InvoiceNumber.localeCompare(b.InvoiceNumber)
        },
        {
            title: getResource('Label.InvoiceDate'),
            dataIndex: 'InvoiceDate',
            key: 'history_invoiceDate',
            className: 'cell-date',
            responsive: ['md'],
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{formatDateString(value)}</span>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a, b) => new Date(a.InvoiceDate).getTime() - new Date(b.InvoiceDate).getTime()
        },
        {
            title: getResource('Label.InvoiceSenderID'),
            dataIndex: 'ExternalCustomerID',
            key: 'history_externalCustomerID',
            responsive: ['md'],
            sorter: (a, b) => a.ExternalCustomerID.localeCompare(b.ExternalCustomerID),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceSenderName'),
            dataIndex: 'ExternalCustomerName',
            key: 'history_externalCustomerName',
            responsive: ['md'],
            sorter: (a, b) => a.ExternalCustomerName.localeCompare(b.ExternalCustomerName),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceDescription'),
            dataIndex: 'InvoiceDescription',
            key: 'history_invoiceDescription',
            sorter: (a, b) => a['InvoiceDescription'].localeCompare(b['InvoiceDescription']),
            align: 'left',
        },
        {
            title: getResource('Js_TH_Amount'),
            dataIndex: 'Amount',
            key: 'history_amount',
            responsive: ['md'],
            sorter: (a, b) => a.Amount - b.Amount,
            align: 'left',
        },
        {
            title: size[0] > 768 ? getResource('Js_TH_Currency') : "",
            dataIndex: 'CurrencyID',
            key: 'history_currency',
            sorter: (a, b) => a['CurrencyID'].localeCompare(b['CurrencyID']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTaxes'),
            dataIndex: 'Taxes',
            key: 'history_taxes',
            responsive: ['md'],
            sorter: (a, b) => a.Taxes - b.Taxes,
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceSenderEmail'),
            dataIndex: 'ExternalCustomerEmail',
            key: 'history_externalCustomerEmail',
            responsive: ['md'],
            sorter: (a, b) => a.ExternalCustomerEmail.localeCompare(b.ExternalCustomerEmail),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceLink'),
            dataIndex: 'InvoiceLink',
            key: 'history_invoiceLink',
            responsive: ['md'],
            sorter: (a, b) => a.InvoiceLink.localeCompare(b.InvoiceLink),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTerms'),
            dataIndex: 'Terms',
            key: 'history_terms',
            responsive: ['md'],
            sorter: (a, b) => a.Terms - b.Terms,
            align: 'left',
        },
    ];
    const downloadBlankTemplate = () => {
        downloadTemplateCSV(invoiceImportTemplate, 'InvoicesTemplate');
    }
    return (
        <div>
            <Modal
                title={<h3 className="component-title">{'Upload ' + props.InvoiceType + ' Invoices'}</h3>}
                centered
                visible={true}
                footer={null}
                //onCancel={props.changeState()}
                maskClosable={false}
                className='modal-message-lg'
                zIndex={1000}
                closable={false}

            >
                <div className='row'>
                    <div className='row row-actions-r'>
                            <Button
                            type={'primary'}
                                onClick={downloadBlankTemplate}
                            >{getResource('SubMenu_DownloadTemplateForInvoices')}
                        </Button>
                            <Button
                            type={'primary'}
                                onClick={handleUploadClick}
                            >{getResource('Upload')}
                        </Button>
                    </div>
                    <div className="row middle-xs center-xs">
                        <div className='col-md-12'>
                            <DataGrid
                                key={'invoicing-table'}
                                columns={props.InvoiceType !== 'Payable' ? invoicingColumnsReceivable : invoicingColumnsPayable}
                                dataSource={invoiceList}
                            />
                        </div>
                    </div>

                    <div className="row-actions">
                                <Button id="exit-button" type="secondary" onClick={onClickExit}>
                                    Exit
                        </Button>
                                <Button id="exit-button" type="primary" onClick={handleSaveClick}>
                                    {getResource('Button.Save')}
                        </Button>
                    </div>
                </div>
            </Modal>
            <UploadModal showModalUpload={showUploadTemplate} title={'Upload File'} fileUploaded={fileUploaded} accept={'.csv'} setShowModalUpload={() => {
                setFileUploaded(undefined);
                setShowUploadTemplate(false);
            }}
                onFileChangeHandler={onFileChangeHandler} importFile={onUploadFile}>
            </UploadModal>
            <ConfirmationModal visible={showConfirmation} cancel={closeAlert} confirm={yesAlert} title={getResource('AreYouSureYouWantToSave')} />
        </div>
    );
}
export default InvoiceUpload;