import { useEffect, useState } from 'react';
import { getCurrenciesByCustomer } from '../NetworkingCurrencies';

interface ICustomerCurrencies {
  payment: any[];
  settlement: any[];
}

class CustomerCurrencies implements ICustomerCurrencies {
  payment!: [];
  settlement!: [];
}

const useCustomerCurrencies = () => {
  const emptyCurrencies: CustomerCurrencies = new CustomerCurrencies();
  const [currencies, setCurrencies] = useState(emptyCurrencies);
  const [needsReloading, setNeedsReloading] = useState(true);
  const userSession = JSON.parse(localStorage.getItem('UserSession')!);

  useEffect(() => {

    let customerID = 0;
    if (userSession && userSession['CustomerID']) {
      customerID = userSession['CustomerID']
    }

    const loadCurrencies = async (customerID: any) => {

      const json = await getCurrenciesByCustomer(customerID);
      if (json.currencies !== undefined) {
        const formattedCurrencies = json.currencies.map((c: any) => ({ ...c, label: c.CurrencyIDName, value: c.CurrencyID }));
        let finalCurrencies = { payment: [] as any, settlement: [] as any };
        finalCurrencies.payment.push({ label: 'Select Buy Currency...', value: '' });
        finalCurrencies.settlement.push({ label: 'Select Sell Currency...', value: '' });
        formattedCurrencies.forEach((element: any) => {
          switch (element.Type) {
            case 'Payment':
              finalCurrencies.payment.push(element);
              break;
            case 'Settlement':
              finalCurrencies.settlement.push(element);
              break;
            default:
              break;
          }
        });
        setCurrencies(finalCurrencies);
      } else {
        setCurrencies({ payment: [], settlement: [] });
      }
      setNeedsReloading(false);
    };
    if (needsReloading && customerID > 0) {
      loadCurrencies(customerID);
    }
  }, [needsReloading]);

  return [currencies, setNeedsReloading];
};

export default useCustomerCurrencies;
