
export function formatToCurrency(amount: string, currencyID?: string, scale?: number,) {
  const listCurrencies = JSON.parse(localStorage.getItem('UserSession')!).AvailableCurrencies;
  let auxScale = scale != null ? scale : 2;
  if (currencyID != null && currencyID !== '') {
    const auxCurrencies = listCurrencies.find((currency: any) => currency.CurrencyID === currencyID);
    auxScale = auxCurrencies != null ? auxCurrencies.NumberOfDecimals : 2;
  }
  if (isNaN(Number(amount)) || amount === '' || amount === null) {
    amount = '0.00';
  }

  let amountAux = Number(amount);
  if (isNaN(amountAux) && amount !== '0.00') {
    amountAux = Number(amount.replaceAll(',', ''));
  }

  return amountAux
    .toFixed(auxScale)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function formatToCurrencyDefault(amount: string) {
  if (amount === '' || amount === null) {
    amount = '0.00';
  }
  return parseFloat(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
export function formatToRate(rate: string) {
  if (rate !== undefined && rate !== null && rate !== '') {
    return parseFloat(rate)
      .toFixed(4)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
  } else {
    return null;
  }
}

export function formatAmount(number: number) {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = '$1,';
  let arr = number.toString().split('.');
  arr[0] = arr[0].replace(exp, rep);
  return arr[1] ? arr.join('.') : arr[0];
}

export function formatDateToDDMMYYYY(date: string) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

export function formatDateString(dateString: string) {
  const d = new Date(dateString);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

export function formatDateString2(dateString: string) {
  const d = new Date(dateString);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('/');
}

export const formatStringToDate = (string: string) => {
  const [y, m, d] = string.split('/');
  const year = Number.parseInt(y);
  const month = Number.parseInt(m);
  const day = Number.parseInt(d);
  const date = new Date(year, Number(month) - 1, day);
  return date;
};

export const formatStringToDate2 = (string: string) => {
  const [y, m, d] = string.split('-');
  const year = Number.parseInt(y);
  const month = Number.parseInt(m);
  const day = Number.parseInt(d);
  const date = new Date(year, Number(month) - 1, day);
  return date;
};

export function formatDate(date: string | Date) {
  const d = new Date(date);
  let month = '' + (d.getMonth() + 1);
  let day = '' + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('/');
}

function addZero(i: number) {
  let lessThanTen = i.toString();
  if (i < 10) {
    lessThanTen = '0' + lessThanTen;
  }
  return lessThanTen;
}

export function getHoursDate(dateString: string) {
  var d = new Date(dateString);

  var h = addZero(d.getHours());
  var m = addZero(d.getMinutes());
  var s = addZero(d.getSeconds());

  return [h, m, s].join(':');
}

export function toDDMMMFormat(stringDate: string) {
  var date = new Date(stringDate);
  var month_names = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  var day = date.getDate();
  var month_index = date.getMonth();
  // var year = date.getFullYear();
  return month_names[month_index] + ' ' + day;
}

export function getYear(stringDate: string) {
  var date = new Date(stringDate);
  return date.getFullYear();
}

export function titleCase(str: string) {
  if (str.includes(' ')) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  } else {
    return str;
  }
}

export function addDays(date: Date, days: number) {
  var result = date;
  result.setDate(date.getDate() + days);
  return result;
}

export const removeWhiteSpace = (string: string) => string.replace(/\s/g, '');

export function getTimehhmm(datetime: Date) {
  let hours = datetime.getHours();
  let minutes = datetime.getMinutes();
  var hoursText = hours.toString();
  var minutesText = minutes.toString();
  if (hours < 10) {
    hoursText = '0' + hours;
  }
  if (minutes < 10) {
    minutesText = '0' + minutes;
  }
  return hoursText + ':' + minutesText;
}

export const formatValidInputClass = (valid: boolean) => (valid ? '' : 'input-error');
export const formatValidSelectClass = (valid: boolean) => (valid ? 'use-select2' : 'error');
export const formatValidAutocompleteClass = (valid: boolean) => (valid ? '' : 'ant-select-auto-complete-error');
export const formatIconClass = (valid: boolean) => (valid ? '' : 'icon-error');
export const formatValidDateRangeClass = (valid: boolean) => (valid ? 'ant-picker-range' : 'ant-picker-range-error');
export const formatValidDateClass = (valid: boolean) => (valid ? '' : 'ant-picker-input-error');

export function validInputClass(value: string) {
  if (value != null && value !== '' && !value.includes('Select') && value !== '0') {
    return true;
  } else {
    return false;
  }
}

export function downloadTemplateCSV(obj: any, fileName: string) {
  let csvContent = "data:text/plain;charset=utf-8,";
  let csvDataAsRows = obj
  if (csvDataAsRows && csvDataAsRows.length > 0) {
  let header = [...Object.keys(csvDataAsRows[0])];
  for (let i = 0; i < csvDataAsRows.length; i++) {
    delete csvDataAsRows[i].Action;
    delete csvDataAsRows[i].Key;

    for (let j = 0; j < header.length; j++) {
      if (header[j].includes('$')) {
        delete csvDataAsRows[i][header[j]];
      }
    }
  }
  let encodedUri = '';
  let link: HTMLElement;

  csvDataAsRows = obj != null ?
    obj
      .map((e: any) => Object.values(e))
      .reduce(function (csvString: any, row: any) {
        csvString += "\r\n";
        csvString += row.join(',');
        return csvString;
      }) : [];
  let today = new Date();
  let yearString = today.getFullYear().toString();
  let monthString = (today.getMonth() + 1).toString().padStart(2, '0');
  let dayString = today.getDate().toString().padStart(2, '0');
  let hourString = today.getHours().toString().padStart(2, '0');
  let minuteString = today.getMinutes().toString().padStart(2, '0');
  let timeStamp = yearString + monthString + dayString + hourString + minuteString;

  let newFileName = fileName + timeStamp + ".csv";

  encodedUri = encodeURI(csvContent + header + "\r\n" + csvDataAsRows);
  link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", newFileName);
  link.click();
  document.body.appendChild(link);
  }
}
export function scaleByCurrencyPair(buyCurrency: string, sellCurrency: string) {

  let currencyPairs = JSON.parse(localStorage.getItem('CurrencyPairs')!);
  let pair = currencyPairs.find((c: any) => c.CurrencyCode === buyCurrency && c.OutCurrencyCode === sellCurrency);
  let scale = pair != null ? pair.RatePrecision || 8 : 8;

  return scale;
}

export function scaleByCurrency(currencyID: string) {
  let listCurrencies = JSON.parse(localStorage.getItem('UserSession')!).AvailableCurrencies;

  let auxCurrencies = listCurrencies.find((currency: any) => currency.CurrencyID === currencyID);
  let scale = auxCurrencies != null ? auxCurrencies.NumberOfDecimals : 2;

  return scale;
}