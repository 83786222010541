import React, { FunctionComponent, useEffect, useState } from "react";
import { OnlineRightsNames } from "../../../helpers/OnlineRightsHelper";
import { getResource } from "../../../helpers/ResourcesHelper";


interface NavBarProps {
    onChangeScreen: Function;
    currentScreen: string;
    onlineRights: string;
}

const showMenuItem = (onlineRightsStr: string, onlineRight: string) => {
    let rightsOnline;
    if (onlineRightsStr != null && onlineRightsStr != '') {
        rightsOnline = onlineRightsStr;
    }
    else {
        rightsOnline = localStorage.getItem('OnlineRights');
    }

    if (rightsOnline != null && rightsOnline != '') {
        let onlineRights = JSON.parse(rightsOnline);
        var right = onlineRights.find((element: any) => element.Right == onlineRight);
        if (right != undefined) {
            return true
        }
    }
    return false
}

const NavBar: FunctionComponent<NavBarProps> = (props) => {

    const [selectedScreen, setSelectedScreen] = useState('dashboard');
    //const [onlineRights, setOnlineRights] = useState('')
    console.log(props)


    useEffect(() => {
        setSelectedScreen(props.currentScreen);
    })

    /*useEffect(() => {
        let onlineRightsStr = localStorage.getItem('OnlineRights') || '';
        console.log(onlineRightsStr)
        setOnlineRights(onlineRightsStr)
    }, [onlineRights]);*/

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let OL_RatesSection = showMenuItem(props.onlineRights, OnlineRightsNames.OL_RatesSection);
    let OL_RatesSpotAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_RatesSpotAccess);
    let OL_RatesForwardAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_RatesForwardAccess);
    let OL_SpotAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_SpotAccess);
    let OL_ForwardAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_ForwardAccess);
    let OL_RegularPaymentAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_RegularPaymentAccess);
    let OL_OrderAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_OrderAccess);
    let OL_BeneficiaryAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_BeneficiaryAccess);
    let OL_DealApprove = showMenuItem(props.onlineRights, OnlineRightsNames.OL_DealApprove);
    let OL_BeneficiaryApprove = showMenuItem(props.onlineRights, OnlineRightsNames.OL_BeneficiaryApprove);
    let OL_TransactionsHistoryAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_TransactionsHistoryAccess);
    let OL_MyAccountInformation_Balances = showMenuItem(props.onlineRights, OnlineRightsNames.OL_MyAccountInformation_Balances);
    let OL_ToolsAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_ToolsAccess);;
    let OL_Invoicing = showMenuItem(props.onlineRights, OnlineRightsNames.OL_Invoicing);

    /* const onLogoutClick = () => {
        logout().then((logoutResponse) => {
            localStorage.clear();
            props.onLogout();
        })
    } */

    const onNavBarOptionClick = (screen: 'dashboard' | 'rates' | 'payment' | 'balance' | 'transaction' | 'beneficiaries' | 'quickbooks' | 'approvals' | 'invoicing') => {
        setSelectedScreen(screen);
        props.onChangeScreen(screen);
    }

    return (
        <React.Fragment>
            <div className="navbar">
                {/* <div className={(selectedScreen === 'dashboard') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={()=>{onNavBarOptionClick('dashboard')}}>
                    <div>
                        <span className="material-icons-round navbar-icon">dashboard</span>
                    </div>
                    <div>
                        <span>{getResource('Title.Dashboard')}</span>
                    </div>
                </div> */}
                {OL_RatesSection &&
                    (OL_RatesSpotAccess || OL_RatesForwardAccess)
                    && <div className={(selectedScreen === 'rates') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('rates') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">trending_up</span>
                    </div>
                    <div>
                        <span>{getResource('Title.RateWatch')}</span>
                    </div>
                    </div>}

                {(OL_SpotAccess || OL_ForwardAccess || OL_RegularPaymentAccess || OL_OrderAccess)
                    && <div className={(selectedScreen === 'payment') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('payment') }}>
                    <div><span className="material-icons-round navbar-icon">payment</span></div>
                        <div><span>{getResource('Title.PaymentsTrading')}</span></div>
                    </div>}

                {(OL_TransactionsHistoryAccess)
                    && <div className={(selectedScreen === 'transaction') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('transaction') }}>
                    <div><span className="material-icons-round navbar-icon">receipt_long</span></div>
                    <div><span>{getResource('Title.Transactions')}</span></div>
                    </div>}

                {OL_MyAccountInformation_Balances
                    && <div className={(selectedScreen === 'balance') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('balance') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">view_list</span>
                    </div>
                    <div>
                        <span>{getResource('Tab_MyBalances')}</span>
                    </div>
                    </div>}

                {OL_BeneficiaryAccess
                    && <div className={(selectedScreen === 'beneficiaries') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('beneficiaries') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">people</span>
                    </div>
                    <div>
                        <span>{getResource('Tab.Option.Beneficiary')}</span>
                    </div>
                </div>
                }

                {OL_ToolsAccess
                    && <div className={(selectedScreen === 'quickbooks') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('quickbooks') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">construction</span>
                    </div>
                    <div>
                            <span>{getResource('Title.Tools')}</span>
                    </div>
                </div>
                }

                {((userSession.DealApproveRequired && OL_DealApprove)
                    || (userSession.BeneApproveRequired && OL_BeneficiaryApprove))
                    && <div className={(selectedScreen === 'approvals') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('approvals') }}>
                    <div>
                        <span className="material-icons-round navbar-icon">check_circle_outline</span>
                    </div>
                    <div>
                        <span>{getResource('Tab_Approvals')}</span>
                    </div>
                </div>
                }
                {OL_Invoicing
                    && <div className={(selectedScreen === 'invoicing') ? 'col navbar-option-selected' : 'col navbar-option'} onClick={() => { onNavBarOptionClick('invoicing') }}>
                        <div>
                            <span className="material-icons-round navbar-icon">receipt</span>
                        </div>
                        <div>
                            <span>{getResource('Title.Invoicing')}</span>
                        </div>
                    </div>
                }
                {/* <div className="col navbar-option" onClick={onLogoutClick}>
                    <div>
                        <span className="material-icons-round navbar-icon">logout</span>
                    </div>
                    <div>
                    <span>{getResource('Login.Logout')}</span>
                    </div>
                </div> */}
                <div className="navbar-trader-info-container">
                    <div className="row middle-xs">
                        <div className="col col-xs-md-8 col-xs-12">
                            <span className="trader-name">{userSession.TraderName}</span>
                            <span className="trader-info">{userSession.TraderEmail}</span>
                            <span className="trader-info">{userSession.TraderPhone}</span>
                            <span className="trader-info" hidden={true}>{props.onlineRights}</span>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default NavBar;