import { Input } from 'antd';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { formatValidInputClass, validInputClass } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Dropdown from '../inputs/Dropdown';
interface BeneProps {
  beneFields: number;
  [x: string]: any;
}
const PaymentDefault: React.FC<BeneProps> = ({ beneFields, ...props }) => {
  const { showModal } = useContext(AppContext);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [email, setEmail] = useState('');
  const [dealLimit, setDealLimit] = useState(0);
  const [detailsPayment, setDetailsPayment] = useState('');
  const [paymentReference, setPaymentReference] = useState('');
  const { TextArea } = Input;

  const [validations, setValidations] = useState({
    payment: true,
    delivery: true
  });

  const validationsFields = () => {
    let validPaymentMethod = validInputClass(paymentMethod);
    let methods: { value: string; label: string }[] = deliveryMethods || [{}]
    let validDeliveryMethod = (methods.length === 0 || methods[0].label === '') || validInputClass(deliveryMethod);
    setValidations({
      payment: validPaymentMethod,
      delivery: validDeliveryMethod,
    });
    var isValid = validPaymentMethod && validDeliveryMethod;
    if (!isValid) { showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted')); }
    else {
      props.onUpdateStep(2);
    }
  }

  useEffect(() => {
    if (props.beneficiary !== undefined) {
      setPaymentMethod(props.beneficiary.PreferredMethodOfPayment);
      if (props.paymentMethods != null) {
        let item = props.paymentMethods.filter((x: any) => x.InstrumentTranslationLabel === props.beneficiary.PreferredMethodOfPayment);
        if (item !== null && item.length > 0) {
          filterDeliveryMethods(item[0]);
          setDeliveryMethod(props.beneficiary.PreferredDeliveryMethod);
        }
      }
      setEmail(props.beneficiary.EmailAddress);
      setDealLimit(props.beneficiary.PaymentLimit);
      setDetailsPayment(props.beneficiary.DetailsOfPayment);
      setPaymentReference(props.beneficiary.PaymentReference);

    }
  }, [props.beneficiary]);

  useEffect(() => {
    if (beneFields != null && beneFields > 0) {
      validationsFields();
    }
  }, [beneFields]);

  const paymentMethodChangeHandler = (item: any, name: string) => {
    if (item != null) {
      setPaymentMethod(item.value);
      setValidations({ ...validations, payment: true });
      filterDeliveryMethods(item);
      props.onUpdateBeneficiary('PreferredMethodOfPayment', item.value);
    }
  };

  const filterDeliveryMethods = (item: any) => {    
    if (item.DeliveryMethods !== null && item.DeliveryMethods !== '') {
      setDeliveryMethod('');
      let methods = item.DeliveryMethods.split('§');
      methods = methods.map((x: string) => ({ label: x, value: x }));
      setDeliveryMethods(methods);
    } else {
      setDeliveryMethod("")
      setDeliveryMethods([]);
    }
  };

  const deliveryMethodChangeHandler = (item: any, name: string) => {
    if (item != null) {
      setDeliveryMethod(item.value);
      setValidations({ ...validations, delivery: true });
      props.onUpdateBeneficiary('PreferredDeliveryMethod', item.value);
    }
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
    props.onUpdateBeneficiary('EmailAddress', e.target.value);
  };

  const onChangeDealLimit = (e: any) => {
    setDealLimit(e.target.value);
    props.onUpdateBeneficiary('PaymentLimit', e.target.value);
  };

  const onChangeDetailsPayment = (e: any) => {
    setDetailsPayment(e.target.value);
    props.onUpdateBeneficiary('DetailsOfPayment', e.target.value);
  };

  const onChangePaymentReference = (e: any) => {
    setPaymentReference(e.target.value);
    props.onUpdateBeneficiary('PaymentReference', e.target.value);
  };


  return (
    <div className={props.canEdit ? '' : 'readonly'}>
      <div className="row max-w-xs-sm">
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.PreferredMethodPayment') + '(*)'}</span>
          </div>
          <div className="row">
            <Dropdown name="paymentMethod" title={getResource('SelectDeliveryMethod')} list={props.paymentMethods} select={{ value: paymentMethod }} onChange={paymentMethodChangeHandler}
              errorMessage={getResource('DataMsgRequired')}
              className={formatValidInputClass(validations.payment)} />
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.PreferredDeliveryMethod') + '(*)'}</span>
          </div>
          <div className="row">
            <Dropdown disabled={paymentMethod !== '' ? false : true} name="deliveryMethod" title={getResource('SelectDeliveryMethod')} list={deliveryMethods} select={{ value: deliveryMethod || '' }} onChange={deliveryMethodChangeHandler}
              errorMessage={getResource('DataMsgRequired')}
              className={formatValidInputClass(validations.delivery)} />
          </div>
        </div>
      </div>

      <div className="row max-w-xs-sm">
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.Email')}</span>
          </div>
          <div className="row">
            <input type="text" onChange={onChangeEmail} value={email}></input>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.DealLimit')}</span>
          </div>
          <div className="row">
            <input type="number" onChange={onChangeDealLimit} value={dealLimit}></input>
          </div>
        </div>
      </div>

      <div className="row max-w-xs-sm">
        <div className="col-md-12 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.DetailsPayment')}</span>
          </div>
          <div className="row">
            <TextArea rows={3} onChange={onChangeDetailsPayment} value={detailsPayment} />
          </div>
        </div>
      </div>

      <div className="row max-w-xs-sm">
        <div className="col-md-12 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.PaymentReference')}</span>
          </div>
          <div className="row">
            <TextArea rows={3} onChange={onChangePaymentReference} value={paymentReference} />
          </div>
        </div>
      </div>

    </div>
  );
};

export default PaymentDefault;
