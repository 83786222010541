import { Breadcrumb } from 'antd';
import React, { useState } from 'react';
import { getResource } from '../../../helpers/ResourcesHelper';
import PendingDealDetail from './PendingDealDetail';
import PendingDealsTable from './PendingDealsTable';

const PendingDealList = (props: any) => {  
  const [selectedDealID, setSelectedDealID] = useState(0);
  const [dealObject, setDealObject] = useState({} as any);
  const [screen, setScreen] = useState('table');

  const [drawdownDealID, setDrawdownDealID] = useState(0);
  const [showTrackForm, setShowTrackForm] = useState<boolean>(false);

  const onCancelClick = () => {
    setScreen('table');
    setDealObject({});
    setSelectedDealID(0);
    setDrawdownDealID(0);
    setShowTrackForm(false);
  };

  const selectRow = (dealID: number) => {
    setSelectedDealID(dealID);
    setScreen('info');
    setShowTrackForm(true);
  };

  return (
    <React.Fragment>
      {showTrackForm && <div className="breadcrumb-container">
        <Breadcrumb separator="">
          <Breadcrumb.Item onClick={onCancelClick}>{getResource('PendingDeals')} </Breadcrumb.Item>
          {screen === 'info' && selectedDealID !== 0 && <Breadcrumb.Separator children=">" />}
          {screen === 'info' && selectedDealID !== 0 && <Breadcrumb.Item>{getResource('Deal')} {selectedDealID}</Breadcrumb.Item>}
        </Breadcrumb>
      </div>}
      {screen === 'table' && <PendingDealsTable selectRow={selectRow} />}
      {screen === 'info' && selectedDealID !== 0 && <PendingDealDetail dealHeaderID={selectedDealID} onExit={onCancelClick} />}
    </React.Fragment>
  );
};

export default PendingDealList;
