import { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { getCustomerInformation, updateCustomerInformation } from '../../../api/NetworkingCustomers';
import useLookUpTables from '../../../api/hooks/useLookUpTables';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import Dropdown from '../../inputs/Dropdown';

interface DropdownReturnValue {
  label: string;
  value: string;
}

const DetailsCorporate = () => {
  const { showModal } = useContext(AppContext);
  const listBusinessType = useLookUpTables('Business Type');
  const [customerName, setCustomerName] = useState('');
  const [companyStructure, setCompanyStructure] = useState('');
  const [companyRegNo, setCompanyRegNo] = useState('');
  const [customerID, setCustomerID] = useState(0);
  const [customerType, setCustomerType] = useState('');
  const [salutation, setSalutation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [email, setEmail] = useState('');
  const [updateToken, setUpdateToken] = useState('');

  useEffect(() => {
    const loadCustomerDetails = async () => {
      const _customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const json = await getCustomerInformation(_customerID);
      if (json != null) {
        setCustomerName(json.CustomerName);
        setCompanyStructure(json.CompanyStructure);
        setCompanyRegNo(json.CompanyRegistrationNo);
        setCustomerID(_customerID);
        setCustomerType(json.CustomerType);
        setSalutation(json.Salutation);
        setFirstName(json.FirstName);
        setLastName(json.LastName);
        setEmail(json.Email != null ? json.Email : '');
        setPhoneNumber(json.PhoneNumber);
        setMobilePhone(json.MobileNumber);
        setUpdateToken(json.UpdateToken);
      }
    };
    loadCustomerDetails();
  }, []);

  const onDropdownChange = (e: DropdownReturnValue) => {
    setCompanyStructure(e.value);
  };

  const saveDetailsCorporate = async () => {
    const model = {
      CustomerID: customerID,
      CustomerType: customerType,
      Salutation: salutation,
      FirstName: firstName,
      LastName: lastName,
      Name: customerName,
      PhoneNumber: phoneNumber,
      MobilePhone: mobilePhone !== null ? mobilePhone : '',
      Email: email,
      UpdateToken: updateToken,
      entityproperties: [
        { PropertyDescription: 'Company Structure', PropertyValue: companyStructure },
        { PropertyDescription: 'Company Registration No', PropertyValue: companyRegNo }
      ]
    };
    const json = await updateCustomerInformation(model);
    if (json != null) {
      if (json.httpStatusCode !== 200) {
        showModal(getResource('Js_MessageError_TitleError'), json.httpErrorMessage);
      } else {
        showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfully'));
      }
    }
  };

  const onChangeCustomerName = (e: any) => setCustomerName(e.target.vaue);

  const onChangeCompanyRegNo = (e: any) => setCompanyRegNo(e.target.value);

  return (
    <div className="screen-container">
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.CompanyName')}</span>
          </div>
          <div className="row">
            <input id="customerName" value={customerName} onChange={onChangeCustomerName} type="text" />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.CompanyStructure')}</span>
          </div>
          <div className="row">
            <Dropdown
              select={{ value: companyStructure }}
              name="company-structure"
              title="Select a Company Structure..."
              list={listBusinessType.BusinessType}
              onChange={onDropdownChange}
            />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.CompanyRegistrationNo')}</span>
          </div>
          <div className="row">
            <input id="companyRegNo" value={companyRegNo} onChange={onChangeCompanyRegNo} type="text" />
          </div>
          <div className="row-actions">
            <Button onClick={saveDetailsCorporate}>{getResource('Button.Save')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsCorporate;
