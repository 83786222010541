const pjson = require('../../package.json');
const preregURL = window.REACT_APP_REGISTRATIONFORM_URL;
const preregAFEXURL = window.REACT_APP_AFEX_REGISTRATIONFORM_URL;

export function getCurrentVersionNumber() {
  return pjson.version;
}

export function getPreRegURL() {
  let afexSession = JSON.parse(localStorage.getItem('UserSession')!).OnlineInterface;
  if (afexSession != null && afexSession.includes('AFEX - Pass Thru')) {
    return preregAFEXURL;
  } else {
    return preregURL;
  }
}

export function getCompanyLogo() {
  const company = window.REACT_APP_COMPANY;
  switch (company) {
    case 'Premier': return require('../images/Logo_Premier.png').default;
    case 'Xushi': return require('../images/Logo_Xushi.png').default;
    case 'GlobalQFX': return require('../images/Logo_QFX.png').default;
    case 'Lighthouse': return require('../images/Logo_LFX.png').default;
    default: return require('../images/logo_generic.png').default;
  }
}