import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Breadcrumb, Modal, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { getListOfChallengeQuestions, saveChallengeAnswers } from '../../api/NetworkingUsers';
import AppContext from '../../App.context';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import Input from '../inputs/Input';

interface SetQuestionsProps {
  questionsSaved: () => void;
  continueToLogin: () => void;
}

const SetQuestions: React.FC<SetQuestionsProps> = ({ questionsSaved, continueToLogin }) => {
  const { showModal: showAppModal } = useContext(AppContext);
  const [listQuestions, setListQuestions] = useState([]);
  const [listAnswers, setListAnswers] = useState([]);
  const [saving] = useState<boolean>(false);
  const [showChallengeQuestionsNextTime, setShowChallengeQuestionsNextTime] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    const loadQuestions = async () => {
      const json = await getListOfChallengeQuestions();
      if (json != null && json.Questions != null) {
        const _listAnswers = json.Questions.map((question: any) => ({ QuestionID: question.QuestionID, Answer: '' }));
        setListQuestions(json.Questions);
        setListAnswers(_listAnswers);
      }
    };
    loadQuestions();
  }, []);

  const saveAnswers = async () => {
    const userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let contactName = userSession.ContactName;
    if (userSession.BeneficiaryID != null && userSession.BeneficiaryID > 0) {
      contactName = userSession.BeneficiaryName;
    }
    const objQuestions = {
      UserID: userSession.PersonID,
      QuestionsJson: listAnswers,
      ShowChallengeQuestions: showChallengeQuestionsNextTime,
      InstallationID: userSession.InstallationID,
      UserName: userSession.UserName,
      ContactName: contactName
    };
    const json = await saveChallengeAnswers(objQuestions);
    if (json != null) {
      if (json.httpStatusCode !== 200) {
        showAppModal(getResource('Js_MessageError_TitleError'), json.httpErrorMessage);
      } else {
        setShowModal(true);
        questionsSaved();
        setModalTitle(getResource('Js_MessageError_TitleSuccess'));
        setModalMessage(getResource('Js_Message_SaveSuccessfullyAnswers'));
      }
    }
  }

  const handleAnswerUpdate = (event: any, question: any) => {
    let newAnswers = listAnswers.map(
      (a: any) => a.QuestionID === question.QuestionID ?
        { QuestionID: a.QuestionID, AnswerText: event.target.value } : a
    ) as any;
    setListAnswers(newAnswers);
  }

  const onChangeShowChallengeQuestionsNextTime = (value: any) => {
    setShowChallengeQuestionsNextTime(value);
  }

  const handleCloseModal = () => {
    continueToLogin();
  }

  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-xs-12 start-xs">
          <div className="board">
            <h5 className="input-label start-sm start-xs">{getResource('PersonalVerificationQuestions')}</h5>
            <p>{getResource('Questions_Text1')}</p>
            <p>{getResource('Questions_Text2')}</p>
          </div>
          <div className="row">
            <div className="col-md-7 col-xs-12">
              <span className="input-label start-sm start-xs">{getResource('ShowChanllengeQuestions')}</span>
            </div>
            <div className="col-md-5 col-xs-12 start-sm start-xs">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={false}
                checked={showChallengeQuestionsNextTime}
                onChange={onChangeShowChallengeQuestionsNextTime}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="screen-container">
            {
              listQuestions.map(
                (question: any, i: number) => (
                  <div className="row center-xs">
                    <div className="col-md-9 col-xs-12">
                      <div className="row">
                        <span className="input-label">{getResource(question.QuestionText)}</span>
                      </div>
                      <div className="row">
                        <Input
                          type="text"
                          submit={saving}
                          onChange={(event: any) => {
                            return handleAnswerUpdate(event, question);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              )
            }
          </div>
          <div className="row center-xs">
            <div className="col-md-5 col-xs-12">
              <div className="row center-xs">
                <Button onClick={saveAnswers}>{getResource('Button.Save')}</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={
          <div className="breadcrumb-container">
            <Breadcrumb separator="">
              <Breadcrumb.Item >{modalTitle}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        }
        centered
        visible={showModal}
        footer={null}
        onCancel={handleCloseModal}
        maskClosable={false}
        width={'25%'}
        zIndex={1021}
        closable={false}
      >
        {modalMessage}
        <div className="row">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            <Button id="confirmation-OK" type="primary" onClick={handleCloseModal}>
              Ok
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SetQuestions;
