import { DollarCircleOutlined, FilterFilled, FilterOutlined, HistoryOutlined, SyncOutlined } from '@ant-design/icons';
import { Breadcrumb, Collapse, Modal, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { getHistoryAuditFundsOnHoldBalances, getListOfFundsOnHold } from '../../api/NetworkingFunds';
import useOnlineRights from '../../api/hooks/useOnlineRights';
import { formatDateString, formatToCurrency } from '../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../helpers/OnlineRightsHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import DatePicker from '../inputs/DatePicker';
import OrdersScreen from '../orders/OrdersScreen';
import DownloadTableRows from '../shared/DownloadTableRows';
import ModalFilter from '../shared/ModalFilter';

const { Panel } = Collapse;
export class ResponseGetListOfFundsOnHold {
  Amount                  : number;
  CurrencyID              : string;
  PendingWithdrawalAmount : number;

  constructor(amount:number, currencyID:string, pendingWithdrawalAmount:number)
  {
    this.Amount = amount;
    this.CurrencyID = currencyID;
    this.PendingWithdrawalAmount = pendingWithdrawalAmount;
  }
}
export class ResponseGetHistoryOfFundsOnHold {
  AuditFundsOnHOldBalancesID: number;
  CurrencyID: string;
  'User Name': string;
  Balance: number;
  'Beginning Balance': number;
  TransactionAmount: number;
  Source: string;
  Destination: string;
  UpdatedOn: string;

  constructor(currencyID: string, auditFundsOnHOldBalancesID: number, userName: string, balance: number, beginningBalance: number, transactionAmount: number, source: string, destination: string, updatedOn: string) {
    this.CurrencyID = currencyID;
    this.AuditFundsOnHOldBalancesID = auditFundsOnHOldBalancesID;
    this['User Name'] = userName;
    this.Balance = balance;
    this['Beginning Balance'] = beginningBalance;
    this.TransactionAmount = transactionAmount;
    this.Source = source;
    this.Destination = destination;
    this.UpdatedOn = updatedOn;
  }
}
const MyBalances = (props:any) => {
  const [updating] = useState<boolean>(true);

  const [fundsOnHoldList, setFundsOnHoldList] = useState([]);
  const [historyFundsOnHoldList, setHistoryFundsOnHoldList] = useState([]);
  const [isHistory, setHistory] = useState(false);
  const [currencyID, setCurrencyID] = useState('');
  const [beginningBalance, setBeginningBalance] = useState('');
  const [currentBalance, setCurrentBalance] = useState('');
  // History
  const [momentFromDate, setMomentFromDate] = useState<Moment>(moment().subtract(6, 'months'));
  const [momentToDate, setMomentToDate] = useState<Moment>(moment());
  const [fromDate, setFromDate] = useState<string>(moment().subtract(6, 'months').format('YYYY/MM/DD'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY/MM/DD'));
  const [currentScreen, setCurrentScreen] = useState(0);
  const [regularPayment, setRegularPayment] = useState({});

  const [showTrackForm, setShowTrackForm] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  //rights
  let OL_SpotAccess = useOnlineRights(OnlineRightsNames.OL_SpotAccess);
  let OL_MyAccountInformation_BalancesHistory = useOnlineRights(OnlineRightsNames.OL_MyAccountInformation_BalancesHistory);
  let OL_OrderAccess = useOnlineRights(OnlineRightsNames.OL_OrderAccess);

  useEffect(() => {
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    
    getListOfFundsOnHold(userSession.CustomerID,'').then((response) => {
        if (typeof response !== undefined && response.httpStatusCode === 200 && response.data !== undefined) 
        {
          setFundsOnHoldList(response.data);
        }
        else {
          setFundsOnHoldList([]);
        }
    });
    /*
      response:
        data: Array(1)
        0:
          Amount: 200
          CurrencyID: "AUD"
          PendingWithdrawalAmount: 0
    */
  }, [updating]);

  const historyFunds = (element: any) => {
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let obj = {
      CustomerID: userSession.CustomerID,
      CurrencyID: element != null ? element.CurrencyID : currencyID,
      FromDate: null,//fromDate,
      ToDate: null //toDate,
    }
    setCurrencyID(obj.CurrencyID)
    setCurrentBalance(element != null ? element.Amount : currentBalance);
    getHistoryAuditFundsOnHoldBalances(obj).then((response) => {
      if (response != null && response.httpStatusCode === 200 && response.historyFunds != null) {        
        let begBalance = Number(response.historyFunds[0]['Balance']) - Number(response.historyFunds[0]['TransactionAmount']);
        setBeginningBalance(begBalance.toString());
        setHistoryFundsOnHoldList(response.historyFunds);
        setHistory(true);
      }
      else {
        setHistoryFundsOnHoldList([]);
        setBeginningBalance('0')
      }
    });
  }
  const onUseNowATemplate = (obj: any) => {
    setRegularPayment({
      BuyCurrencyID: obj.CurrencyID,
      SellCurrencyID: obj.CurrencyID,
      SettlementMethod: {
        value: obj.CurrencyID + ";0;Client's Vostro Account;0",
        name: obj.CurrencyID + " - Clients's Vostro Account - " + obj.CurrencyID,
        details: [
          { label: "Currency", description: "" + obj.CurrencyID },
          { label: "Balance", description: obj.Amount },
          { label: "Instrument", description: "Client's Vostro Account" },
          { label: "Delivery Method", description: 'ACH' }
        ],
        label: obj.CurrencyID + " - Clients's Vostro Account - " + obj.CurrencyID
      }
    })
    setCurrentScreen(1)
  }

  const columns: ColumnsType<ResponseGetListOfFundsOnHold> = [
    {
      title: getResource('Js_TH_Currency'),
      dataIndex: 'CurrencyID',
      key: 'responsegetlistoffundsonhold_currency',
      align: 'center',
    },
    {
      title: getResource('Js_TH_Amount'),
      dataIndex: 'Amount',
      key: 'responsegetlistoffundsonhold_amount',
      align: 'center',
      render: (value: any, obj: ResponseGetListOfFundsOnHold, index: any) => (
        <span>{formatToCurrency(value, obj.CurrencyID)}</span>
      ),
    },
    {
      title: getResource('Js_TH_Action'),
      key: 'action',
      align: 'center',
      width: '30%',
      render: (value: any, obj: ResponseGetListOfFundsOnHold, index: any) => (
        obj.Amount > 0 && <div>
          {OL_SpotAccess && <Tooltip title={getResource('Js_TH_Convert')}>
            <Typography.Link
              onClick={() => {
                props.convertBalance(obj);
              }}
              className="column-actions"
            >
              <SyncOutlined />
            </Typography.Link>
          </Tooltip>}
          {OL_OrderAccess && <Tooltip title={getResource('Js_TH_Pay')}>
            <Typography.Link className="column-actions" onClick={() => { onUseNowATemplate(value); setShowTrackForm(true) }}>
              <DollarCircleOutlined />
            </Typography.Link>
          </Tooltip>}
          {OL_MyAccountInformation_BalancesHistory && <Tooltip title={getResource('Js_TH_History')}>
            <Typography.Link className="column-actions" onClick={() => historyFunds(value)}>
              <HistoryOutlined />
            </Typography.Link>
          </Tooltip>}
        </div>
      ),
    },
  ];
  
  const historyColumns: ColumnsType<ResponseGetHistoryOfFundsOnHold> = [
    /*
        "AuditFundsOnHOldBalancesID": 1,
        "User Name": "Willy Oshiro",
        "CurrencyID": "CAD",
        "Source": "Outgoing funds",
        "Destination": "Funds on hold",
        "Amount": 100,
        "TransactionAmount": 100,
        "UpdatedOn": "2021-01-21T18:23:54.793"
         */
    {
      title: getResource('Table_TH_Date'),
      dataIndex: 'UpdatedOn',
      key: 'history_updatedOn',
      align: 'center',
      render: (value: any, obj: ResponseGetHistoryOfFundsOnHold, index: any) => (
        <span>{formatDateString(value)}</span>
      ),
      defaultSortOrder: 'ascend',
      className: 'cell-date',
      sorter: (a, b) => new Date(a.UpdatedOn).getTime() - new Date(b.UpdatedOn).getTime()
    },
    {
      title: getResource('Table_TH_TransactionAmount'),
      dataIndex: 'TransactionAmount',
      key: 'history_transactionAmount',
      align: 'center',
      render: (value: string, obj: ResponseGetHistoryOfFundsOnHold, index: any) => (
        <span>{formatToCurrency(value, obj.CurrencyID)}</span>
      ),
      sorter: (a, b) => a.TransactionAmount - b.TransactionAmount
    },
    {
      title: getResource('Table_TH_Balance'),
      dataIndex: 'Balance',
      key: 'history_balance',
      align: 'center',
      responsive: ['md'],
      render: (value: any, obj: ResponseGetHistoryOfFundsOnHold, index: any) => (
        <span>{formatToCurrency(value, obj.CurrencyID)}</span>
      ),
      sorter: (a, b) => a.Balance - b.Balance
    },
    {
      title: getResource('Table_TH_Source'),
      dataIndex: 'Source',
      key: 'history_source',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) => a.Source.localeCompare(b.Source)
    },
    {
      title: getResource('Table_TH_Destination'),
      dataIndex: 'Destination',
      key: 'history_destination',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) => a.Destination.localeCompare(b.Destination)
    },
    {
      title: getResource('Table_TH_TransactionID'),
      dataIndex: 'AuditFundsOnHOldBalancesID',
      key: 'history_auditFundsOnHOldBalancesID',
      align: 'center',
      responsive: ['md'],
      render: (value: any, obj: ResponseGetHistoryOfFundsOnHold, index: any) => (
        <span>{value}</span>
      ),
      sorter: (a, b) => a.AuditFundsOnHOldBalancesID - b.AuditFundsOnHOldBalancesID
    },
    {
      title: getResource('Table_TH_UserName'),
      dataIndex: 'User Name',
      key: 'history_userName',
      sorter: (a, b) => a['User Name'].localeCompare(b['User Name']),
      align: 'left',
    },
  ];

  //---------Filter History
  const onChangeFromDate = (date: Moment | null, dateString: string) => {
    setFromDate(dateString);
    setMomentFromDate(date as Moment);
  };
  const onChangeToDate = (date: Moment | null, dateString: string) => {
    setToDate(dateString);
    setMomentToDate(date as Moment);
  };
  const onResetFilters = () => {
    setFromDate(moment().subtract(6, 'months').format('YYYY/MM/DD'));
    setMomentFromDate(moment().subtract(6, 'months'));
    setToDate(moment().format('YYYY/MM/DD'));
    setMomentToDate(moment());
    setHistory(true);
  };
  //-----------------------

  const closeFilter = () => {
    setOpenFilter(false);
  }

  const filtered = momentFromDate || momentToDate

  const filterOptions = <>
    {!momentFromDate && !momentToDate && getResource('Title.FilterOptions')}
    {momentFromDate && <span className="input-label">{getResource('DateFrom')}: {fromDate}</span>}
    {momentToDate && <span className="input-label">{getResource('DateTo')}: {toDate}</span>}
  </>

  const filter = <>
    <div>
      <div>
        <div className="row">
          <span className="input-label">{getResource('DateFrom')}:</span>
        </div>
        <div className="row">
          <DatePicker
            id="ratewatch-datepicker-fromdate"
            key="ratewatch-datepicker-fromdate"
            onChange={onChangeFromDate}
            value={momentFromDate}
            submit={true}
            errorMessage={getResource('DataMsgRequired')}
            valueValidation={fromDate}
          />
        </div>
      </div>
      <div>
        <div className="row">
          <span className="input-label">{getResource('DateTo')}:</span>
        </div>
        <div className="row">
          <DatePicker
            id="ratewatch-datepicker-todate"
            key="ratewatch-datepicker-todate"
            onChange={onChangeToDate}
            value={momentToDate}
            submit={true}
            errorMessage={getResource('DataMsgRequired')}
            valueValidation={toDate}
          />
        </div>
      </div>
      <div className="row-actions">
          <Button type="secondary" onClick={() => { historyFunds(null); closeFilter(); }}>
            {getResource('ApplyFilter')}
        </Button>
          <Button type="primary" onClick={() => { onResetFilters(); closeFilter(); }}>
            {getResource('Reset')}
        </Button>
      </div>
    </div>
  </>

  return (
    <React.Fragment>
      <div className="title-text"><h2>{getResource('Tab_MyBalances')}</h2></div>

      {//showTrackForm 
        false && <div className="breadcrumb-container">
        <Breadcrumb separator="">
          <Breadcrumb.Item
            onClick={() => { setCurrentScreen(0); setShowTrackForm(false); }}>{getResource('Tab_MyBalances')}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>}

      {isHistory &&
        <Modal
          title={<h3 className="component-title">{currencyID + ' ' + getResource('Label.HistoryBalance')}</h3>}
          centered
          visible={isHistory}
          footer={null}
          onCancel={() => setHistory(false)}
          maskClosable={false}
          className='modal-message-lg'
          zIndex={500}
        >
          {/* <Collapse defaultActiveKey={['1']} style={{ marginBottom: '15px' }}>
            <Panel header={'Filter Options'} key="1">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <span className="input-label">{getResource('DateFrom')}:</span>
                    </div>
                    <div className="row">
                      <DatePicker
                        id="ratewatch-datepicker-fromdate"
                        key="ratewatch-datepicker-fromdate"
                        onChange={onChangeFromDate}
                        value={momentFromDate}
                        submit={true}
                        errorMessage={getResource('DataMsgRequired')}
                        valueValidation={fromDate}
                      />
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <span className="input-label">{getResource('DateTo')}:</span>
                    </div>
                    <div className="row">
                      <DatePicker
                        id="ratewatch-datepicker-todate"
                        key="ratewatch-datepicker-todate"
                        onChange={onChangeToDate}
                        value={momentToDate}
                        submit={true}
                        errorMessage={getResource('DataMsgRequired')}
                        valueValidation={toDate}
                      />
                    </div>
                  </div>
                  <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                    <Button type="secondary" onClick={() => historyFunds(null)}>
                      {getResource('ApplyFilter')}
                    </Button>
                  </div>
                  <div className="col-md-2 col-xs-12" style={{ paddingTop: 30 }}>
                    <Button type="primary" onClick={onResetFilters}>
                      {getResource('Reset')}
                    </Button>
                  </div>
                </div>
              </div>
            </Panel>
          </Collapse> */}
          <div className='row'>
            <div className='col-xs-12 col-md-12'>
              <label className='bold-text-big'>{'Initial Balance: ' + formatToCurrency(beginningBalance, currencyID) + ' ' + currencyID}</label>
            </div>
            <div className='col-xs-12 col-md-12'>
              <label className='bold-text-big'>{'Current Balance: ' + formatToCurrency(currentBalance, currencyID) + ' ' + currencyID}</label>
            </div>
          </div>
          <div className="row-actions-1">

            <DownloadTableRows data={historyFundsOnHoldList} fileName={'balance'} />1qaz

            <Tooltip title={filterOptions}>
              <Typography.Link onClick={() => setOpenFilter(true)} style={{ marginRight: '10px' }}>
                {filtered && <FilterFilled />}
                {!filtered && <FilterOutlined />}
              </Typography.Link>
            </Tooltip>
          </div>
          <Table
            className=''
            key={'historyFundsOnHoldList-table'}
            columns={historyColumns}
            dataSource={historyFundsOnHoldList}
          />
          <ModalFilter title={getResource('Title.FilterOptions')} open={openFilter} close={closeFilter} panel={filter} />
        </Modal>
      }
      {currentScreen === 0 &&
        <div className="row center">
          <div className="col-md-2 col-xs-1"></div>
          <div className="col-md-8 col-xs-10">
            <div className="row-actions-1" style={{ height: '20px' }}></div>
        <Table
        className=''
        key={'fundsOnHoldList-table'}
        columns={columns}
        dataSource={fundsOnHoldList}
      />
          </div>
          <div className="col-md-2 col-xs-1"></div>
        </div>
      }
      {currentScreen === 1 &&
        <OrdersScreen dealID={0} objOrderScreen={regularPayment} fromBalance={true}
          showCancelButton={true} onBackOrder={() => { setCurrentScreen(0); setShowTrackForm(false); }} />
      }
    </React.Fragment>
  );
};

export default MyBalances;