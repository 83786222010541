import { DeleteOutlined, DollarCircleOutlined, EditOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Table } from 'ant-table-extensions';
import { Breadcrumb, Divider, Modal, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { deleteTemplateByIdAndContactId, getListOfDealTemplates } from '../../../api/NetworkingDealTemplates';
import { scaleByCurrency } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import AddEditOrderTemplate from './AddEditOrderTemplate';

interface OrdersTemplateProps {
  modalOptions?: {
    showOrderTemplate: boolean; //just for type 'modal'
    onCancelModal: Function; //just for type 'modal'
  }
  type: 'modal' | 'inline';
  onUseNowATemplate: Function;
}

const OrdersTemplates: FunctionComponent<OrdersTemplateProps> = (props) => {
  const { showModal } = useContext(AppContext);
  const [updating, setUpdating] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [viewTemplate, setViewTemplate] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  //confirmation
  const [currentTemplateSelect, setCurrentTemplateSelect] = useState(null);
  const [confirmationTitle, setConfirmationTitle] = useState(getResource('Js_MessageError_TitleConfirm'));
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    getListOfDealTemplates().then((responseDealTemplates) => {
      if (responseDealTemplates !== undefined) {
        /*
          BuyCurrency: "AUD"
          ContactId: 9220
          CustomerId: 9633
          DealTemplateID: "894ff894-15d5-460b-893c-4e49a85d7387"
          Disbursements: Array(1)
            0:
            AccountNo: null
            Attachments: []
            BankAccountID: 0
            BankName: null
            BeneficiaryBankAccountID: 1012316
            BeneficiaryID: 13922
            BeneficiaryName: null
            BeneficiaryPaymentID: 0
            BeneficiaryStatus: null
            BeneficiaryType: null
            Comments: null
            CurrencyID: null
            DeliveryMethod: ""
            Instrument: "Electronic Funds Transfer"
            InterfaceBalanceAccountId: null
            PaymentReference: ""
            SellAmount: 0
            ValueDate: "0001-01-01T00:00:00"
            WireItemFee: 0
            WireItemFeeCurrencyID: null
            [[Prototype]]: Object
            length: 1
          [[Prototype]]: Array(0)
          PayIn: "Paying in AUD"
          SellAmount: 456
          SellCurrency: "CAD"
          SendingToBeneficiary: "Sending 456 AUD to 1 beneficiary(ies)"
          TemplateName: "hola"
          UpdateToken: "637638470990500000"
          [[Prototype]]: Object
        */
        //console.log('responseDealTemplates-> ', responseDealTemplates);
        if (responseDealTemplates.data !== undefined) {
          setTemplates(responseDealTemplates.data);
        }
      }
    })
  }, [updating]);

  const columns: ColumnsType<any> = [
    {
      title: getResource('TemplateName'),
      dataIndex: 'TemplateName',
      key: 'templateName',
      align: 'left',
    },
    {
      title: getResource('SendTo'),
      dataIndex: 'SendingToBeneficiary',
      key: 'sendingToBeneficiary',
      align: 'left',
      render: (value: any, obj: any, index: any) => (
        <span>{`Sending ${obj.SellAmount.toFixed(scaleByCurrency(obj.BuyCurrency))} ${obj.BuyCurrency} to ${obj.Disbursements != null ? obj.Disbursements.length : 0} beneficiary(ies)`}</span>
      ),
    },
    {
      title: getResource('Js_TH_Currency'),
      dataIndex: 'PayIn',
      key: 'payIn',
    },
    {
      title: getResource('Js_TH_Action'),
      key: 'action',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <>
          <div className="col-md-12 col-xs-12 middle-xs center-md center-xs">
            {obj['Next Execute'] !== undefined ? (<label>{getResource('NextExecute')}: {obj['Next Execute']}</label>) : (<label>{getResource('NextExecute')}: {getResource('NoSetted')}</label>)}
          </div>
          <div className="col-md-12 col-xs-12 middle-xs center-md center-xs">
            <Tooltip title={getResource('Button.View')}>
              <Typography.Link
                className="column-actions"
                onClick={() => { setCurrentTemplate(obj); setViewTemplate(true); }}
              >
                <ZoomInOutlined style={{ fontSize: 18 }} />
              </Typography.Link>
            </Tooltip>
            <Tooltip title={getResource('Js.Link.Edit')}>
              <Typography.Link
                className="column-actions"
                onClick={() => { }}
              >
                <EditOutlined style={{ fontSize: 18 }} />
              </Typography.Link>
            </Tooltip>
            <Tooltip title={getResource('Js.Link.UseNow')}>
              <Typography.Link
                className="column-actions"
                onClick={() => {
                  props.onUseNowATemplate(obj);
                }}
              >
                <DollarCircleOutlined style={{ fontSize: 18 }} />
              </Typography.Link>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const yesDelete = (template: any) => {
    deleteTemplateByIdAndContactId(template.DealTemplateID).then((deleteResponse) => {
      let title = getResource('Js_MessageError_TitleError');
      let message = getResource('SomethingWentWrongTryAgain');

      if (deleteResponse !== undefined) {
        /*
          {"Message":"The template has been successfully removed.","ErrorMessage":null,"httpStatusCode":200}
          {"Message":"Invalid - This entered contact cannot delete the template.","ErrorMessage":null,"httpStatusCode":412}
        */
        //console.log("deleteResponse -> ", JSON.stringify(deleteResponse));

        switch (deleteResponse.httpStatusCode) {
          case 200: {
            title = getResource('Js_MessageError_TitleSuccess');
            message = deleteResponse.Message;
            setUpdating(!updating);
            break;
          }
          case 412: {
            if (JSON.stringify(deleteResponse.Message).includes('DealTemplateId')) {
              message = getResource('SomethingWentWrongTryAgain');
            } else {
              message = deleteResponse.Message;
            }
            break;
          }
          default: {
            if (deleteResponse.Message !== undefined && deleteResponse.Message !== null && deleteResponse.Message !== '') {
              message = deleteResponse.Message;
            } else {
              if (deleteResponse.ErrorMessage !== undefined && deleteResponse.ErrorMessage !== null && deleteResponse.ErrorMessage !== '') {
                message = deleteResponse.ErrorMessage;
              }
            }
            break;
          }
        }
      }
      setShowConfirmationModal(false);
      showModal(title, message);
    });
  }

  const columnsInline: ColumnsType<any> = [
    {
      title: getResource('TemplateName'),
      dataIndex: 'TemplateName',
      key: 'templateName',
      align: 'left',
      //width: '40%',
    },
    {
      title: getResource('SendTo'),
      dataIndex: 'SendingToBeneficiary',
      key: 'sendingToBeneficiary',
      align: 'left',
      //width: '40%',
      render: (value: any, obj: any, index: any) => (
        <span>{`Sending ${obj.SellAmount.toFixed(scaleByCurrency(obj.BuyCurrency))} ${obj.BuyCurrency} to ${obj.Disbursements != null ? obj.Disbursements.length : 0} beneficiary(ies)`}</span>
      ),
    },
    {
      title: getResource('Js_TH_Action'),
      key: 'action',
      align: 'center',
      //width: '20%',
      render: (value: any, obj: any, index: any) => (
        <>
          <div className="col-md-12 col-xs-12 middle-xs center-md center-xs">
            <Tooltip title={getResource('Delete')}>
              <Typography.Link
                className="column-actions"
                onClick={() => {
                  setCurrentTemplateSelect(obj);
                  setConfirmationMessage(getResource('AreYouSureYouWantToDeleteTemplate') + " '" + obj.TemplateName + "'.");
                  setShowConfirmationModal(true);
                }}
              >
                <DeleteOutlined className='icon-delete-primary-color' style={{ fontSize: 18 }} />
              </Typography.Link>
            </Tooltip>
            <Divider type="vertical" />
            <Tooltip title={getResource('Js.Link.UseNow')}>
              <Typography.Link
                className="column-actions"
                onClick={() => { props.onUseNowATemplate(obj); }}
              >
                <DollarCircleOutlined style={{ fontSize: 18 }} />
              </Typography.Link>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {props.type === 'inline' && <Table
        key={'templates-table-inline'}
        columns={columnsInline}
        dataSource={templates}
        size={'small'}
        pagination={{
          onChange: (page: number) => { setCurrentPage(page) },
          current: currentPage,
          defaultCurrent: 1,
          size: 'default',
          defaultPageSize: 5,
          pageSizeOptions: ["5", "8", "10"],
          showSizeChanger: true,
        }}
        // exportableProps={{
        //   fileName: 'OrderTemplates',
        //   btnProps: {
        //     type: "default",
        //     icon: <DownloadOutlined />,
        //   }
        // }}
      />}
      <Modal
        title={<h3 className="component-title">{getResource('Order Templates')}</h3>}
        visible={props.type === 'modal' && props.modalOptions!.showOrderTemplate}
        footer={null}
        centered
        width={'50%'}
        //style={{ height: '50vh', verticalAlign: 'center', display: 'grid' }}
        onCancel={() => props.modalOptions!.onCancelModal()}
      >
        <Table
          key={'templates-table-modal'}
          columns={columns}
          dataSource={templates}
          size={'small'}
          pagination={{
            onChange: (page: number) => { setCurrentPage(page) },
            current: currentPage,
            defaultCurrent: 1,
            size: 'default',
            defaultPageSize: 8,
            pageSizeOptions: ["5", "8", "10"],
            showSizeChanger: true,
          }}
          // exportableProps={{
          //   fileName: 'OrderTemplates',
          //   btnProps: {
          //     type: "default",
          //     icon: <DownloadOutlined />,
          //   }
          // }}
        />
        {viewTemplate && <AddEditOrderTemplate
          type={'view'}
          templateObj={currentTemplate}
          show={viewTemplate}
          saveTemplateModalProps={{
            ButtonLabel: getResource('Button.AddOrderTemplate'),
            Modaltitle: getResource('OrderTemplate') + ' - ' + currentTemplate.TemplateName
          }}
          onCancel={() => setViewTemplate(false)}
        />}
      </Modal>
      <Modal
        title={
          <div className="breadcrumb-container">
            <Breadcrumb separator="">
              <Breadcrumb.Item >{confirmationTitle}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        }
        centered
        visible={showConfirmationModal}
        footer={null}
        onCancel={() => setShowConfirmationModal(false)}
        maskClosable={false}
        width={'25%'}
        zIndex={1021}
        closable={false}
      >
        <div>
          <div>
            <h4>{confirmationMessage}</h4>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <div className="col">
                <Button id="confirmation-afex-OK" type="primary" onClick={() => { yesDelete(currentTemplateSelect) }}>
                  {getResource('Accept')}
                </Button>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="col">
                <Button id="confirmation-afex-cancel" type="primary" onClick={() => setShowConfirmationModal(false)}>
                  {getResource('Button.Cancel')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default OrdersTemplates;
