import { Modal } from "antd";

function ModalFilter(props: { open: any, close: any, panel: any, title: any }) {
  return (<Modal
    centered
    title={props.title}
    open={props.open}
    footer={null}
    maskClosable={true}
    className='modal-filter'
    zIndex={1000}
    closable={true}
    onCancel={props.close}
  >    {props.panel}
  </Modal>);
}

export default ModalFilter;