import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { getOnlineBanks } from '../../api/NetworkingBranch';
import { getAllCountries, getCountryByName } from "../../api/NetworkingCountries";
import AppContext from '../../App.context';
import { formatValidAutocompleteClass, formatValidInputClass, validInputClass } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import Input from '../inputs/Input';
import RadioButton from '../inputs/RadioButton';
interface IntermediaryBankAccountProps {
  intermediaryBankAccountFields: number;
  [x: string]: any;
}

const IntermediaryBankAccount: React.FC<IntermediaryBankAccountProps> = ({ intermediaryBankAccountFields, ...props }) => {
  const { showModal } = useContext(AppContext);
  const [countryID, setCountryID] = useState('');
  const [bankName, setBankName] = useState('');
  const [transitNo, setTransitNo] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [aba, setAba] = useState('');
  const [swift, setSwift] = useState('');
  const [bankDescription, setBankDescription] = useState('');
  const [banks, setBanks] = useState([]);
  const [bankID, setBankID] = useState('0');
  const [openBanks, setOpenBanks] = useState(false);  
  const [afexBanksSetup, setAfexBanksSetup] = useState(false);
  const [searchBy, setSearchBy] =useState('bank');
  const [countries, setCountries] = useState([]);

  const [validations, setValidations] = useState({
    countryID: true,
    bankName: true
  });

  const validationsFields = () => {
    var valCountryID = (bankName !== null && bankName !== '') ? validInputClass(countryID) : true;
    var valBankName = (countryID !== null && countryID !== '') ? validInputClass(bankName) : true;

    setValidations({
      countryID: valCountryID,
      bankName: valBankName
    });
    var isValid = valCountryID && valBankName;
    if (!isValid) { showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted')); }
    else {
      props.onUpdateStep(2);
    }

  }
  useEffect(() => {
    const loadCountries = async () => {
      const json = await getAllCountries();
      if (json != null && json.countries != null) {
        console.log(json);
        const formattedCountries = json.countries.map((c: any) => ({ ...c, label: `${c.Country} - ${c['Country Name']}`, value: c.Country }));
        formattedCountries.push({ label: 'Select a Country...', value: '' });
        setCountries(formattedCountries);
      } else {
        setCountries(props.countries);
      }
    }
    loadCountries();
  }, [props.countries]);

  useEffect(() => {
    if (props.intermediary !== undefined) {
      setCountryID(props.intermediary.CountryID);
      setBankName(props.intermediary.BankName);
      setTransitNo(props.intermediary.TransitNo);
      setAccountNo(props.intermediary.AccountNo);
      setAba(props.intermediary.ABA);
      setSwift(props.intermediary.Swift);
      setBankDescription(props.intermediary.BankName);
    }
    var userSession = JSON.parse(localStorage.getItem('UserSession')!);
    if (userSession.BeneficiaryBanksConfiguration === 'AFEX') {
      setAfexBanksSetup(true);
    }

  }, [props.intermediary]);

  useEffect(() => {
    if (intermediaryBankAccountFields != null && intermediaryBankAccountFields > 0) {
      validationsFields();
    }
  }, [intermediaryBankAccountFields]);

  const countryChangeHandler = (item: any, option: any) => {
    if (item != null) {
      setCountryID(item.value);
      setValidations({ ...validations, countryID: true });
      props.onUpdateIntermediaryBankAccount('CountryID', item.value);
    }
    if (option != null && !option.label.includes('Select')) {
      setCountryID(option.label);
      setValidations({ ...validations, countryID: true });
      props.onUpdateIntermediaryBankAccount('CountryID', item);
    }
  };

  const onSearch = (value: any) => {
    setCountryID(value);
    let newValue = value.split(' -');
    getCountryByName(newValue[0]).then(
      (json) => {
        if (json.countries != null) {
          const formattedCountries = json.countries.map((c: any) => ({ ...c, label: c.CountryIdName, value: c.CountryId }));          
          setCountries(formattedCountries);
        }
      }
    )
  }
  const handleSearchBank = (value: string) => {
    setBankDescription(value);
  }

  const onSearchBank = () => {  
    getOnlineBanks(bankDescription, countryID, searchBy).then((response) => {
      if (response !== undefined && response !== null && response.httpStatusCode === 200 && response.banks !== undefined) {
        console.log(response.banks);
        let banks = response.banks;

        banks.forEach((x: any) => {
          if (afexBanksSetup) {
            let id = x['BankName'] + ',' + x['SWIFT'];
            let desc = x['BankName'] + ',' + x['SWIFT'] + ',' + x['Address'] + ',' + x['City'];
            x.value = id;
            x.label = desc;
            x.name = x['BankName']
          } else {
            x.value = x['Bank ID'];
            x.label = x['Bank Name'];
            x.name = x['Bank Name'];
          }
        });
        setBanks(banks);
      } else {
        setBanks([]);
      }
      setOpenBanks(true);
    });
  };

  const onSelectBank = (item: any, option: any) => {
    setBankDescription(option.label);
    setBankID(option.value);
    setBankName(option.name);
    setAba(option.ABA);
    setSwift(option.SWIFT);
    setOpenBanks(false);
    setValidations({ ...validations, bankName: true });
    props.onUpdateIntermediaryBankAccount('BankName', option.name);
    props.onUpdateIntermediaryBankAccount('ABA', option.ABA);
    props.onUpdateIntermediaryBankAccount('Swift', option.SWIFT);
  };

  const onChange = (data: string, option: any) => {
    setBankDescription(data);
    setOpenBanks(false);
  };

  const onChangeTransitNo = (e: any) =>
{ 
    setTransitNo(e.target.value);
    props.onUpdateIntermediaryBankAccount('TransitNo', e.target.value);
}

const onChangeAccountNo = (e: any) =>
{ 
    setAccountNo(e.target.value);
    props.onUpdateIntermediaryBankAccount('AccountNo', e.target.value);
}

const onChangeABA = (e: any) =>
{ 
    setAba(e.target.value);
    props.onUpdateIntermediaryBankAccount('ABA', e.target.value);
}

const onChangeSWIFT = (e: any) =>
{ 
    setSwift(e.target.value);
    props.onUpdateIntermediaryBankAccount('Swift', e.target.value);
}

const radioChangeHandler = (event: any) => {
  setSearchBy(event.target.value);
};

  return (
    <div className={props.canEdit ? '' : 'readonly'}>
      <div>
        <div className="row max-w-xs-sm">
          <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.Country') + '(*)'}</span>
            </div>
            <div className="row">
              <AutoComplete
                allowClear
                options={countries}
                className={formatValidInputClass(validations.countryID)}
                placeholder={getResource('SelectCountry')}
                notFoundContent={getResource('PlaceHolder.NoResults')}
                value={countryID}
                onSelect={countryChangeHandler}
                onSearch={onSearch}
              />
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            {props.canEdit && afexBanksSetup && (
              <>
                <div className="row">{getResource('Label.SearchBy')}</div>
                <div className="row">
                  <div className="col-md-2 col-xs-12">
                    <RadioButton changed={radioChangeHandler} id="1" isSelected={searchBy === 'bank'} label={getResource('Label_Bank')} value="bank" />
                  </div>
                  <div className="col-md-2 col-xs-12">
                    <RadioButton changed={radioChangeHandler} id="2" isSelected={searchBy === 'swift'} label={getResource('Label_Swift')} value="swift" />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="row max-w-xs-sm">
          <div className="col-md-12 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.BankName') + '(*)'}</span>
            </div>
            {!props.canEdit && (
              <div className="row">
                <input type="text" value={bankDescription} ></input>
              </div>
            )}

            {props.canEdit && (
              <div className="row">
                <div className="col-md-6 col-xs-12">
                  <AutoComplete
                    allowClear
                    options={banks}
                    notFoundContent={getResource('PlaceHolder.NoResults')}
                    placeholder={getResource('PlaceHolder.BankName')}
                    open={openBanks}
                    onSelect={onSelectBank}
                    onChange={onChange}
                    value={bankDescription}
                    onSearch={handleSearchBank}
                    className={formatValidAutocompleteClass(validations.bankName)}
                  />
                </div>
                <div className="col-md-6 col-xs-12"><div className="row">
                  <Button type="search" onClick={onSearchBank}>
                    <SearchOutlined />
                  </Button>
                </div></div>
              </div>
            )}
          </div>
        </div>

        <div className="row max-w-xs-sm">
          <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.SWIFT')}</span>
            </div>
            <div className="row">
              <Input type="text" value={swift} onChange={onChangeSWIFT}></Input>
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.AccountNo')}</span>
            </div>
            <div className="row">
              <Input type="text" value={accountNo} onChange={onChangeAccountNo}></Input>
            </div>
          </div>
        </div>

        <div className="row max-w-xs-sm">
          <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.TransitNo')}</span>
            </div>
            <div className="row">
              <Input type="text" value={transitNo} onChange={onChangeTransitNo}></Input>
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="row">
              <span className="input-label">{getResource('Label.ABA')}</span>
            </div>
            <div className="row">
              <Input type="text" value={aba} onChange={onChangeABA}></Input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntermediaryBankAccount;
