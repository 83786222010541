import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { formatToCurrency } from "../../../../../helpers/FormatHelper";
import { getResource } from "../../../../../helpers/ResourcesHelper";
import Button from "../../../../inputs/Button";

const SummaryContact = (props: any) => {
  const [contactObj, setContactObj] = useState({} as any);
  //--------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (props.contactObj != null) {
      setContactObj(props.contactObj);
    }
  }, [props.contactObj]);

  const entityProperties = (obj: string) => {
    if (obj != null) {
      const contactObjAux = JSON.parse(obj!).EntityProperty;
      let isShareHolder = false, valuePercentage = '';
      contactObjAux.forEach((entityProperty: any) => {
        switch (entityProperty.Description) {
          case 'Share Holder':
            switch (entityProperty.Value) {
              case 'Yes':
                {
                  isShareHolder = true;
                  break;
                }
              default:
                {
                  isShareHolder = false;
                  break;
                }
            }
            break;

          case 'Share Holder Percent':
            let auxValuePercentage: number = entityProperty && entityProperty.Value ? parseInt(entityProperty.Value.toString()) : 0;

            if (auxValuePercentage > 0) isShareHolder = true;

            valuePercentage = auxValuePercentage.toString();
            break;
          default:
            break;
        }
      });
      return (
        <>
          <div className="row start-xs">
            <div className="col-xs-3 bold-text">{getResource('IsShareholder')}</div>
            <div className="col-xs-4 bold-text-big">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={isShareHolder}
              />
            </div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-3 bold-text">{getResource('ShareHolderPercentage')}</div>
            <div className="col-xs-4">
              <div className="col-xs-4">{formatToCurrency(valuePercentage)}</div>
            </div>
          </div>
        </>
      )
    }
  }

  return (
    <React.Fragment>
      <div className="screen-container row">
        <div className="col-xs-6">
          {/*---------------CONTACT INFO--------- */}
          <div className="row start-xs middle-xs">
            <div className="col-xs-12 subtitle-text-primary">{getResource('ContactInformation')}</div>
            <div className="col-xs-8 row end-xs middle-xs" style={{ margin: 0, padding: 0 }}>
            </div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('MainContact')}</div>
            <div className="col-xs-6 bold-text-big">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={contactObj.MainContact === 1 ? true : false}
              />
            </div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.FirstName')}</div>
            <div className="col-xs-6">{contactObj.FirstName}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.LastName')}</div>
            <div className="col-xs-6">{contactObj.LastName}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.PhoneNumber')}</div>
            <div className="col-xs-6">{contactObj.PhoneNumber}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.MobileNumber')}</div>
            <div className="col-xs-6">{contactObj.MobileNumber}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.Email')}</div>
            <div className="col-xs-6">{contactObj.Email}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.DateBirth')}</div>
            <div className="col-xs-6">{contactObj.DateOfBirth}</div>
          </div>

        </div>
        <div className="col-xs-6">
          {/*---------------ADDRESS--------- */}
          <div className="row start-xs middle-xs">
            <div className="col-xs-12 subtitle-text-primary">{getResource('Label.Addresses')}</div>
            <div className="col-xs-8 row end-xs middle-xs" style={{ margin: 0, padding: 0 }}>
            </div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.Address1')}</div>
            <div className="col-xs-6">{contactObj.AddressValue}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.Address2')}</div>
            <div className="col-xs-6">{contactObj.Address2}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.City')}</div>
            <div className="col-xs-6">{contactObj.City}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.PostalZip')}</div>
            <div className="col-xs-6">{contactObj.ZipCode}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.Country')}</div>
            <div className="col-xs-6">{contactObj.CountryName}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-6 bold-text">{getResource('Label.ProvinceState')}</div>
            <div className="col-xs-6">{contactObj.StateName}</div>
          </div>

        </div>
        <div className="col-xs-12">
          {/*---------------TRADE INFO--------- */}
          <div className="row start-xs middle-xs">
            <div className="col-xs-4 subtitle-text-primary">{getResource('Label.TradeInformation')}</div>
            <div className="col-xs-8 row end-xs middle-xs" style={{ margin: 0, padding: 0 }}>
            </div>
          </div>
          {entityProperties(contactObj.EntityProperties)}
          <div className="row start-xs">
            <div className="col-xs-3 bold-text">{getResource('OnlineAccess')}</div>
            <div className="col-xs-4">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={contactObj.OnlineAccess}
              />
            </div>
            {/*---switch */}
          </div>
          <div className="row start-xs">
            <div className="col-xs-3 bold-text">{getResource('TradeLimit')}</div>
            <div className="col-xs-4">{contactObj.TradeLimit}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-3 bold-text">{getResource('TradeLimitWoe')}</div>
            <div className="col-xs-4">{contactObj.TradeLimitWarningOrEnforce}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-3 bold-text">{getResource('TradeLimitDaily')}</div>
            <div className="col-xs-4">{contactObj.DailyTradeLimit}</div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-3 bold-text">{getResource('TradeLimitDailyWoe')}</div>
            <div className="col-xs-4">{contactObj.DailyTradeLimitWarningOrEnforce}</div>
          </div>
        </div>
        {/*-----------CANCEL BUTTON---------*/}
        <div className="row-actions">
            <Button
              type="secondary"
              onClick={() => {
                props.onClickCancel();
              }}
            >
            {getResource('Btn_Exit')}
          </Button>
        </div>
      </div>

    </React.Fragment>
  );
};

export default SummaryContact;