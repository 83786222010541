import { EditOutlined, MinusCircleOutlined, RollbackOutlined, ZoomInOutlined } from '@ant-design/icons';
import { Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { inactiveAndActiveContact } from '../../../../api/NetworkingContacts';
import { Contact, getListOfCustomerContacts } from '../../../../api/NetworkingCustomers';
import useWindowSize from '../../../../api/hooks/useWindowResize';
import { getResource } from '../../../../helpers/ResourcesHelper';
import Button from '../../../inputs/Button';
import ConfirmationModal from '../../../others/ConfirmationModal';
import DownloadTableRows from '../../../shared/DownloadTableRows';
import AddEditContact from './AddEditContact';
import SummaryContact from './summary/SummaryContact';
interface Item extends Contact {
}

const Contacts = () => {
  const [updating, setUpdating] = useState<boolean>(true);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [editContact, setEditContact] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const [currentContactID, setCurrentContactID] = useState(0);
  const [currentContact, setCurrentContact] = useState<Contact|null>(null);
  const [hastMainContact, setHastMainContact] = useState<object>({'can': false, 'id':-1});
  const [viewContact, setViewContact] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');

  const size = useWindowSize();

  useEffect(() => {
    const loadContacts = async () => {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const json = await getListOfCustomerContacts(customerID);
      if (json != null && json.Contacts) {
        json.Contacts.forEach(contact => {
          if(contact.MainContact === 1)
          {
            setHastMainContact({'can': true, 'id': contact.ContactID});
          }
        });
        setContacts(json.Contacts);
      }
    };
    loadContacts();
  }, [updating]);

  const updateStatus = (contactID: number) => {
    inactiveAndActiveContact(contactID).then(
      (json) => {
        if (json.httpStatusCode === 200) {
          if (json.result) {
            setUpdating(!updating);
            setCurrentContactID(0);
            setShowConfirmModal(false);
          }
        }
      }
    )
  }
  const onInactiveContact = (record: Item, inactive: boolean) => {
    setCurrentContactID(record.ContactID);

    if (inactive) {
      setConfirmMessage(getResource('Js_Message_ConfirmInactiveContact'));      
    }
    else {
      setConfirmMessage(getResource('Js_Message_ConfirmActiveContact'));
    }
    setShowConfirmModal(true);
  }

  const onYesInactiveContact = () => {
    updateStatus(currentContactID);
  }

  const onNoInactiveContact = () => {
    setCurrentContactID(0);
    setShowConfirmModal(false);
  }

  const edit = (record: Item) => {
    setCurrentContactID(record.ContactID);
    setCurrentContact(record);
    setEditContact(true);
  }

  const view = (record: Item) => {
    setCurrentContactID(record.ContactID);
    setCurrentContact(record);
    setViewContact(true);
  }

  const columns: any = [
    {
      title: getResource('Label.FirstName'),
      dataIndex: 'FirstName',
      key: 'FirstName'
    },
    {
      title: getResource('Label.LastName'),
      dataIndex: 'LastName',
      key: 'LastName'
    },
    {
      title: getResource('Label.Email'),
      dataIndex: 'Email',
      key: 'Email',
      width: '25%',
    },
    {
      title: getResource('MainContact'),
      dataIndex: 'MainContact',
      key: 'MainContact',
      responsive: ['md'],
      width: '10%',
      align: 'center',
      render: (_: any, record: Item) => (
        <>
          {(record.MainContact == 1) ? getResource('Yes') : getResource('No')}
        </>
      )
    },
    {
      title: getResource('Label.Status'),
      dataIndex: 'Status',
      key: 'Status',
      responsive: ['md'],
      width: '15%',
      align: 'center',
      render: (_: any, record: Item) => (
        <>
          {(record.Status === 'Suspicious') ? 'Pending activation' : record.Status}
        </>
      )
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_Actions') : "",
      dataIndex: 'Actions',
      align: 'center',
      fixed: 'right',
      render: (_: any, record: Item) => (
        <>
          {record.Status !== 'Inactive' &&
          <Tooltip title={getResource('Option.Inactive')}>
            <Typography.Link
              onClick={() => {
                  onInactiveContact(record, true);
              }}
              className="column-actions"
            >
              <MinusCircleOutlined />
            </Typography.Link>
            </Tooltip>}
          {record.Status === 'Inactive' &&
            <Tooltip title={getResource('Option.Active')}>
              <Typography.Link
                onClick={() => {
                  onInactiveContact(record, false);
                }}
                className="column-actions"
              >
                <RollbackOutlined />
              </Typography.Link>
            </Tooltip>}
          {record.Status !== 'Pending activation' && record.Status !== 'Disabled' && record.Status !== 'Inactive' && 
          <Tooltip title={getResource('Js.Link.Edit')}>
          <Typography.Link 
            key={`contacts-${record.ContactID}-edit`}
            id={`contacts-${record.ContactID}-edit`}
            onClick = {
              () => edit(record)
            }
                className="column-actions"
          >
            <EditOutlined style={{fontSize: 18}}/>
          </Typography.Link>
            </Tooltip>}
          <Tooltip title={getResource('Button.View')}>
            <Typography.Link
              onClick={() => {
                view(record);
              }}
              className="column-actions"
            >
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];
  
  return (
    <>
      {!editContact && !newContact && !viewContact && <>
        <div className="row-actions-1">
          <DownloadTableRows data={contacts} fileName={'contacts'} />
        </div>
        <Table
          columns={columns}
          dataSource={contacts}
        />
        <div className="row-actions">
          <Button
            id="contacts-add-contact"
            type="secondary"
            onClick={() => { setNewContact(true); }}
          >{getResource('AddContact')} </Button>
        </div>
      </>}
      {newContact && <AddEditContact
        contactID       = {0}
        contactObj      = {null}
        onCancelAddEdit = {() => { 
          setEditContact(false); 
          setNewContact(false); 
          setUpdating(!updating);
        }}
        do                = {'add'}
        hasMainContact = {hastMainContact}
      />}
      {editContact && <AddEditContact
        contactID       = {currentContactID}
        contactObj      = {currentContact}
        onCancelAddEdit = {() => { 
          setEditContact(false); 
          setUpdating(!updating)
        }}
        do                = {'edit'}
        hasMainContact = {hastMainContact}
      />}
      {viewContact && <SummaryContact
        contactID={currentContactID}
        contactObj={currentContact}
        onClickCancel={() => setViewContact(false)}
      />}
      <ConfirmationModal visible={showConfirmModal} cancel={onNoInactiveContact} confirm={onYesInactiveContact} title={confirmMessage} />
    </>
  );
};

export default Contacts;