import { genericCallWithParametersNoEncrypted, genericGetWithParameters } from './Networking';

export async function getRate(customerID: number, contactID: number, dealType: number, valueDate:string, forwardNearDate:string, buyCurrency:string, sellCurrency:string, dealAmount:number, 
    lockedSide:string, totalFeesAmount:number, externalInterface:string, externalInterfaceType:string){
    try {
        let params = {"customerID": customerID,
            "contactID": contactID,
            "dealType":dealType,
            "valueDate":valueDate,
            "forwardNearDate":forwardNearDate,
            "buyCurrency":buyCurrency,
            "sellCurrency":sellCurrency,
            "dealAmount":dealAmount,
            "lockedSide":lockedSide,
            "totalFeesAmount": totalFeesAmount,
            isOnlineRequest: true,
            farLegDate: null,
            farLegDealAmount: null,
            externalInterface: externalInterface,
            externalInterfaceType: externalInterfaceType,
        };

        let response = await genericGetWithParameters('Rate/GetRate',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getManualRate(manualRateQuoteId: string){
    try {
        let params = {
            rateQuoteID: manualRateQuoteId
        };

        let response = await genericGetWithParameters('Rate/GetManualRate',params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function notQuotedPendingRateActivity(rateQuoteID: any) {
    let response = await genericCallWithParametersNoEncrypted('Rate/NotQuotedPendingRateActivity', rateQuoteID);
    return response;
}