import { MenuOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { FunctionComponent, useEffect } from 'react';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';

let rateWatch = getResource('Title.RateWatch')
let approvalsTitle = getResource('Tab_Approvals')
let invoicingTitle = getResource('Title.Invoicing')

const items: MenuProps['items'] = [];

let ddIcon = <MenuOutlined />

interface CNavBarProps {
  onChangeScreen: Function;
  currentScreen: string;
  onlineRights: string;
}

const CNavBar: FunctionComponent<CNavBarProps> = (props) => {

  const showMenuItem = (onlineRightsStr: string, onlineRight: string) => {
    let rightsOnline;
    if (onlineRightsStr !== null && onlineRightsStr !== '') {
      rightsOnline = onlineRightsStr;
    }
    else {
      rightsOnline = localStorage.getItem('OnlineRights');
    }

    if (rightsOnline !== null && rightsOnline !== '') {
      let onlineRights = JSON.parse(rightsOnline);
      if (onlineRights) {
        var right = onlineRights.find((element: any) => element.Right === onlineRight);
        if (right !== undefined) {
          return true
        }
      }
    }
    return false
  }


  let userSession = JSON.parse(localStorage.getItem('UserSession')!);
  let OL_RatesSection = showMenuItem(props.onlineRights, OnlineRightsNames.OL_RatesSection);
  let OL_RatesSpotAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_RatesSpotAccess);
  let OL_RatesForwardAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_RatesForwardAccess);
  let OL_DealApprove = showMenuItem(props.onlineRights, OnlineRightsNames.OL_DealApprove);
  let OL_BeneficiaryApprove = showMenuItem(props.onlineRights, OnlineRightsNames.OL_BeneficiaryApprove);
  let OL_Invoicing = showMenuItem(props.onlineRights, OnlineRightsNames.OL_Invoicing);

  useEffect(() => {
    if (OL_RatesSection &&
      (OL_RatesSpotAccess || OL_RatesForwardAccess)) {
      items.push({
        label: rateWatch,
        key: '1'
      })
    }
    if ((userSession.DealApproveRequired && OL_DealApprove)
      || (userSession.BeneApproveRequired && OL_BeneficiaryApprove)) {
      items.push({
        label: approvalsTitle,
        key: '2'
      })
    }
    if (OL_Invoicing) {
      items.push({
        label: invoicingTitle,
        key: '3'
      })
    }
  }, [])

  return (  
  <Space wrap>
    <Dropdown.Button
      menu={{
          items: items, selectable: true, onClick: e => {
          if (e.key === '1') {
            props.onChangeScreen('rates')
          }
          else if (e.key === '2') {
            props.onChangeScreen('approvals')
          }
          else if (e.key === '3') {
            props.onChangeScreen('invoicing')
          }
        }
      }}
      icon={ddIcon}>
    </Dropdown.Button>
  </Space>
);
}

export default CNavBar;