import { PaperClipOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import * as mime from 'mime-types';
import moment from 'moment';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import AppContext from '../../App.context';
import { getAttachmentInvoice, saveInvoices } from '../../api/NetworkingInvoice';
import { LookUpTableDetail } from '../../api/hooks/useLookUpTables';
import { formatDate, formatValidInputClass } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import AttachmentsForm from '../attachments/AttachmentsForm';
import Button from '../inputs/Button';
import DatePicker from '../inputs/DatePicker';
import Dropdown from '../inputs/Dropdown';
import Input from '../inputs/Input';
import ConfirmationModal from '../others/ConfirmationModal';
const dateFormat = 'YYYY/MM/DD';

interface InvoiceAddEditFormDialogProps {
  InvoiceType: string;
  settlementCurrencies: any[];
  onClose: () => void;
  beneficiaries: any[];
  changeState?: Function;
  onLoadInvoices: () => void;
  objInvoice: any;
  StatusList: any[];
}

const InvoiceAddEditFormDialog: FunctionComponent<InvoiceAddEditFormDialogProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [invoiceDate, setInvoiceDate] = useState(moment(new Date(), dateFormat).format(dateFormat))
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [currencyId, setCurrencyId] = useState('');
  const [taxes, setTaxes] = useState(0);
  const [total, setTotal] = useState(0);
  const [senderID, setSenderID] = useState('');
  const [senderName, setSenderName] = useState('');
  const [customerEmail, setCustomerEmail] = useState('');
  const [invoiceLink, setInvoiceLink] = useState('');
  const [fileName, setFileName] = useState('');
  const [fileContent, setFileContent] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [beneficiaryId, setBeneficiaryId] = useState(0);
  const [validations, setValidations] = useState({
    invoiceNumber: true,
    invoiceDate: true,
    invoiceDescription: true,
    amount: true,
    currencyId: true,
    taxes: true,
    senderID: true,
    senderName: true,
    terms: true,
    beneficiaryId: true,
    status: true
  });
  const [terms, setTerms] = useState(0);
  const [availableCurrencies, setAvailableCurrencies] = useState([]);
  const [scaleAmount, setScaleAmount] = useState(2);
  const [invoiceID, setInvoiceID] = useState(0);
  const [updateToken, setUpdateToken] = useState('');
  const [status, setStatus] = useState('');

  const [currentFileName, setCurrentFileName] = useState('');
  const [currentFileURLList, setCurrentFileURLList] = useState([]);
  const [showOpenAlert, setShowOpenAlert] = useState(false);
  const [currentBlob, setCurrentBlob] = useState({} as any);
  const [currentExtension, setCurrentExtension] = useState('');
  const [openAttachment, setOpenAttachment] = useState(false);


  useEffect(() => {
    setAvailableCurrencies(props.settlementCurrencies as any);
  }, [props.settlementCurrencies])

  useEffect(() => {
    if (props.objInvoice && props.objInvoice.InvoiceIssuedID > 0) {
      setInvoiceID(props.objInvoice.InvoiceIssuedID);
      setInvoiceNumber(props.objInvoice.InvoiceNumber);
      setInvoiceDate(props.objInvoice.InvoiceDate);
      setInvoiceDescription(props.objInvoice.InvoiceDescription);
      setAmount(props.objInvoice.Amount);
      setCurrencyId(props.objInvoice.CurrencyID);
      setTaxes(props.objInvoice.Taxes);
      setTotal(props.objInvoice.Total);
      setSenderID(props.objInvoice.ExternalCustomerID);
      setSenderName(props.objInvoice.ExternalCustomerName);
      setCustomerEmail(props.objInvoice.Email);
      setInvoiceLink(props.objInvoice.InvoiceLink);
      setFileName(props.objInvoice.FileName);
      setBeneficiaryId(props.objInvoice.BeneficiaryID);
      setStatus(props.objInvoice.Status);
    }
    else {
      clearData();
    }
  }, [props.objInvoice])

  const onClickExit = () => {
    props.onClose();
  }

  const clearData = () => {
    setInvoiceID(0);
    setInvoiceNumber('');
    setInvoiceDate(moment(new Date(), dateFormat).format(dateFormat));
    setInvoiceDescription('');
    setAmount(0);
    setCurrencyId('');
    setTaxes(0);
    setTotal(0);
    setSenderID('');
    setSenderName('');
    setCustomerEmail('');
    setInvoiceLink('');
    setFileName('');
    setFileContent([]);
    setShowUpload(false);
    setBeneficiaryId(0);
    setTerms(0);
    setUpdateToken('');
    setStatus('');
    setValidations({
      invoiceNumber: true,
      invoiceDate: true,
      invoiceDescription: true,
      amount: true,
      currencyId: true,
      taxes: true,
      senderID: true,
      senderName: true,
      terms: true,
      beneficiaryId: true,
      status: true
    });
    setShowConfirmation(false);
    if (props.InvoiceType === 'Payable') {
      setAvailableCurrencies([]);
    }
  }

  const handleInvoiceNumber = (event: any) => {
    setInvoiceNumber(event.target.value);
  }

  const handleUpdateInvoiceDate = (date: any) => {
    setInvoiceDate(date);
  }

  const handleInvoiceDescription = (event: any) => {
    setInvoiceDescription(event.target.value);
  }

  const handleGetCurrency = (obj: any) => {
    if (obj != null && obj.value != null) {
      setCurrencyId(obj.value);
      setScaleAmount(obj.numberOfDecimals);
    }
  }

  const handleGetBeneficiary = (obj: any) => {
    if (obj != null && obj.value != null) {
      var beneCurrencies = [] as any;
      var currencies = props.settlementCurrencies;
      var auxAvailableCurrencies = [] as any;
      if (obj.Currencies != null && obj.Currencies !== '') {
        beneCurrencies = obj.Currencies.replace('(', '').replace(')', '').split(',');

        currencies.forEach((element: any) => {
          var ccy = beneCurrencies.findIndex((ccy: any) => element.value === ccy);
          if (ccy >= 0) {
            auxAvailableCurrencies.push(element);
          }
        });
      }
      setBeneficiaryId(obj.value)
      setAvailableCurrencies(auxAvailableCurrencies);
    }
  }

  const handleInvoiceAmount = (value: number) => {
    var auxTotal = taxes + value;
    setAmount(value);
    setTotal(auxTotal);
  }

  const handleInvoiceTerms = (value: number) => {
    setTerms(value);
  }

  const handleInvoiceTaxes = (value: number) => {
    var auxTotal = amount + value;
    setTaxes(value);
    setTotal(auxTotal);
  }

  const handleSenderID = (event: any) => {
    setSenderID(event.target.value);
  }

  const handleSenderName = (event: any) => {
    setSenderName(event.target.value);
  }

  const handleCustomerEmail = (event: any) => {
    setCustomerEmail(event.target.value);
  }

  const handleInvoiceLink = (event: any) => {
    setInvoiceLink(event.target.value);
  }

  const onAttachmentsClick = () => {
    setShowUpload(true);
  }

  const onCloseAttachmentsForm = () => {
    setShowUpload(false);
  }

  const onUploadFile = (attachmentID: string, filename: string, description: any, fileContent: any) => {
    //(attachmentID, fileName, description) => {
    //console.log('--attachmentID -> ' + { attachmentID } + '--fileName' + fileName + '--description' + description);
    if (attachmentID != null) {
      hideLoadingModal();
      setShowUpload(false);
      setFileName(filename);
      setFileContent(fileContent);
    }
  }

  const handleSaveClick = () => {    
    if (validate()) {
      setShowConfirmation(true);
    } else {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
  }

  const resetValidations = (validations: any) => {
    validations.invoiceNumber =
      validations.invoiceDate =
      validations.invoiceDescription =
      validations.amount =
      validations.currencyId =
      validations.taxes =
      validations.senderName =
      validations.beneficiaryId =
    validations.senderID =
    validations.status =
      validations.terms = true;
    return validations;
  }

  const validate = () => {
    var result = true;
    var tempValidations = validations;
    tempValidations = resetValidations(tempValidations);

    if (invoiceNumber === null || invoiceNumber === undefined || invoiceNumber === '') {
      result = false;
      tempValidations.invoiceNumber = false;
    }

    if (invoiceDate === null || invoiceDate === undefined || invoiceDate === '') {
      result = false;
      tempValidations.invoiceDate = false;
    }

    if (invoiceDescription === null || invoiceDescription === undefined || invoiceDescription === '') {
      result = false;
      tempValidations.invoiceDescription = false;
    }

    if (amount === null || amount === undefined || amount === 0) {
      result = false;
      tempValidations.amount = false;
    }

    if (currencyId === '' || currencyId === 'select') {
      result = false;
      tempValidations.currencyId = false;
    }

    if (taxes === null || taxes === undefined) {
      result = false;
      tempValidations.taxes = false;
    }

    if (props.InvoiceType === 'Receivable' && (senderID === undefined || senderID === '')) {
      result = false;
      tempValidations.senderID = false;
    }

    if (props.InvoiceType === 'Receivable' && (senderName === undefined || senderName === '')) {
      result = false;
      tempValidations.senderName = false;
    }


    if (props.InvoiceType === 'Payable' && (beneficiaryId === undefined || beneficiaryId === 0)) {
      result = false;
      tempValidations.senderName = false;
    }

    if (status === '' || status.includes('Select ')) {
      result = false;
      tempValidations.status = false;
    }

    if (terms === undefined) {
      result = false;
      tempValidations.terms = false;
    }
    setValidations(tempValidations);

    return result;
  }

  const closeAlert = () => {
    setShowConfirmation(false);
  }

  const yesAlert = () => {
    //setState({ showConfirmation: false });
    showLoadingModal();
    const userSession = JSON.parse(localStorage.getItem('UserSession')!);
    var userID = localStorage.getItem('UserID');
    var customerId = userSession.CustomerID;
    var invoices = [];
    var date = formatDate(invoiceDate);
    invoices.push({
      InvoiceIssuedId: invoiceID,
      CustomerId: customerId,
      InvoiceNumber: invoiceNumber,
      InvoiceDate: date,
      InvoiceDescription: invoiceDescription,
      Amount: amount,
      CurrencyId: currencyId,
      Taxes: taxes,
      Total: total,
      ExternalCustomerID: senderID,
      ExternalCustomerName: senderName,
      ExternalCustomerEmail: customerEmail,
      InvoiceLink: invoiceLink,
      CreatedBy: userID,
      FileName: fileName,
      FileContent: fileContent,
      InvoiceType: props.InvoiceType,
      Terms: terms,
      BeneficiaryID: beneficiaryId,
      Status: status,
      UpdateToken: updateToken
    });


    var model = { Invoices: invoices };

    saveInvoices(model).then(
      (response) => {
        if (typeof response !== undefined && response.httpStatusCode === 200) {
          hideLoadingModal();
          showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_SaveSuccessfully'));
          clearData();
          props.onLoadInvoices();
          onClickExit();
        }
        else {
          hideLoadingModal();
          showModal(getResource('Js_MessageError_TitleError'), getResource('Something went wrong. Please try again later.'));
        }
      });
  }

  const statusFilterChangeHandler = (detail: LookUpTableDetail) => {
    if (detail != null) {
      setStatus(detail.value);
    }
  };

  const onCloseViewAttachment = () => {
    setCurrentFileName([] as any);
    setCurrentFileURLList([] as any);
    setOpenAttachment(false);
  }

  const getDate = (dateString: string) => {
    if (dateString === '') {
      return undefined;
    }
    const date = moment(dateString, dateFormat);
    return date && date.isValid() ? date : undefined;
  };

  const onViewAttachment = () => {
    getAttachmentInvoice(invoiceID).then(
      (value) => {
        var byteCharacters = atob(value.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var bytes = new Uint8Array(byteNumbers);
        var blob = new Blob(
          [bytes], {
          type: mime.lookup(File.name) as string
        });
        /*var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = obj.Filename;
        link.click();*/

        var arrayName = fileName.split(".")

        let fileList = [{
          uri: window.URL.createObjectURL(blob),
          fileType: arrayName[arrayName.length - 1].toLowerCase(),
          fileName: fileName
        }] as any;
        if (arrayName.length !== 0) {
          if (arrayName[arrayName.length - 1] === 'xlsx' || arrayName[arrayName.length - 1] === 'xls' ||
            arrayName[arrayName.length - 1] === 'docx' || arrayName[arrayName.length - 1] === 'doc' ||
            arrayName[arrayName.length - 1] === 'docm') {
            setShowOpenAlert(true);
            setCurrentBlob(blob);
            setCurrentFileName(fileName);
            setCurrentExtension(arrayName[arrayName.length - 1]);
          } else {
            setCurrentFileName(File.name);
            setCurrentFileURLList(fileList);
            setOpenAttachment(true);
          }
        }
      });
  }

  const yesDowloadFile = () => {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(currentBlob);
    link.download = currentFileName;
    link.click();
  }

  const disabledDate = (current: any) => {
    let today = moment(new Date());
    return current > today;
  };

  const auxInvoiceTypePayable = <div className="row middle-xs start-xs">
    {/* BENEFICIARY NAME */}
    <div className="col-md-12 col-xs-12 row middle-xs start-xs">
      <div className="row">
        <div className="col-md-2 col-xs-12 row">
          <div className="row">
            <span>{getResource('Label.BeneficiaryName')}</span>
          </div>
        </div>
        <div className="col-md-10 col-xs-12">
          <div className="row">
            <Dropdown
              className={formatValidInputClass(validations.beneficiaryId)}
              name="f-mbi-invoceCCY"
              title={getResource('SelectBeneficiary')}
              list={props.beneficiaries}
              select={{ value: beneficiaryId }}
              onChange={handleGetBeneficiary}
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  const auxInvoiceTypeReceivable = <div className="row middle-xs start-xs">
    <div className="col-md-12 col-xs-12 row middle-xs start-xs">
      <div className="row">
        {/* CUSTOMER ID */}
        <div className="col-md-2 col-xs-12 row">
          <div className="row">
            <span>{getResource('Label.InvoiceSenderID')}</span>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          <div className="row">
            <input type="text" name="f-mbi-invocesenderid" id="f-mbi-invocesenderid"
              maxLength={50} className={formatValidInputClass(validations.senderID)}
              value={senderID} onChange={handleSenderID}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {/* CUSTOMER NAME */}
        <div className="col-md-2 col-xs-12 row">
          <div className="row">
            <span>{getResource('Label.InvoiceSenderName')}</span>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          <div className="row">
            <input type="text" name="f-mbi-invocesender" id="f-mbi-invocesender"
              maxLength={150} className={formatValidInputClass(validations.senderName)}
              value={senderName} onChange={handleSenderName}
            />
          </div>
        </div>
        {/* CUSTOMER EMAIL */}
        <div className="col-md-2 col-xs-12 row">
          <div className="row">
            <span>{getResource('Label.InvoiceSenderEmail')}</span>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          <div className="row">
            <input type="text" name="f-mbi-invoceemail" id="f-mbi-invoceemail"
              maxLength={75}
              value={customerEmail} onChange={handleCustomerEmail}
            />
          </div>
        </div>
      </div>
    </div>
  </div>;

  return (
    <div>
      <Modal
        title={<h3 className="component-title">{props.InvoiceType + " Invoice"}</h3>}
        centered
        visible={true}
        footer={null}
        //onCancel={props.changeState()}
        maskClosable={false}
        className='modal-message'
        zIndex={1000}
        closable={false}
      >
        <div>
          <div>
            {props.InvoiceType === 'Receivable' && auxInvoiceTypeReceivable}
            {props.InvoiceType !== 'Receivable' && auxInvoiceTypePayable}

            <div className="row middle-xs start-xs">
              <div className="col-md-12 col-xs-12 row middle-xs start-xs">
                <div className="row">
                  {/* INVOICE NUMBER */}
                  <div className="col-md-2 col-xs-12 row">
                    <div className="row">
                      <span>{getResource('Label.InvoiceNumber')}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <input type="text" name="f-mbi-invoceNumber" id="f-mbi-invoceNumber"
                        maxLength={25} className={formatValidInputClass(validations.invoiceNumber)}
                        value={invoiceNumber} onChange={handleInvoiceNumber}
                      />
                    </div>
                  </div>

                  {/* INVOICE DATE */}

                  <div className="col-md-2 col-xs-12 row">
                    <div className="row">
                      <span>{getResource('Label.InvoiceDate')}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <DatePicker
                        id="f-ph-view-range-show-from"
                        key="f-ph-view-range-show-from"
                        className={formatValidInputClass(validations.invoiceDate)}
                        onChange={handleUpdateInvoiceDate}
                        value={getDate(invoiceDate)}
                        valueValidation={invoiceDate}
                        disabledDate={disabledDate}
                      //disabledDays={[0, 6]}
                      //readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>


              <div className="col-md-12 col-xs-12 row middle-xs start-xs">
                <div className="row">
                  {/* TERMS */}
                  <div className="col-md-2 col-xs-12 row">
                    <div className="row">
                      <span>{getResource('Label.InvoiceTerms')}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <Input
                        type="number"
                        className={formatValidInputClass(validations.terms)}
                        value={terms}
                        onValueChange={handleInvoiceTerms}
                        decimalScale={0}
                      //onBlur={onBlurAmount}
                      />
                    </div>
                  </div>

                  <div className="col-md-2 col-xs-12 row">
                    <div className="row">
                      <span>{getResource('Label.Status')}</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <div className="row">
                      <Dropdown
                        name="statusReceivable"
                        title={getResource('SelectStatus')}
                        list={props.StatusList}
                        select={{ value: status }}
                        onChange={statusFilterChangeHandler}
                        className={formatValidInputClass(validations.status)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/* SECTION AMOUNT */}
              <div className="col-md-12 col-xs-12 row middle-xs center-xs">
                {/* DESCRIPTION */}
                <div className="col-md-6 col-xs-12 header-hidden">
                  <label className="input-required">{getResource('Description')}</label>
                </div>
                {/* AMOUNT */}
                <div className="col-md-3 col-xs-12 header-hidden">
                  <label className="input-required">{getResource('Amount')}</label>
                </div>
                {/* CURRENCY */}
                <div className="col-md-3 col-xs-12 header-hidden">
                  <label className="input-required">{getResource('Label.Currency')}</label>
                </div>
              </div>
            </div>

            <hr style={{ marginTop: '0px' }}></hr>
            <div className="row middle-xs start-xs">
              <div className="col-md-12 col-xs-12 row middle-xs start-xs">
                {/* DESCRIPTION */}
                <div className="col-md-6 col-xs-12">
                  <div className="row">
                    <span className="label-visible">{getResource('Description')}</span>
                    <input type="text" name="f-mbi-invoceDes" id="f-mbi-invoceDes"
                      maxLength={150} className={formatValidInputClass(validations.invoiceDescription)}
                      value={invoiceDescription} onChange={handleInvoiceDescription}
                    />
                  </div>
                </div>
                {/* AMOUNT */}
                <div className="col-md-3 col-xs-12">
                  <div className="row">
                    <span className="label-visible">{getResource('Amount')}</span>
                    <Input
                      type="number"
                      className={formatValidInputClass(validations.amount)}
                      value={amount}
                      onValueChange={handleInvoiceAmount}
                      decimalScale={scaleAmount}
                    //onBlur={onBlurAmount}
                      placeholder={'0.00000'}
                    />
                  </div>
                </div>
                {/* CURRENCY */}
                <div className="col-md-3 col-xs-12">
                  <div className="row">
                    <span className="label-visible">{getResource('Label.Currency')}</span>
                    <Dropdown
                      className={formatValidInputClass(validations.currencyId)}
                      name="f-mbi-invoceCCY"
                      title={getResource('SelectCurrency')}
                      list={availableCurrencies}
                      select={{ value: currencyId }}
                      onChange={handleGetCurrency}
                    />
                  </div>
                </div>

              </div>
            </div>

            <hr></hr>
            <div className="row middle-xs start-xs">
              <div className="col-md-12 col-xs-12 row middle-xs">
                {/* TAXES */}
                <div className="col-md-6 col-xs-12">
                  <div className="row end-xs">
                    <span>{getResource('Label.InvoiceTaxes')}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <Input
                    type="number"
                    value={taxes}
                    onValueChange={handleInvoiceTaxes}
                    decimalScale={scaleAmount}
                  //onBlur={onBlurAmount}
                    placeholder={'0.00000'}
                  />
                </div>
              </div>

              <div className="col-md-12 col-xs-12 row middle-xs">

                {/* TAXES */}
                <div className="col-md-6 col-xs-12">
                  <div className="row end-xs">
                    <span>{getResource('Label.InvoiceTotal')}</span>
                  </div>
                </div>
                <div className="col-md-3 col-xs-12">
                  <Input
                    type="number"
                    value={total}
                    //onChange={handleInvoiceTaxes}
                    //onBlur={onBlurAmount}
                    disabled={true}
                    decimalScale={scaleAmount}
                    placeholder={'0.00000'}
                  />
                </div>
              </div>
            </div>

            <hr></hr>

            <div className="row middle-xs start-xs">
              <div className="col-md-12 col-xs-12 row middle-xs">
                {/* INVOICE LINK */}
                <div className="col-md-2 col-xs-12">
                  <div className="row">
                    <span>{getResource('Label.InvoiceLink')}</span>
                  </div>
                </div>
                <div className="col-md-10 col-xs-12">
                  <input type="text" name="f-mbi-invocelink" id="f-mbi-invocelink"
                    maxLength={150}
                    value={invoiceLink} onChange={handleInvoiceLink}
                  />
                </div>
              </div>

              <div className="col-md-12 col-xs-12 row middle-xs">
                {/* INVOICE ATTACHMENT */}
                <div className="col-md-2 col-xs-12">
                  <div className="row">
                    <span>{getResource('Label.InvoiceAttachment')}</span>
                  </div>
                </div>
                <div className="col-md-10 col-xs-12">
                  <PaperClipOutlined onClick={onAttachmentsClick} />
                  <a onClick={onViewAttachment}><span className="fnt-size-08">{fileName}</span>
                  </a>
                </div>
              </div>
            </div>


            <div className="row-actions">
                  <Button id="exit-button" type="secondary" onClick={onClickExit}>
                    {getResource('Btn_Exit')}
              </Button>
                  <Button id="exit-button" type="primary" onClick={handleSaveClick}>
                    {getResource('Button.Save')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationModal visible={showConfirmation} cancel={closeAlert} confirm={yesAlert} title={getResource('AreYouSureYouWantToSave')} />
      <AttachmentsForm
        key={'orders-disbursement-attachment-form'}
        id={'orders-disbursement-attachment-form'}
        show={{ id: '', show: showUpload }}
        onClose={onCloseAttachmentsForm}
        useLinkID={false}
        onUploadWithoutLink={onUploadFile}
        showList={false}
      />
      <Modal
        centered
        visible={openAttachment}
        footer={null}
        onCancel={onCloseViewAttachment}
        maskClosable={false}
        className='modal-message'
        zIndex={1010}
        closable={true}
      >
        <div>
          <DocViewer
            pluginRenderers={DocViewerRenderers}
            documents={currentFileURLList}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false
              }
            }}
          />
        </div>
      </Modal>
      <ConfirmationModal visible={showOpenAlert} cancel={() => setShowOpenAlert(false)} confirm={yesDowloadFile} title={getResource('NoPreview')} />

    </div>
  );
}
export default InvoiceAddEditFormDialog;