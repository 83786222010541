import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Modal, Table, Tooltip, Typography, Upload, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { completeEntityTask, getListOfCustomerExpiredTasks } from '../../api/NetworkingTasks';
import useWindowSize from '../../api/hooks/useWindowResize';
import { getResource } from '../../helpers/ResourcesHelper';
import AttachmentsForm from '../attachments/AttachmentsForm';
import Button from '../inputs/Button';
import DownloadTableRows from '../shared/DownloadTableRows';

const { Dragger } = Upload;

class Task {
  DeadLineDate: string;
  EntityTaskId: string;
  ExpiryDate  : string | null;
  Title       : string;
  UpdateToken : string;

  constructor(deadline:string, entityTaskId:string, expiryDate:string|null, title:string, updateToken:string){
    this.DeadLineDate = deadline;
    this.EntityTaskId = entityTaskId;
    this.ExpiryDate   = expiryDate;
    this.Title        = title;
    this.UpdateToken  = updateToken;
  }
}

const RequiredDocuments = () => {
  const { showModal } = useContext(AppContext);
  const [updating, setUpdating] = useState<boolean>(true);

  const [requiredFileList, setRequiredFileList] = useState([]);
  const [taskSelected, setTaskSelected] = useState<Task>(new Task('','',null,'',''));

  const [showModalUpload, setShowModalUpload] = useState<boolean>(false);

  const [fileUploaded, setFileUploaded] = useState<any>();
  const size = useWindowSize();

  useEffect(() => {
    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    getListOfCustomerExpiredTasks(userSession.CustomerID).then(
      (jsonResponse) =>
      {
        if(jsonResponse !== undefined && jsonResponse.tasks !== undefined)
        {
          let requiredDocs = jsonResponse.tasks;
          /*
            { 
              DeadLineDate: "2020-12-30T10:26:29.687"
              EntityTaskId: "A1BF5508-6662-4C01-9E32-B269A6AEFE07"
              ExpiryDate: null
              Title: "Address verification"
              UpdateToken: "637362807896866667"
            }
          */
          setRequiredFileList(requiredDocs);
        }
      }
    );
  }, [updating]);

  const columns: ColumnsType<Task> = [
    {
      title: getResource('Table_TH_Title'),
      dataIndex : 'Title',
      key       : 'task_title',
      align     : 'left',
    },
    {
      title: getResource('Table_TH_ExpiryDate'),
      dataIndex : 'ExpiryDate',
      key       : 'task_expirydate',
      align     : 'center',
      width: '35%',
      render    : (value: any, obj: Task, index: any) =>(
        <span>{obj.ExpiryDate === null ? '' : obj.ExpiryDate}</span>
      ),
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_Actions') : "",
      key    : 'action',
      align  : 'center',
      width: '15%',
      render : (value: any, obj: Task, index: any) => (
        <Tooltip title={getResource('Upload')}>
          <Typography.Link className="column-actions"
            onClick={()=>{
              setTaskSelected(obj);
              setShowModalUpload(true);
            }}
          >
            <UploadOutlined />
          </Typography.Link>
        </Tooltip>
      ),
    },
  ];

  const props = {
    name: 'file',
    multiple: true,
    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info:any) {

      if(info.fileList[0] !== undefined)
      {
        setFileUploaded(info.fileList[0]);
      }else{
        setFileUploaded(undefined);
      }

      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} ` + getResource('FileUploaded'));
      } else if (status === 'error') {
        message.error(`${info.file.name} ` + getResource('FileUploadedError'));
      }
    },
    onDrop(e:any) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onCloseAttachmentsForm = () => {
    setShowModalUpload(false);
  }

  const onAfterUploadAttachment = async () => {

    let user = localStorage.getItem('UserID');
    let model = {
      EntityTaskId: taskSelected.EntityTaskId,
      Completed: true,
      Status: 'Completed',
      UpdateToken: taskSelected.UpdateToken,
      CompletedBy: user,
      UpdatedBy: user
    }
    let completeResponse = await completeEntityTask(model);
    if (completeResponse.httpStatusCode === 200) {
      setShowModalUpload(false);
      showModal(getResource('Js_MessageError_TitleSuccess'), getResource('FileUploaded'));
      setUpdating(!updating);
    }
    else {
      setShowModalUpload(false);
      showModal(getResource('Js_MessageError_TitleError'), getResource('SomethingWentWrongTryAgain'));
    }

  }

  return (
    <div >
      <div className="title-text"><h2>{getResource('Tab_RequiredDocumments')}</h2></div>

      <div className="row center">
        <div className="col-md-2 col-xs-1"></div>
        <div className="col-md-8 col-xs-10">
          <div className="row-actions-1" style={{ height: '20px' }}>
            <DownloadTableRows data={requiredFileList} fileName={'requiredFiles'} />
          </div>
          <Table
        key        = {'requiredFileList-table'}
        columns    = {columns} 
        dataSource={requiredFileList} 
      />
        </div>
        <div className="col-md-2 col-xs-1"></div>
      </div>



      <AttachmentsForm
        key={'required-docs-attachment-form'}
        id={'required-docs-attachment-form'}
        show={{ id: '', show: showModalUpload }}
        onClose={onCloseAttachmentsForm}
        useLinkID={true}
        linkID={taskSelected.EntityTaskId}
        linkType='Task'
        showList={false}
        onAfterUpload={onAfterUploadAttachment}
      />


      <Modal
        title    = {
          <h3 className="component-title">{taskSelected.Title}</h3>}
        centered
        visible={false}
        footer   = {null}
        onCancel = {()=>{setShowModalUpload(false);}}
        className='modal-message'
        zIndex   = {1021}
      >
        <Dragger
          {...props}
          //name      = {taskSelected.Title + 'file'}
          //multiple  = {false}
          //action    = 'https://www.mocky.io/v2/5cc8019d300000980a055e76'
          /*onChange  = {
            (info: any) => {
              if(info.fileList[0] !== undefined)
              {
                setFileUploaded(info.fileList[0]);
              }else{
                setFileUploaded(undefined);
              }
              /*const { status } = info.file;
              if (status !== 'uploading') {
                console.log(info.file, info.fileList);
              }
              if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
              } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
              }
            }
          }
          onDrop = {
            (e:any) => {
              console.log('Dropped files -> ', e);
            }
          }*/
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">{getResource('ClickOrDrag')}</p>
        </Dragger>
        <div className="row center">
          <div className="col-md-3 col-xs-6 center">
            <Button 
              type    = "secondary" 
              onClick = {()=>{setShowModalUpload(false);}}
            >{getResource('Button.Cancel')}</Button>
          </div>
          <div className="col-md-3 col-xs-6 center">
            <Button 
              type    = {fileUploaded !== undefined ? "primary" : 'disable'}
            >{getResource('Upload')}</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RequiredDocuments;