import { DollarCircleOutlined, FilterFilled, FilterOutlined } from "@ant-design/icons";
import { AutoComplete, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { getOnlineBeneficiaries } from '../../../api/NetworkingBeneficiaries';
import { getCountryByName } from "../../../api/NetworkingCountries";
import { getResource } from "../../../helpers/ResourcesHelper";
import Button from '../../inputs/Button';
import { Beneficiary } from '../../models/Beneficiary';
import DownloadTableRows from "../../shared/DownloadTableRows";
import ModalFilter from "../../shared/ModalFilter";

const BeneficiaryPaymentsTable = (props: any) => {

  const [openFilter, setOpenFilter] = useState<boolean>(false);

  const [beneficiaries, setBeneficiaries] = useState([]);
  const userSession = JSON.parse(localStorage.getItem('UserSession')!);
  const [updating, setUpdating] = useState(false);
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [country, setCountry] = useState('all');
  const [countries, setCountries] = useState([
    { value: 'all', label: getResource('All') }
  ]);

  const selectRow = (record: any) => {
    props.selectRow(record.BeneficiaryID);
  };

  const columns: ColumnsType<Beneficiary> = [
    {
      title: getResource('Js_TH_Beneficiary'),
      dataIndex: 'BeneficiaryName',
      key: 'BeneficiaryName',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.BeneficiaryName.localeCompare(b.BeneficiaryName)


    },
    {
      title: getResource('Js_TH_Country'),
      dataIndex: 'CountryName',
      key: 'CountryName',
      width: '25%',
      sorter: (a, b) => a.CountryName.localeCompare(b.CountryName)
    },
    {
      title: getResource('Js_TH_Status'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      width: '15%',
      sorter: (a, b) => a.Status.localeCompare(b.Status)
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: '15%',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('Js_Link_ViewPayments')}>
            <Typography.Link
              className="column-actions"
              onClick={() => {
                selectRow(obj);
              }}
            >
              <DollarCircleOutlined />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];


  const loadBeneficiaries = () => {
    if (userSession != null && userSession.CustomerID > 0) {
      getOnlineBeneficiaries(userSession.CustomerID).then((response) => {
        loadCountries(response.Beneficiaries);
        setBeneficiaries(response.Beneficiaries);
        applyFilters(response.Beneficiaries);
      });
    }
  }

  useEffect(() => {
    loadBeneficiaries();
  }, [updating]);

  const onChangeBeneficiaryName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBeneficiaryName(event.target.value);
  };

  const dropdownCountryChangeHandler = (item: any, option: any) => {
    if (option !== null && !option.label.includes('Select')) {
      setCountry(option.label);
    }
  };
  const onSearch = (value: any) => {
    setCountry(value);
    let newValue = value.split(' -');
    getCountryByName(newValue[0]).then(
      (json) => {
        if (json.countries !== null) {
          const formattedCountries = json.countries.map((c: any) => ({ ...c, label: c.CountryIdName, value: c.CountryId }));          
          setCountries(formattedCountries);
        }
      }
    )
  }
  const loadCountries = (beneficiaries: []) => {
    var countries = [{ value: 'all', label: getResource('All') }];
    beneficiaries.map((item: typeof Beneficiary) => {
      var findItem = countries.find(x => x.value === item.CountryName);
      if (!findItem)
        countries.push({ value: item.CountryName, label: item.CountryName });
    });

    setCountries(countries);
  }

  const applyFilters = (beneficiaries: any) => {
    var beneficiariesAux = beneficiaries;
    if (country !== 'all' && country !== '') {
      beneficiariesAux = beneficiariesAux.filter((item: typeof Beneficiary) => item.CountryName === country);
    }
    if (beneficiaryName !== '') {
      beneficiariesAux = beneficiariesAux.filter((item: typeof Beneficiary) => item.BeneficiaryName.indexOf(beneficiaryName) > -1);
    }

    setBeneficiaries(beneficiariesAux);
  }

  const onResetFilters = () => {
    setBeneficiaryName('');
    setCountry('all');
    setUpdating(!updating);
    setOpenFilter(false);
  };

  const closeFilter = () => {
    setOpenFilter(false);
  }

  const filtered = beneficiaryName || country

  const filterOptions = <>
    {!beneficiaryName && !country && getResource('Title.FilterOptions')}
    {beneficiaryName && <span className="input-label">{getResource('Js_TH_Beneficiary')}: {beneficiaryName}</span>}
    {country && <span className="input-label">{getResource('Js_TH_Country')}: {country}</span>}
  </>

  const filter = <>
    <div>
      <div className="row">
        <div className="row">
          <span className="input-label">{getResource('Js_TH_Beneficiary')}: </span>
        </div>
        <div className="row">
          <input id="beneficiaryName" value={beneficiaryName} onChange={onChangeBeneficiaryName} type="text" />
        </div>
      </div>
      <div className="row">
        <div className="row">
          <span className="input-label">{getResource('Js_TH_Country')}:</span>
        </div>
        <div className="row">
          <AutoComplete
            allowClear
            options={countries}
            placeholder={getResource('SelectCountry')}
            notFoundContent={getResource('PlaceHolder.NoResults')}
            value={country}
            onSelect={dropdownCountryChangeHandler}
            onSearch={onSearch}
          />
        </div>
      </div>
      <div className="row-actions">
        <Button type="primary" onClick={() => { setUpdating(!updating); closeFilter(); }}>
          {getResource('ApplyFilter')}
        </Button>
        <Button type="secondary" onClick={() => { onResetFilters(); closeFilter(); }}>
          {getResource('Reset')}
        </Button>
      </div>
    </div>
  </>

  return (
    <React.Fragment>
      <div className="row-actions-1">

        <DownloadTableRows data={beneficiaries} fileName={'beneficiaries'} />
        <Tooltip title={filterOptions}>
          <Typography.Link onClick={() => setOpenFilter(true)} style={{ marginRight: '10px' }}>
            {filtered && <FilterFilled />}
            {!filtered && <FilterOutlined />}
          </Typography.Link>
        </Tooltip>
      </div>
      <Table
        className=""
        key={'transactions-table'}
        columns={columns}
        dataSource={beneficiaries}
      />
      <ModalFilter title={getResource('Title.FilterOptions')} open={openFilter} close={closeFilter} panel={filter} />
    </React.Fragment>
  );
}

export default BeneficiaryPaymentsTable;