import TextArea from 'antd/lib/input/TextArea';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { Message, getReplyHistoryByMessageID, replyMessage } from '../../../api/NetworkingMessages';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';

interface MessageReplyProps {
  subject: string;
  message: Message | null;
  handleBackLink: (showSuccessAlert?: boolean) => void;
}

const MessageReply: FunctionComponent<MessageReplyProps> = ({ subject, message, handleBackLink: returnToMessageContent }) => {
  const { showModal } = useContext(AppContext);
  const [content, setContent] = useState('');
  const [replyContent, setReplyContent] = useState('');

  useEffect(() => {
    if (message && message.Content) {

      getReplyHistory();
    }
  }, [message])

  const getReplyHistory = async () => {

    const json = await getReplyHistoryByMessageID(message!.MessageId);
    let reply = '';
    if (message && message.Content) {
      reply = `${getResource('From')}: ${message.Sender} \n${getResource('Table_TH_Date')}: ${message.SentOnDate} \n${getResource('Table_TH_Subject')}: ${message.Subject}\n\n${message.Content}\n\n`;

    }
    if (json.messages && json.messages.length > 0) {
      json.messages.forEach((m: any) => {
        reply = reply + `${getResource('From')}: ${m.Sender} \n${getResource('Table_TH_Date')}: ${m.SentOnDate} \n${getResource('Table_TH_Subject')}: ${m.Subject}\n\n${m.Content}\n\n`;

      });
      setReplyContent(reply);
    }
  }

  const handleSendReply = async () => {
    const model = {
      content: content,
      senderId: message!.ReceiverId,
      sentOn: new Date().toISOString(),
      subject: subject,
      receiverId: message!.SenderId,
      replyMessageId: message!.MessageId
    };
    const json = await replyMessage(model);
    if (json != null) {
      if (json.httpStatusCode !== 200) {
        showModal(getResource('Js_MessageError_TitleError'), json.httpErrorMessage);
      } else {
        // showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Message_Successfully_Sent'));
        returnToMessageContent(true)
      }
    }
  };

  const onChangeContent = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setContent(event.target.value);
  };

  const handleBackLink = () => {
    returnToMessageContent();
  };

  return (
    <div className="screen-container">
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Message_To')}</span>
          </div>
          <div className="row">
            <input id="reply-to" disabled={true} value={message?.Sender} type="text" />
          </div>
        </div>
      </div>
      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Table_TH_Subject')}</span>
          </div>
          <div className="row">
            <input id="reply-subject" disabled={true} value={subject} type="text" />
          </div>
        </div>
      </div>

      <div className="row center-xs">
        <div className="col-md-5 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Content')}</span>
          </div>
          <div className="row" style={{ boxShadow: '5px 5px 15px #8f999f', borderRadius: '10px' }}>
            <TextArea id="reply-content" bordered={false} autoFocus={true} className='text-area-reply-message' value={content} onChange={onChangeContent} />
            {replyContent && <TextArea id="reply-content" bordered={false} className='text-area-reply-message text-area-border-top' readOnly={true} value={replyContent} onChange={onChangeContent} />}
          </div>
        </div>
      </div>
      <div className="row-actions">
            <Button key="go-back-address" type="secondary" onClick={handleBackLink}>
              {getResource('Button.Cancel')}
        </Button>
            <Button key="save-address" onClick={handleSendReply}>
              {getResource('Button.Send')}
        </Button>
      </div>
    </div>
  );
};

export default MessageReply;
