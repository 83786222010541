import { Breadcrumb, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { approveBeneficiary, getListOfPendingApprovalBeneficiaries } from '../../../api/NetworkingBeneficiaries';
import Button from '../../../components/inputs/Button';
import { getResource } from '../../../helpers/ResourcesHelper';
import BeneficiaryWizard from '../../beneficiaries/BeneficiaryWizard';
import { Beneficiary } from '../../models/Beneficiary';
import ApprovalsNotification from '../ApprovalsNotification';
import PendingBeneficiariesTable from './PendingBeneficiariesTable';


const PendingBeneficiariesList = (props: any) => {
  const userSession = JSON.parse(localStorage.getItem('UserSession')!);

  const { showModal } = useContext(AppContext);
  const [benes, setBenes] = useState<Beneficiary[]>([]);
  const [updating] = useState<boolean>(false);
  const [showApprovalAlert, setShowApprovalAlert] = useState(false);
  const [showRejectAlert, setShowRejectAlert] = useState(false);
  const [showApprovalsNotification, setShowApprovalsNotification] = useState(false);

  const [selectedBeneID, setSelectedBeneID] = useState(0);
  const [beneObject, setBeneObject] = useState({} as any);
  const [screen, setScreen] = useState('table');

  const [contactsAbleToApprove, setContactsAbleToApprove] = useState([]);
  const [approvalEntityName, setApprovalEntityName] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('')
  const [rejectMessage, setRejectMessage] = useState('')
  const [showTrackForm, setShowTrackForm] = useState<boolean>(false);

  useEffect(() => {
    loadPendingForApprovalBenes();
  }, [updating]);

  const loadPendingForApprovalBenes = () => {

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);
    let params = {
      CurrencyID: '',
      CustomerID: userSession.CustomerID,
      UserID: userSession.PersonID,
      Page: 0,
      Items: 1000
    }
    getListOfPendingApprovalBeneficiaries(params).then((response) => {
      console.log(response);
      if (response != null && response.beneficiaries != null) {
        response.beneficiaries.forEach((bene: any) => {
          bene.key = bene["Beneficiary ID"];
          bene.RegisterByInfo = bene.RegisterBy + ' on ' + bene.UpdatedOn + ' at ' + bene.UpdatedOnTime;
        });
        setBenes(response.beneficiaries);
      } else {
        setBenes([]);
      }
    });
  }

  const onCancelClick = () => {
    setScreen('table');
    setBeneObject({});
    setSelectedBeneID(0);
  };

  const selectRow = (beneID: number) => {
    setSelectedBeneID(beneID);
  };

  const stringEmptyOrNull = (str: string) => { return (str === undefined || str === null || str === '') }

  //actions

  //approvalIndicator [0 for rejection, 1 for approval]
  const onApproveBeneficiary = (approvalIndicator: number, approvalLeft: number) => {

    let userSession = JSON.parse(localStorage.getItem('UserSession')!);

    let approvalRequest = {
      ApprovalIndicator: approvalIndicator,
      CustomerID: userSession.CustomerID,
      ContactID: userSession.ContactID,
      BeneficiaryID: beneObject["Beneficiary ID"],
      PersonID: userSession.PersonID
    }

    setShowApprovalAlert(false);
    setShowRejectAlert(false);

    approveBeneficiary(approvalRequest).then((response) => {

      console.log(response);
      if (response !== undefined && response !== null) {
        if (response.httpStatusCode === 200) {
          if (response.ErrorMessage !== '') {
            if (approvalIndicator === 0) {
              showModal(getResource('Js_Message_ConfirmRejectTitle'), "Success");
            }
            else if (approvalIndicator === 1) {
              let message = getResource('Js_Message_AprovedBeneficiary')
              if (response.externalInterfaceMessage !== undefined && response.externalInterfaceMessage !== null
                && response.externalInterfaceMessage !== '') {
                let interfaceMessage = response.externalInterfaceMessage.split("\n").map(function (item: any, idx: any) {
                  return (
                    <span key={idx}>
                      {item}
                      <br />
                    </span>
                  )
                })
                message = <span> {message} <br /> {interfaceMessage}</span>
              }

              if (approvalLeft === 1) {
                //success, no more approval required
                showModal(getResource('Js_Message_AprovedBeneficiaryTitle'), message);
              } else {
                //success, more approvals required
                let contactsAbleToApprove = stringEmptyOrNull(response.contactsAbleToApprove) ? [] : response.contactsAbleToApprove
                console.log(contactsAbleToApprove)
                if (contactsAbleToApprove.length === 0) {
                  //error, need more user with approval right
                  showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_MissingApproval'));
                }
                else {
                  contactsAbleToApprove = contactsAbleToApprove.map((c: any) => ({ ...c, label: c.Name, value: c.Email }));
                  setApprovalEntityName(beneObject.BeneficiaryName)
                  setContactsAbleToApprove(contactsAbleToApprove)
                  setShowApprovalsNotification(true)
                }
              }
            }
            loadPendingForApprovalBenes();
          } else {
            showModal(getResource('Js_MessageError_TitleSubmit'), response.ErrorMessage);
          }
        } else if (response.httpStatusCode >= 401 && response.httpStatusCode < 500) {
          showModal(getResource('Js_MessageError_TitleSubmit'), response.Message);
        } else if (response.httpStatusCode >= 500) {
          showModal(getResource('Js_MessageError_TitleSubmit'), 'Internal Server Error: ' + response.Message);
        } else {
          showModal(getResource('Js_MessageError_TitleSubmit'), 'Something went wrong');
        }
      } else {
        showModal(getResource('Js_MessageError_TitleSubmit'), 'Something went wrong');
      }
    }
    ).then(x => {
      setBeneObject({})
      setSelectedBeneID(0);
    }
    )
  }

  const onShowApprovalAlert = (obj: any) => {
    setBeneObject(obj)
    processBeneInfo(obj)
    setShowApprovalAlert(true)
  }

  const onShowRejectlAlert = (obj: any) => {
    setBeneObject(obj)
    processBeneInfo(obj)
    setSelectedBeneID(obj['Beneficiary ID']);
    setShowRejectAlert(true)
  }

  const onShowBeneinfo = (obj: any) => {    
    setBeneObject(obj)
    processBeneInfo(obj)
    setScreen('info');
    setSelectedBeneID(obj['Beneficiary ID']);
    setShowTrackForm(true)
  };

  const closeApprovalAlert = () => {
    setShowApprovalAlert(false);
  }

  const closeRejectAlert = () => {
    setShowRejectAlert(false);
  }

  const processBeneInfo = (bene: any) => {

    let confirmationMessage = getResource('Js_Message_ConfirmApproveBeneficiary')
    confirmationMessage = confirmationMessage.replace('#beneficiaryName', bene.BeneficiaryName)

    let rejectMessage = getResource('Js_Message_ConfirmRejectBeneficiary')
    rejectMessage = rejectMessage.replace('#beneficiaryName', bene.BeneficiaryName)

    setConfirmationMessage(confirmationMessage)
    setRejectMessage(rejectMessage)
  }

  const backToApprovals = () => {
    setShowApprovalsNotification(false)
    setApprovalEntityName('')
    setScreen('table');
  }

  const onCloseDetailsFromApproval = (value: boolean) => {
    setShowApprovalsNotification(false)
    setApprovalEntityName('')
    setScreen('table');
  }

  const onApproveFromBeneficiaryScreen = () => {
    setScreen('table');
    onApproveBeneficiary(1, parseInt(beneObject.ApprovalsLeft || '1', 10))
  }

  return (

    <React.Fragment>
      {showTrackForm && <div className="breadcrumb-container">
        <Breadcrumb separator="">
          {screen === 'info' && selectedBeneID !== 0 && <Breadcrumb.Item>{getResource('Js_TH_Beneficiary')} {selectedBeneID}</Breadcrumb.Item>}
          {screen === 'info' && selectedBeneID !== 0 && <Breadcrumb.Separator children=">" />}
        </Breadcrumb>
      </div>}
      {screen === 'table' && <PendingBeneficiariesTable
        selectRow={selectRow}
        benes={benes}
        onShowApprovalAlert={onShowApprovalAlert}
        onShowRejectlAlert={onShowRejectlAlert}
        onShowBeneinfo={onShowBeneinfo}
      />}
      {screen === 'info' && beneObject["Beneficiary ID"] !== 0 && <BeneficiaryWizard beneficiaryID={beneObject["Beneficiary ID"]}
        customerID={userSession.CustomerID}
        onCancelClick={backToApprovals}
        readOnly={true}
        viewAndApprove={true}
        approveFromBeneficiaryScreen={onApproveFromBeneficiaryScreen}
      />
      }

      <Modal
        title={<h3 className="component-title">{getResource('Js_Message_ConfirmApproveTitle')}</h3>}
        centered open={showApprovalAlert} footer={null} onCancel={closeApprovalAlert} maskClosable={false} zIndex={1010}>
        <div>
          <div>
            <h4>{confirmationMessage}</h4>
          </div>
          <div className="row-actions-w">
            <Button type="primary" onClick={() => onApproveBeneficiary(1, parseInt(beneObject.ApprovalsLeft || '1', 10))}>
              {getResource('Button.Ok')}
            </Button>
            <Button type="secondary" onClick={() => closeApprovalAlert()}>
              {getResource('Button.Cancel')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={<h3 className="component-title">{getResource('Js_Message_ConfirmRejectTitle')}</h3>}
        centered open={showRejectAlert} footer={null} onCancel={closeRejectAlert} maskClosable={false} zIndex={1010}>
        <div>
          <div>
            <h4>{rejectMessage}</h4>
          </div>
          <div className="row-actions-w">
            <Button type="primary" onClick={() => onApproveBeneficiary(0, parseInt(beneObject.ApprovalsLeft || '1', 10))}>
              {getResource('Button.Ok')}
            </Button>
            <Button type="secondary" onClick={() => closeRejectAlert()}>
              {getResource('Button.Cancel')}
            </Button>
          </div>
        </div>
      </Modal>
      <ApprovalsNotification
        title={getResource('BeneficiaryApproved')}
        entityName={approvalEntityName}
        entityType="Beneficiary"
        showApprovalsNotification={showApprovalsNotification}
        contactsAbleToApprove={contactsAbleToApprove}
        onClose={backToApprovals}
        onCloseDetails={onCloseDetailsFromApproval}
      />
    </React.Fragment>
  );
};

export default PendingBeneficiariesList;


