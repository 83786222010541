import { FunctionComponent, useEffect, useState } from 'react';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';

let beneficiaries = getResource('Tab.Option.Beneficiary')

interface CNavBarProps {
  onChangeScreen: Function;
  currentScreen: string;
  onlineRights: string;
}

const BNavBar: FunctionComponent<CNavBarProps> = (props) => {
  const [selectedScreen, setSelectedScreen] = useState('payment');

  useEffect(() => {
    setSelectedScreen(props.currentScreen);
  })

  const showMenuItem = (onlineRightsStr: string, onlineRight: string) => {
    let rightsOnline;
    if (onlineRightsStr != null && onlineRightsStr != '') {
      rightsOnline = onlineRightsStr;
    }
    else {
      rightsOnline = localStorage.getItem('OnlineRights');
    }

    if (rightsOnline != null && rightsOnline != '') {
      let onlineRights = JSON.parse(rightsOnline);
      var right = onlineRights.find((element: any) => element.Right == onlineRight);
      if (right != undefined) {
        return true
      }
    }
    return false
  }

  let OL_SpotAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_SpotAccess);
  let OL_ForwardAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_ForwardAccess);
  let OL_RegularPaymentAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_RegularPaymentAccess);
  let OL_OrderAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_OrderAccess);
  let OL_BeneficiaryAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_BeneficiaryAccess);
  let OL_TransactionsHistoryAccess = showMenuItem(props.onlineRights, OnlineRightsNames.OL_TransactionsHistoryAccess);
  let OL_MyAccountInformation_Balances = showMenuItem(props.onlineRights, OnlineRightsNames.OL_MyAccountInformation_Balances);

  const onNavBarOptionClick = (screen: 'dashboard' | 'rates' | 'payment' | 'balance' | 'transaction' | 'beneficiaries' | 'quickbooks' | 'approvals' | 'invoicing') => {
    setSelectedScreen(screen);
    props.onChangeScreen(screen);
  }

  return (

    <div className="mobile-bottom-nav">
      {(OL_SpotAccess || OL_ForwardAccess || OL_RegularPaymentAccess || OL_OrderAccess) &&
        <div className={(selectedScreen === 'payment') ? 'mobile-bottom-nav__item-selected' : 'mobile-bottom-nav__item'}>
          <div className="mobile-bottom-nav__item-content" onClick={() => onNavBarOptionClick('payment')}>
            <div>
              <span className="material-icons-round navbar-icon">payment</span>
            </div>
            <div>
              {getResource('Title.Payments')}
            </div>
          </div>
        </div>}

      {(OL_TransactionsHistoryAccess) &&
        <div className={(selectedScreen === 'transaction') ? 'mobile-bottom-nav__item-selected' : 'mobile-bottom-nav__item'}>
          <div className="mobile-bottom-nav__item-content" onClick={() => onNavBarOptionClick('transaction')}>
            <div>
              <span className="material-icons-round navbar-icon">receipt_long</span>
            </div>
            <div>
              {getResource('Title.Transactions')}
            </div>
          </div>
        </div>}

      {OL_MyAccountInformation_Balances &&
        <div className={(selectedScreen === 'balance') ? 'mobile-bottom-nav__item-selected' : 'mobile-bottom-nav__item'}>
          <div className="mobile-bottom-nav__item-content" onClick={() => onNavBarOptionClick('balance')}>
            <div>
              <span className="material-icons-round navbar-icon">view_list</span>
            </div>
            <div>
              {getResource('Title.Balances')}

            </div>
          </div>
        </div>}

      {OL_BeneficiaryAccess &&
        <div className={(selectedScreen === 'beneficiaries') ? 'mobile-bottom-nav__item-selected' : 'mobile-bottom-nav__item'}>
          <div className="mobile-bottom-nav__item-content" onClick={() => onNavBarOptionClick('beneficiaries')}>
            <div>
              <span className="material-icons-round navbar-icon">people</span>
            </div>
            <div>
              <span>{beneficiaries}</span>
            </div>
          </div>
        </div>}
    </div>);
}

export default BNavBar;