import { EditOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { Collapse, Tooltip, Typography } from 'antd';

import moment, { Moment } from 'moment';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { getBeneficiariesByCustomerMin } from '../../api/NetworkingBeneficiaries';
import { getCurrenciesByCustomer } from '../../api/NetworkingCurrencies';
import { getInvoicesByDate } from '../../api/NetworkingInvoice';
import useLookUpTables, { LookUpTableDetail } from '../../api/hooks/useLookUpTables';
import useWindowSize from '../../api/hooks/useWindowResize';
import { formatDate, formatDateString, formatToCurrency } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import DatePicker from '../inputs/DatePicker';
import Dropdown from '../inputs/Dropdown';
import DownloadTableRows from '../shared/DownloadTableRows';
import ModalFilter from '../shared/ModalFilter';
import DataGrid from '../table/DataGrid';
import InvoiceAddEditFormDialog from './InvoiceAddEditFormDialog';
import InvoiceUpload from './InvoiceUpload';
const dateFormat = 'YYYY/MM/DD';

const { Panel } = Collapse;
interface InvoiceScreenProps {
}
export interface ResponseGetInvoicing {
    'InvoiceNumber': string;
    'InvoiceDate': string;
    'ExternalCustomerName': string;
    'InvoiceDescription': string;
    'TotalInvoice': string;
    'Email': string;
    Terms: number;
    BeneficiaryID: number;
    BeneficiaryName: string;
    Status: string;
    InvoiceIssuedID: number;
    'CustomerID': number;
    'Amount': number;
    'CurrencyID': string;
    'Taxes': number;
    'Total': number;
    'InvoiceLink': string;
    'UpdateToken': string;
    FileName: string;
    ExternalCustomerID: string;
}
const InvoiceScreen: FunctionComponent<InvoiceScreenProps> = (props) => {
    const { showLoadingModal, hideLoadingModal } = useContext(AppContext);
    const [showUploadScreen, setShowUploadScreen] = useState(false);
    const [invoiceType, setInvoiceType] = useState('Payable');
    const [showAddNew, setShowAddNew] = useState(false);
    const [currentTab, setCurrentTab] = useState('Payable');
    let date = new Date();
    date.setDate(date.getDate() - 30);
    const [fromDate, setFromDate] = useState(moment(date, dateFormat).format(dateFormat));
    const [toDate, setToDate] = useState(moment(new Date(), dateFormat).format(dateFormat));
    const [today, setToday] = useState(moment(new Date(), dateFormat).format(dateFormat));
    const [momentFromDate, setMomentFromDate] = useState<Moment>(moment());
    const [momentToDate, setMomentToDate] = useState<Moment>(moment());
    const [statusFilter, setStatusFilter] = useState('-1');

    const [validations, setValidations] = useState({
        fromDate: true,
        toDate: true
    });
    const [invoiceListSearch, setInvoiceListSearch] = useState([]);
    const [invoiceListSearchFilter, setInvoiceListSearchFilter] = useState([]);
    const [settlementCurrencies, setSettlementCurrencies] = useState([]);

    const [beneficiaries, setBeneficiaries] = useState([]);
    const lookUpTables = useLookUpTables('Account receivable status');
    const [statusList, setStatusList] = useState<any>([]);

    //-----------For Render------------
    const [classNamePreviousDealsP, setClassNamePreviousDealsP] = useState('');
    const [listHiddenR, setListHiddenR] = useState('');
    const [classNamePreviousDealsR, setClassNamePreviousDealsR] = useState('');
    const [listHiddenP, setListHiddenP] = useState('');
    const [listOfInvoiceExportPayable, setListOfInvoiceExportPayable] = useState([]);
    const [listOfInvoiceExportReceivable, setListOfInvoiceExportReceivable] = useState([]);
    const [openFilterPayment, setOpenFilterPayment] = useState<boolean>(false);
    const [openFilterReceive, setOpenFilterReceive] = useState<boolean>(false);
    const size = useWindowSize();
    const [currentInvoice, setCurrentInvoice] = useState({});

    useEffect(() => {

        if (lookUpTables.Accountreceivablestatus) {
            setStatusList(lookUpTables.Accountreceivablestatus);
        }
    }, [lookUpTables])

    useEffect(() => {
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        getCurrenciesByCustomer(userSession.CustomerID).then(
            (json) => {
                if (json !== undefined) {
                    let jsonCurrencies = json.currencies;

                    var settlementHelper = [] as any;
                    if (jsonCurrencies !== undefined) {
                        for (var i = 0; i < jsonCurrencies.length; i++) {
                            var currencyElement = {
                                value: jsonCurrencies[i].CurrencyID,
                                label: jsonCurrencies[i].CurrencyIDName,
                                numberOfDecimals: jsonCurrencies[i].NumberOfDecimals,
                                currencyType: jsonCurrencies[i].CurrencyType
                            };
                            switch (jsonCurrencies[i].Type) {
                                case 'Settlement':
                                    settlementHelper.push(currencyElement);
                                    break;
                                default:
                                    break;
                            }
                        }
                    }
                    setSettlementCurrencies(settlementHelper);
                }
            }
        );

        getBeneficiariesByCustomerMin(userSession.CustomerID, 'Authorized,Pending Activation').then(
            (response) => {
                if (typeof response !== undefined &&
                    response.httpStatusCode === 200 &&
                    response.beneficiaries !== undefined) {
                    var benes = response.beneficiaries;
                    var helper = [] as any;
                    for (var i = 0; i < benes.length; i++) {
                        benes[i].value = benes[i]["BeneficiaryID"];
                        benes[i].label = benes[i]["Name"];
                        helper.push(benes[i]);
                    }
                    setBeneficiaries(helper);
                }
            });
    }, []);

    const validateDates = (fromDate: any, toDate: any) => {
        var result = true;
        var tempValidations = validations;
        tempValidations = resetValidations(tempValidations);
        if (fromDate === undefined || fromDate === '' || fromDate === 'NaN/NaN/NaN') {
            tempValidations.fromDate = false;
            result = false;
        }

        if (toDate === undefined || toDate === '' || toDate === 'NaN/NaN/NaN') {
            tempValidations.toDate = false;
            result = false;
        }

        if (fromDate > toDate) {
            tempValidations.fromDate = false;
            tempValidations.toDate = false;
            result = false;
        }
        setValidations(tempValidations);
        return result;
    }

    const getInvoicesByDates = async (fromDate: any, toDate: any, customerID: any, invoiceType: any, status: any) => {
        if (validateDates(fromDate, toDate)) {
            showLoadingModal();
            const json = await getInvoicesByDate(fromDate, toDate, customerID, invoiceType, status);
            if (json !== undefined) {
                var invoices = json.invoices;
                setInvoiceListSearch(invoices);
                hideLoadingModal();
                isRender();
            }
            else {
                setInvoiceListSearch([]);
                hideLoadingModal()
            }
        }
    }

    useEffect(() => {
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        var auxFromDate = formatDate(fromDate);
        var auxToDate = formatDate(toDate);
        setStatusFilter('');
        const loadInvoices = async () => {
            getInvoicesByDates(auxFromDate, auxToDate, userSession.CustomerID, invoiceType, '')
        }
        loadInvoices();
    }, [currentTab,]);


    /*
    useEffect(() => {
        isRender();
    }, [invoiceListSearch]);
*/

    const handleAddNewClick = () => {
        setCurrentInvoice({});
        setShowAddNew(true);
    }

    const onCloseAddNew = () => {
        setShowAddNew(false);
    }

    const updateTab = (tab: any) => {
        setCurrentTab(tab);
        setInvoiceType(tab);
        setInvoiceListSearch([]);
    }

    const resetValidations = (validations: any) => {
        validations.fromDate =
            validations.toDate = true
        return validations;
    }

    const SearchInvoices = () => {
        var auxFromDate = formatDate(fromDate);
        var auxToDate = formatDate(toDate);
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        getInvoicesByDates(auxFromDate, auxToDate, userSession.CustomerID, invoiceType, statusFilter);
    }

    const handleUpdateFromDate = (date: Moment | null, dateString: string) => {
        setFromDate(dateString);
        setMomentFromDate(date as Moment);
    }

    const handleUpdateToDate = (date: Moment | null, dateString: string) => {
        setToDate(dateString);
        setMomentToDate(date as Moment);
    }

    const Clear = () => {
        setInvoiceListSearch([]);
        setInvoiceListSearchFilter([]);
    }

    const formatValidDatePicker = (valid: any) => { return valid ? "DayPickerInput" : "error"; }

    const handleUploadClickFromScreen = () => {
        setShowUploadScreen(true);
    }

    const closeUploadScreen = () => {
        setShowUploadScreen(false);
    }

    const onLoadInvoices = () => {        
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        var auxFromDate = formatDate(fromDate);
        var auxToDate = formatDate(toDate);
        const loadInvoices = async () => {
            getInvoicesByDates(auxFromDate, auxToDate, userSession.CustomerID, invoiceType, statusFilter)
        }
        loadInvoices();
    }

    const closeFilterPayment = () => {
        setOpenFilterPayment(false);
    }

    const closeFilterReceive = () => {
        setOpenFilterReceive(false);
    }

    const statusFilterChangeHandler = (detail: LookUpTableDetail) => {
        if (detail != null) {
            if ((detail.value).includes('Select ')) {
                setStatusFilter('');
            }
            else {
                setStatusFilter(detail.value);
            }
        }
    };

    const isRender = () => {
        var auxFromDate = formatDate(fromDate);
        var auxToDate = formatDate(toDate);
        const userSession = JSON.parse(localStorage.getItem('UserSession')!);
        switch (currentTab) {
            case 'Payable':
            default:
                //List View
                setClassNamePreviousDealsR('');
                setListHiddenP('');
                setClassNamePreviousDealsP('active');
                setListHiddenR(' hidden');
                break;
            case 'Receivable':
                //List View
                setClassNamePreviousDealsR('active');
                setListHiddenP(' hidden');
                setClassNamePreviousDealsP('');
                setListHiddenR('');
                break;
        }
        var listOfInvoiceExport = [] as any;

        if (invoiceListSearch != null && invoiceListSearch.length > 0) {
            invoiceListSearch.forEach((element: any) => {
                var id = element.InvoiceIssuedID;
                var total = formatToCurrency(element.Total, element.CurrencyID) + ' ' + element.CurrencyID;
                if (invoiceType === 'Receivable') {
                    listOfInvoiceExport.push(
                        {
                            'Invoice Number': element.InvoiceNumber,
                            'Invoice Date': element.InvoiceDate,
                            'External Customer ID': element.ExternalCustomerID,
                            'External Customer Name': element.ExternalCustomerName,
                            'Invoice Description': element.InvoiceDescription,
                            'Total Invoice': total,
                            'External Customer Email': element.Email,
                            'Terms': element.Terms,
                            'Status': element.Status
                        }
                    );
                    setListOfInvoiceExportReceivable(listOfInvoiceExport);
                }
                else {
                    listOfInvoiceExport.push(
                        {
                            'Invoice Number': element.InvoiceNumber,
                            'Invoice Date': element.InvoiceDate,
                            'BeneficiaryID': element.BeneficiaryID,
                            'Beneficiary': element.BeneficiaryName,
                            'Invoice Description': element.InvoiceDescription,
                            'Total Invoice': total,
                            'Terms': element.Terms
                        }
                    );
                    setListOfInvoiceExportPayable(listOfInvoiceExport);
                }
            }
            );
        }
    }

    const editInvoice = (record: any) => {

        setCurrentInvoice(record);
        setShowAddNew(true);
    }

    const invoicingColumnsPayable = [
        {
            title: getResource('Label.InvoiceNumber'),
            dataIndex: 'InvoiceNumber',
            key: 'history_invoiceNumber',
            align: 'left',
            sorter: (a: any, b: any) => a['InvoiceNumber'].localeCompare(b['InvoiceNumber'])
        },
        {
            title: getResource('Label.InvoiceDate'),
            dataIndex: 'InvoiceDate',
            key: 'history_invoiceDate',
            align: 'center',
            className: 'cell-date',
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{formatDateString(value)}</span>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => new Date(a['InvoiceDate']).getTime() - new Date(b['InvoiceDate']).getTime()
        },
        {
            title: getResource('Label.InvoiceBeneficiaryID'),
            dataIndex: 'BeneficiaryID',
            key: 'history_beneficiaryID',
            align: 'left',
            responsive: ['md'],
            sorter: (a: any, b: any) => a['BeneficiaryID'] - b['BeneficiaryID']
        },
        {
            title: getResource('Label.InvoiceBeneficiaryName'),
            dataIndex: 'BeneficiaryName',
            key: 'history_beneficiary',
            align: 'left',
            responsive: ['md'],
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{value}</span>
            ),
            sorter: (a: any, b: any) => a['BeneficiaryName'].localeCompare(b['BeneficiaryName'])
        },
        {
            title: getResource('Label.InvoiceDescription'),
            dataIndex: 'InvoiceDescription',
            key: 'history_invoiceDescription',
            sorter: (a: any, b: any) => a['InvoiceDescription'].localeCompare(b['InvoiceDescription']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTotal'),
            dataIndex: 'Total',
            key: 'history_totalInvoice',
            render: ((value: any, obj: ResponseGetInvoicing, index: any) =>
                <span>{formatToCurrency(value, obj.CurrencyID) + ' ' + obj.CurrencyID}</span>
            ),
            sorter: (a: any, b: any) => a['Total'].localeCompare(b['Total']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTerms'),
            dataIndex: 'Terms',
            key: 'history_terms',
            sorter: (a: any, b: any) => a.Terms - b.Terms,
            align: 'left',
            responsive: ['md'],
        },
    ];
    const invoicingColumnsReceivable = [
        {
            title: getResource('Label.InvoiceNumber'),
            dataIndex: 'InvoiceNumber',
            key: 'history_invoiceNumber',
            align: 'left',
            sorter: (a: any, b: any) => a['InvoiceNumber'].localeCompare(b['InvoiceNumber'])
        },
        {
            title: getResource('Label.InvoiceDate'),
            dataIndex: 'InvoiceDate',
            key: 'history_invoiceDate',
            align: 'center',
            className: 'cell-date',
            render: (value: any, obj: ResponseGetInvoicing, index: any) => (
                <span>{formatDateString(value)}</span>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a: any, b: any) => new Date(a['InvoiceDate']).getTime() - new Date(b['InvoiceDate']).getTime()
        },
        {
            title: getResource('Label.InvoiceSenderName'),
            dataIndex: 'ExternalCustomerName',
            key: 'history_externalCustomerName',
            sorter: (a: any, b: any) => a['ExternalCustomerName'].localeCompare(b['ExternalCustomerName']),
            align: 'left',
            responsive: ['md'],
        },
        {
            title: getResource('Label.InvoiceDescription'),
            dataIndex: 'InvoiceDescription',
            key: 'history_invoiceDescription',
            sorter: (a: any, b: any) => a['InvoiceDescription'].localeCompare(b['InvoiceDescription']),
            align: 'left',
        },
        {
            title: getResource('Label.InvoiceTotal'),
            dataIndex: 'Total',
            key: 'history_totalInvoice',
            render: ((value: any, obj: ResponseGetInvoicing, index: any) =>
                <span>{formatToCurrency(value, obj.CurrencyID) + ' ' + obj.CurrencyID}</span>
            ),
            sorter: (a: any, b: any) => a['Total'].localeCompare(b['Total']),
            align: 'left',
        },
        {
            title: getResource('Label.Status'),
            dataIndex: 'Status',
            key: 'history_Status',
            sorter: (a: any, b: any) => a['Status'].localeCompare(b['Status']),
            align: 'left',
            responsive: ['md'],
        },
        {
            title: size[0] > 768 ? getResource('Table_TH_Actions') : "",
            key: 'action',
            align: 'center',
            width: '10%',
            render: (_: any, record: ResponseGetInvoicing) => (
                <React.Fragment>
                    <Tooltip title={getResource('Js.Link.Edit')}>
                        <Typography.Link className="column-actions" key={`${record['InvoiceIssuedID']}`} id={`${record['InvoiceIssuedID']}-edit`} onClick={() => editInvoice(record)}>
                            <EditOutlined style={{ fontSize: 18 }} />
                        </Typography.Link>
                    </Tooltip>
                </React.Fragment>
            )
        }
    ];

    const filteredPayable = momentFromDate || momentToDate

    const filterOptionsPayable = <>
        {!momentFromDate && !momentToDate && getResource('Title.FilterOptions')}
        {momentFromDate && <span className="input-label">{getResource('DateFrom')}: {fromDate}</span>}
        {momentToDate && <span className="input-label">{getResource('DateTo')}: {toDate}</span>}
    </>

    const filteredReceive = momentFromDate || momentToDate

    const filterOptionsReceive = <>
        {!momentFromDate && !momentToDate && getResource('Title.FilterOptions')}
        {momentFromDate && <span className="input-label">{getResource('DateFrom')}: {fromDate}</span>}
        {momentToDate && <span className="input-label">{getResource('DateTo')}: {toDate}</span>}
    </>

    const filterPayment = <div className="col-md-12">
        <div className="row">
            <div className="col-md-12 col-xs-12">
                <div className="row">
                    <span className="input-label">{getResource('DateFrom')}:</span>
                </div>
                <div className="row">
                    <DatePicker
                        id="ratewatch-datepicker-from"
                        key="ratewatch-datepicker-from"
                        //className={formatValidDatePicker(validations.fromDate)}
                        onChange={handleUpdateFromDate}
                        value={momentFromDate}
                        submit={true}
                        errorMessage={"Required"}
                        valueValidation={fromDate}
                    />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-xs-12">
                <div className="row">
                    <span className="input-label">{getResource('DateTo')}:</span>
                </div>
                <div className="row">
                    <DatePicker
                        id="ratewatch-datepicker-todate"
                        key="ratewatch-datepicker-todate"
                        onChange={handleUpdateToDate}
                        value={momentToDate}
                        submit={true}
                        errorMessage={"Required"}
                        valueValidation={toDate}
                    />
                </div>
            </div>
        </div>
        <div className="row-actions">
                <Button type="secondary" onClick={() => { SearchInvoices(); closeFilterReceive(); }}>
                    {getResource('ApplyFilter')}
            </Button>
                <Button type="primary" onClick={() => { Clear(); closeFilterReceive(); }}>
                    {getResource('Reset')}
                </Button>
        </div>
    </div>

    const filterReceive =
        <>
        <div className="row">
            <div className="col-md-12 col-xs-12">
                <div className="row">
                    <span className="input-label">{getResource('DateFrom')}:</span>
                </div>
                <div className="row">
                    <DatePicker
                        id="ratewatch-datepicker-from"
                        key="ratewatch-datepicker-from"
                        //className={formatValidDatePicker(validations.fromDate)}
                        onChange={handleUpdateFromDate}
                        value={momentFromDate}
                        submit={true}
                        errorMessage={"Required"}
                        valueValidation={fromDate}
                    />
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-12 col-xs-12">
                <div className="row">
                    <span className="input-label">{getResource('DateTo')}:</span>
                </div>
                <div className="row">
                    <DatePicker
                        id="ratewatch-datepicker-todate"
                        key="ratewatch-datepicker-todate"
                        onChange={handleUpdateToDate}
                        value={momentToDate}
                        submit={true}
                        errorMessage={"Required"}
                        valueValidation={toDate}
                    />
                </div>
            </div>
        </div>
            <div className="row">
                <div className="col-md-12 col-xs-12">
                    <div className="row">
                        <span className="input-label">{getResource('Label.Status')}:</span>
                    </div>
                    <div className="row">
                        <Dropdown
                            name="statusReceivable"
                            title={getResource('SelectStatus')}
                            list={statusList}
                            select={{ value: statusFilter }}
                            onChange={statusFilterChangeHandler}
                        />
                    </div>
                </div>
            </div>
            <div className="row-actions">
                <Button type="secondary" onClick={SearchInvoices}>
                    {getResource('ApplyFilter')}
                </Button>
                <Button type="primary" onClick={Clear}>
                    {getResource('Reset')}
                </Button>
            </div>
        </>

    const renderScreen = () => {
        switch (currentTab) {
            case 'Payable':
                return <section className={'board board-spot square-relative' + listHiddenP}>

                    <div className="row-actions-1">
                        <DownloadTableRows data={listOfInvoiceExportPayable} fileName={'payable'} />

                        <Tooltip title={filterOptionsPayable}>
                            <Typography.Link onClick={() => setOpenFilterPayment(true)} style={{ marginRight: '10px' }}>
                                {filteredPayable && <FilterFilled />}
                                {!filteredPayable && <FilterOutlined />}
                            </Typography.Link>
                        </Tooltip>
                    </div>
                    <DataGrid
                        className='col-md-12'
                        key={'invoicing-table'}
                        columns={invoicingColumnsPayable}
                        dataSource={invoiceListSearch}
                    />
                    <div className='row-actions'>
                        <Button type="primary" onClick={handleAddNewClick}>
                            {getResource('AddNew')}
                        </Button>
                        <Button type="primary" onClick={handleUploadClickFromScreen}>
                            {getResource('Upload')}
                        </Button>
                    </div>
                    <ModalFilter title={getResource('Title.FilterOptions')} open={openFilterPayment} close={closeFilterPayment} panel={filterPayment} />
                </section>;
            case 'Receivable':
                return <section className={'board board-spot square-relative' + listHiddenR}>

                    <div className="row-actions-1">
                        <DownloadTableRows data={listOfInvoiceExportReceivable} fileName={'receivable'} />

                        <Tooltip title={filterOptionsReceive}>
                            <Typography.Link onClick={() => setOpenFilterReceive(true)} style={{ marginRight: '10px' }}>
                                {filteredReceive && <FilterFilled />}
                                {!filteredReceive && <FilterOutlined />}
                            </Typography.Link>
                        </Tooltip>
                    </div>

                    <DataGrid
                        className='col-md-12'
                        key={'invoicing-table'}
                        columns={invoicingColumnsReceivable}
                        dataSource={invoiceListSearch}
                    />
                    <div className='row-actions'>
                        <Button type="primary" onClick={handleAddNewClick}>
                            {getResource('AddNew')}
                        </Button>
                        <Button type="primary" onClick={handleUploadClickFromScreen}>
                            {getResource('Upload')}
                        </Button>
                    </div>
                    <ModalFilter title={getResource('Title.FilterOptions')} open={openFilterReceive} close={closeFilterReceive} panel={filterReceive} />
                </section>;
            default:
                return <div></div>;
        }
    };
    return (
        <div className="main-container" >
            <div className="title-text"><h2>{getResource('Title.Invoicing')}</h2></div>
            <div className="navbar-horizontal">
                <div className="row">
                    <div
                        className={currentTab === 'Payable' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
                        onClick={() => {
                            updateTab('Payable')
                        }}
                    >
                        <span>{getResource('Payables')}</span>
                    </div>
                    <div
                        className={currentTab === 'Receivable' ? 'col-xs navbar-horizontal-option-selected' : 'col-xs navbar-horizontal-option'}
                        onClick={() => {
                            updateTab('Receivable')
                        }}
                    >
                        <span>{getResource('Receivables')}</span>
                    </div>
                </div>
            </div>

            {renderScreen()}

            {
                showAddNew &&
                <InvoiceAddEditFormDialog
                    onClose={onCloseAddNew}
                    settlementCurrencies={settlementCurrencies}
                    InvoiceType={invoiceType}
                    beneficiaries={beneficiaries}
                    onLoadInvoices={onLoadInvoices}
                    objInvoice={currentInvoice}
                    StatusList={statusList} />
            }
            {
                showUploadScreen &&
                <InvoiceUpload
                    onClose={closeUploadScreen}
                    onLoadInvoices={onLoadInvoices}
                    InvoiceType={invoiceType} />
            }
            {/*
            <ViewAttachment currentFileName={currentFileName}
                currentFileURLList={currentFileURLList}
                showOpenAlert={showOpenAlert}
                currentBlob={currentBlob}
                currentExtension={currentExtension}
                openAttachment={openAttachment}
                onCloseAttachment={onCloseViewAttachment}
                closeAlert={closeAlert}></ViewAttachment>
            */}
        </div >
    );
}
export default InvoiceScreen;