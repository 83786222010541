
import { PaperClipOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import * as mime from 'mime-types';
import React, { useEffect, useState } from 'react';
import { getAttachment } from '../../../api/NetworkingAttachments';
import { getOutgoingInformationByID } from '../../../api/NetworkingFunds';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';

const PaymentDetail = (props: any) => {
  const [load, setLoad] = useState(true);
  const [selectedPaymentID, setSelectedPaymentID] = useState(props.paymentID);
  const [attachment, setAttachment] = useState({
    ID: '',
    FileName: '',
    Description: ''
  });
  const [property, setProperty] = useState({
    Description: '',
    Value: '',
    Attachments: ''
  });
  const [payment, setPayment] = useState({
    BeneficiaryPaymentID: 0,
    DealID: 0,
    BeneficiaryName: '',
    Instrument: '',
    Amount: 0,
    PaymentReference: '',
    Status: '',
    ValueDate: '',
    DetailsOfPayment: '',
    CurrencyID: '',
    AddressLine1: '',
    AddressLine2: '',
    City: '',
    CountryName: '',
    StateName: '',
    PostalCode: '',
    BankName: '',
    TransitNo: '',
    AccountNo: '',
    ABA: '',
    SWIFTID: '',
    BranchAddressLine1: '',
    BranchAddressLine2: '',
    BranchCity: '',
    BranchCountryName: '',
    BranchStateName: '',
    BranchPostalCode: ''
  });
  const [properties, setProperties] = useState([property]);

  const loadPayment = () => {

    getOutgoingInformationByID(selectedPaymentID).then(
      (value) => {
        setPayment(value);
        if (value.EntityProperties != null) {
          setProperties(JSON.parse(value.EntityProperties));
        }
      }
    );
  }

  useEffect(() => {
    loadPayment();
  }, [load]);

  const onAttachmentClick = (att: typeof attachment) => {
    getAttachment(att.ID).then((value) => {
      if (value !== undefined) {
        let fileName = att.FileName;
        var byteCharacters = atob(value.data);
        var byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var bytes = new Uint8Array(byteNumbers);
        var blob = new Blob([bytes], {
          type: mime.lookup(fileName) as string
        });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      }
    });
  }

  const getPropertyControl = (property: any) => {
    var attachmentList = [] as any[];
    if (property.Attachments !== undefined && property.Attachments !== null && property.Attachments !== '') {
      var attachments = JSON.parse(property.Attachments);
      attachments.forEach((att: typeof attachment) =>
        attachmentList.push(
          <div className="row start-xs">
            <div className="col-xs-3 bold-text"></div>
            <div className="col-xs-6">
              <a
                onClick={() => {
                  onAttachmentClick(att);
                }}
              >
                {att.FileName}
                <Tooltip title={getResource('Label.View')} arrowPointAtCenter>
                  <PaperClipOutlined />
                </Tooltip>
              </a>
            </div>
          </div>
        )
      );
    }

    return (
      <div className="margin-left-detail-summary">
        <div className="row start-xs">
          <div className="col-xs-6 col-md-4 bold-text">{property.Description}</div>
          <div className="col-xs-6 col-md-4">{property.Value}</div>

        </div>
        {property.Attachments !== undefined && property.Attachments !== '' &&
          <div>
            {attachmentList}
          </div>
        }
      </div>
    );
  };

  const getPropertiesList = () => {
    var propertiesList = [] as any[];
    if (properties != null && properties.length > 0) {
      properties.forEach((prop: typeof property) =>
        propertiesList.push(getPropertyControl(prop))
      );
    }

    return propertiesList;
  }

  return (
    <React.Fragment>
      {payment.BeneficiaryPaymentID > 0 && (
        <div className="screen-container">
          <div className="row start-xs">
            <div className="col-xs-12 col-md-12 subtitle-text-primary">{getResource('Title.PaymentInformation')}</div>
          </div>
          <div className="margin-left-detail-summary">
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.BeneficiaryName')}</div>
              <div className="col-xs-6 col-md-10 bold-text-big">{payment.BeneficiaryName}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.PaymentID')}</div>
              <div className="col-xs-6 col-md-10">{payment.BeneficiaryPaymentID}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.DealID')}</div>
              <div className="col-xs-6 col-md-10">{payment.DealID}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Amount')}</div>
              <div className="col-xs-6 col-md-10">
              {formatToCurrency(payment.Amount.toString(), payment.CurrencyID)} {payment.CurrencyID}
            </div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.Instrument')}</div>
              <div className="col-xs-6 col-md-10">{payment.Instrument}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.Status')}</div>
              <div className="col-xs-6 col-md-10">{payment.Status}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.ValueDate')}</div>
              <div className="col-xs-6 col-md-10">{payment.ValueDate}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.DetailsPayment')}</div>
              <div className="col-xs-6 col-md-10">{payment.DetailsOfPayment}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.PaymentReference')}</div>
              <div className="col-xs-6 col-md-10">{payment.PaymentReference}</div>
          </div>
          {/*Beneficiary Address*/}
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.BeneficiaryAddress')}</div>
              <div className="col-xs-6 col-md-10">{payment.AddressLine1
                + (payment.AddressLine2 !== null && payment.AddressLine2 !== '' ? ' ' + payment.AddressLine2 : '')
                + (payment.City !== null && payment.City !== '' ? payment.City + "," : "") + " " + payment.StateName
                + (payment.PostalCode !== null && payment.PostalCode !== '' ? payment.PostalCode : "") + " " + payment.CountryName}</div>
            </div>
          </div>

          <div className="row start-xs">
            <div className="col-xs-12 col-md-12 subtitle-text-primary">{getResource('BankAccountInformation')}</div>
          </div>
          <div className="margin-left-detail-summary">
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.BankName')}</div>
              <div className="col-xs-6 col-md-10">{payment.BankName}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.Address')}</div>
              <div className="col-xs-6 col-md-10">{payment.BranchAddressLine1
                + (payment.BranchAddressLine2 !== null && payment.BranchAddressLine2 !== '' ? " " + payment.BranchAddressLine2 : "")
                + (payment.BranchCity !== null && payment.BranchCity !== '' ? payment.BranchCity + "," : "") + " " + payment.BranchStateName
                + (payment.BranchPostalCode !== null && payment.BranchPostalCode !== '' ? payment.BranchPostalCode : "") + " " + payment.BranchCountryName
              }</div>
          </div>

          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.AccountIBAN')}</div>
              <div className="col-xs-6 col-md-10">{payment.AccountNo}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.TransitNo')}</div>
              <div className="col-xs-6 col-md-10">{payment.TransitNo}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.SWIFTBIC')}</div>
              <div className="col-xs-6 col-md-10">{payment.SWIFTID}</div>
          </div>
          <div className="row start-xs">
              <div className="col-xs-6 col-md-2 bold-text">{getResource('Label.ABA')}</div>
              <div className="col-xs-6 col-md-10">{payment.ABA}</div>
            </div>
          </div>
          {properties != null && properties.length > 0 &&
            <div className="row start-xs">
              <div className="col-xs-12 col-md-12 subtitle-text-primary">{getResource('AdditionalInformation')}</div>
            </div>
          }{getPropertiesList()}
          <div className="row-actions" style={{ marginTop: '20px', marginBottom: '20px', padding: 0 }}>
            <Button type="secondary" onClick={props.onClickExit}>
              {getResource('Btn_Exit')}
            </Button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PaymentDetail;
