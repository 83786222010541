import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { getResource } from '../../helpers/ResourcesHelper';

const OptionalInformation = (props: any) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneExtension, setPhoneExtension] = useState('');
  const [faxNumber, setFaxNumber] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [externalBeneficiaryKey, setExternalBeneficiaryKey] = useState('');
  //const [internalBeneficiaryID, setInternalBeneficiaryID] = useState('');

  useEffect(() => {
    if (props.beneficiary != null) {
      setPhoneNumber(props.beneficiary.PhoneNumber);
      setPhoneExtension(props.beneficiary.PhoneExtension);
      setFaxNumber(props.beneficiary.FaxNumber);
      setMobileNumber(props.beneficiary.MobileNumber);
    }
    if (props.externalBeneficiaryProperty != null) {
      setExternalBeneficiaryKey(props.externalBeneficiaryProperty.Value);
    }
  }, [props.beneficiary, props.externalBeneficiaryProperty]);

  const onChangePhoneNumber = (e: any) => {
    setPhoneNumber(e.target.value);
    props.onUpdateBeneficiary('PhoneNumber', e.target.value);
  };

  const onChangePhoneExtension = (e: any) => {
    setPhoneExtension(e.target.value);
    props.onUpdateBeneficiary('PhoneExtension', e.target.value);
  };

  const onChangeFaxNumber = (e: any) => {
    setFaxNumber(e.target.value);
    props.onUpdateBeneficiary('FaxNumber', e.target.value);
  };

  const onChangeMobileNumber = (e: any) => {
    setMobileNumber(e.target.value);
    props.onUpdateBeneficiary('MobileNumber', e.target.value);
  };

  const onChangeExternalBeneficiaryKey = (e: any) => {
    setExternalBeneficiaryKey(e.target.value);
    props.onUpdateBeneficiary('ExternalBeneficiaryKey', e.target.value);
  };

  return (
    <div className={props.canEdit ? '' : 'readonly'}>
      <div className="row max-w-xs-sm">
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.PhoneNumber')}</span>
          </div>
          <div className="row">
            <PhoneInput
              inputProps={{
                name: 'primary-phone',
                required: true,
                autoFocus: true
              }}
              enableSearch={true}
              disableSearchIcon={true}
              containerClass={'phone-container'}
              inputClass={'input-phone'}
              buttonClass={'button-phone'}
              dropdownClass={'dropdown-phone'}
              placeholder=''
              autoFormat
              copyNumbersOnly={true}
              countryCodeEditable={true}
              value={phoneNumber}
              onChange={(phone: any) => {
                setPhoneNumber(phone);
                props.onUpdateBeneficiary('PhoneNumber', phone);
              }}
              preferredCountries={['gb']}
              searchPlaceholder='Search a country'
              autocompleteSearch
            />
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.PhoneExtension')}</span>
          </div>
          <div className="row">
            <input type="text" onChange={onChangePhoneExtension} value={phoneExtension}></input>
          </div>
        </div>
      </div>

      <div className="row max-w-xs-sm">
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.FaxNumber')}</span>
          </div>
          <div className="row">
            <input type="text" onChange={onChangeFaxNumber} value={faxNumber}></input>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.MobileNumber')}</span>
          </div>
          <div className="row">
            <PhoneInput
              inputProps={{
                name: 'mobile-phone',
                required: true,
                autoFocus: true
              }}
              enableSearch={true}
              disableSearchIcon={true}
              containerClass={'phone-container'}
              inputClass={'input-phone'}
              buttonClass={'button-phone'}
              dropdownClass={'dropdown-phone'}
              placeholder=''
              autoFormat
              copyNumbersOnly={true}
              countryCodeEditable={true}
              value={mobileNumber}
              onChange={(phone: any) => {
                setMobileNumber(phone);
                props.onUpdateBeneficiary('MobileNumber', phone);
              }}
              preferredCountries={['gb']}
              searchPlaceholder='Search a country'
              autocompleteSearch
            />
          </div>
        </div>
      </div>

      <div className="row max-w-xs-sm">
        <div className="col-md-6 col-xs-12">
          <div className="row">
            <span className="input-label">{getResource('Label.ExternalBeneficiaryKey')}</span>
          </div>
          <div className="row">
            <input type="text" onChange={onChangeExternalBeneficiaryKey} value={externalBeneficiaryKey}></input>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionalInformation;
