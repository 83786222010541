import { EditOutlined } from '@ant-design/icons';
import { Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useState } from 'react';
import { Address, getAddressByPersonID } from '../../../api/NetworkingAddresses';
import useWindowSize from '../../../api/hooks/useWindowResize';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import DownloadTableRows from '../../shared/DownloadTableRows';
import AddressForm from './addresses/Address';
interface Item extends Address {
  isNew: boolean;
}

const Addresses = () => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [updating, setUpdating] = useState(false);

  const [currentAddressID, setCurrentAddressID] = useState('');
  const [editAddress, setEditAddress] = useState(false);
  const [newAddress, setNewAddress] = useState(false);
  const size = useWindowSize();

  // const [currentPage, setCurrentPage] = useState(1);

  // const [filteringSomething, setFilteringSomething] = useState(false);
  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');
  // const [searchArray, setSearchArray] = useState({});
  // const searchInput = useRef();

  useEffect(() => {
    const loadAddresses = async () => {
      const personID = localStorage.getItem('CustomerPersonID')!;
      const json = await getAddressByPersonID(personID);
      if (json != null && json.addresses != null) {
        const addresses = json.addresses.map((a: Address, index: number) => ({ ...a, key: a['Address ID'], order: `address-${index + 1}` }));
        setAddresses(addresses);
      }
    };
    loadAddresses();
  }, [updating]);

  const edit = (record: Address) => {
    setCurrentAddressID(record['Address ID']);
    setEditAddress(true);
  };

  const handleBackLink = () => {
    setCurrentAddressID('');
    setEditAddress(false);
    setNewAddress(false);
    setUpdating(!updating);
  };

  const columns: ColumnsType<Address> = [
    {
      title: getResource('Label.AddressType'),
      dataIndex: 'Address Type',
      key: 'Address Type',
      align: 'left',
      fixed: 'left',
      responsive: ['md'],
    },
    {
      title: getResource('Label.Address'),
      dataIndex: 'Address',
      key: 'Address',
      align: 'left'
    },
    /*{
      title: 'Address 2',
      dataIndex: 'Address2$',
      key: 'Address2$',
      align: 'left'
    },*/
    {
      title: getResource('Label.Country'),
      dataIndex: 'Country',
      key: 'Country',
      align: 'left'
    },
    {
      title: getResource('Label.ProvinceState'),
      dataIndex: 'State',
      key: 'State',
      align: 'left',
      responsive: ['md'],
    },
    {
      title: getResource('Label.City'),
      dataIndex: 'City',
      key: 'City',
      align: 'left',
      responsive: ['md'],
    },
    {
      title: getResource('Label.ZipCode'),
      dataIndex: 'ZipCode$',
      key: 'ZipCode$',
      align: 'center',
      responsive: ['md'],
    },
    {
      title: getResource('Label.SinceWhen'),
      dataIndex: 'SinceWhen$',
      key: 'SinceWhen$',
      align: 'center',
      responsive: ['md'],
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_Actions') : "",
      dataIndex: 'Actions',
      align: 'center',
      width: '10%',
      fixed: 'right',
      render: (_: any, record: Address) => (
        <>
          <Tooltip title={getResource('Js.Link.Edit')}>
            <Typography.Link key={`${record.order}`} id={`${record.order}-edit`}
              onClick={() => edit(record)}
              className="column-actions"
            >
              <EditOutlined style={{fontSize: 18}}/>
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];

  if (editAddress || newAddress) {
    return <AddressForm key="address-form" addressID={newAddress ? '' : currentAddressID} handleBackLink={handleBackLink} />;
  }

  return (
    <div>      
      <div className="row-actions-1">
        <DownloadTableRows data={addresses} fileName={'addresses'} />
      </div>
        <Table
          columns={columns}
          dataSource={addresses}
        />
      <div className="row-actions">
        <Button
          id="contacts-add-contact"
          type="secondary"
          onClick={() => { setNewAddress(true); }}
        >{getResource('AddAddress')}</Button>
      </div>
    </div>
  );
};

export default Addresses;
