import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Switch from "antd/lib/switch";
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { getListOfChallengeQuestions, saveChallengeAnswers } from '../../../api/NetworkingUsers';
import useForceUpdate from '../../../api/hooks/useForceUpdate';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import Input from '../../inputs/Input';

interface QuestionAndAnswer {
  QuestionID: number;
  QuestionText: string;
  Answer: string;
}

interface QuestionProps {
  userSession?: any;
}

const Questions: FunctionComponent<QuestionProps> = (props) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const { showModal } = useContext(AppContext);
  const forceUpdate = useForceUpdate();
  const [showChallengeQuestions, setShowChallengeQuestions] = useState(false);

  const [listOfQuestionsAndAnswers, setListOfQuestionsAndAnswers] = useState<QuestionAndAnswer[]>([]);

  useEffect(() => {
    getListOfChallengeQuestions().then(
      (json) => {

        if (json !== undefined && json.Questions != null) {
          json.Questions.forEach((question: any) => {
            question.Answer = '';
          });
          setListOfQuestionsAndAnswers(json.Questions);
        }
      }
    );
  }, [updating])

  useEffect(() => {
    if (props.userSession != null) {
      setShowChallengeQuestions(props.userSession.ShowChallengeQuestions);
    }
  }, [props.userSession])

  const saveAnswers = () => {
    setSaving(true);
    if (validateFields()) {
      let listAnswers: any[] = [];
      listOfQuestionsAndAnswers.forEach((questionAndAnswer: QuestionAndAnswer, index) => {
        var item = {
          QuestionID: questionAndAnswer.QuestionID,
          Answer: questionAndAnswer.Answer
        };
        listAnswers.push(item);
      });

      let userSession = JSON.parse(localStorage.getItem('UserSession')!);
      let contactName = userSession.ContactName;

      if (userSession.BeneficiaryID != null && userSession.BeneficiaryID > 0) {
        contactName = userSession.BeneficiaryName;
      }

      let modelSaveChallengeAnswers = {
        "UserID": userSession.PersonID,
        "QuestionsJson": listAnswers,
        "ShowChallengeQuestions": showChallengeQuestions,
        "InstallationID": userSession.InstallationID,
        "UserName": userSession.UserName,
        "ContactName": contactName
      }

      if (props.userSession != null) props.userSession.ShowChallengeQuestions = showChallengeQuestions;
      saveChallengeAnswers(modelSaveChallengeAnswers).then(
        (responseSaveChallengeAnswers) => {
          if (responseSaveChallengeAnswers !== undefined) {
            let title = getResource('Js_MessageError_TitleError');
            let message = 'Something went wrong. Please try again later.';

            if (responseSaveChallengeAnswers.success !== undefined) {
              if (props.userSession != null) localStorage.setItem('UserSession', JSON.stringify(props.userSession));
              if (responseSaveChallengeAnswers.success) {
                title = getResource('Js_MessageError_TitleSuccess');
                message = getResource('Js_Message_SaveSuccessfullyAnswers');
                setUpdating(!updating);
                setSaving(false);
              }
            }

            if (responseSaveChallengeAnswers.Message !== undefined && responseSaveChallengeAnswers.Message !== '') {
              title = getResource('Js_MessageError_TitleError');
              message = responseSaveChallengeAnswers.Message;
            }

            showModal(title, message);
          }
          else {
            showModal(getResource('Js_MessageError_TitleError'), getResource('SomethingWentWrongTryAgain'));
          }
        }
      );
    } else {
      showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted'));
    }
  }

  const validateFields = () => {

    let auxListOfQuestionsAndAnswers: boolean = true;

    listOfQuestionsAndAnswers.forEach((questionAndAnswer: QuestionAndAnswer, index) => {
      if (questionAndAnswer.Answer === '') {
        auxListOfQuestionsAndAnswers = false;
      }
    });

    return (auxListOfQuestionsAndAnswers);
  }

  const inputsActions = (event: any, questionID: number) => {
    let newListOfQuestionsAndAnswers = listOfQuestionsAndAnswers;
    newListOfQuestionsAndAnswers.forEach((questionAndAnswer: QuestionAndAnswer) => {
      if (questionAndAnswer.QuestionID == questionID) {
        questionAndAnswer.Answer = event.target.value;
      }
    });
    setListOfQuestionsAndAnswers(newListOfQuestionsAndAnswers);
    forceUpdate();
  }
  const GetNeedAnswer = (value: any) => {
    setShowChallengeQuestions(value);
  }

  const QuestionsAndAnswertLikeElements = (list: QuestionAndAnswer[]) => {
    let auxListQAA_LikeElement: JSX.Element[] = [];
    list.forEach((questionAndAnswer: QuestionAndAnswer, index) => {
      auxListQAA_LikeElement.push(
        <div className="row center-xs">
          <div className="col-md-9 col-xs-12">
            <div className="row">
              <span className="input-label">{questionAndAnswer.QuestionText}</span>
            </div>
            <div className="row">
              <Input
                type="text"
                value={listOfQuestionsAndAnswers[index].Answer}
                submit={saving}
                onChange={(event: any) => {
                  return inputsActions(event, questionAndAnswer.QuestionID);
                }}
              />
            </div>
          </div>
        </div>
      );
    });

    return (auxListQAA_LikeElement);
  }

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-5 col-xs-12">
          <div className="board">
            <h5 className="input-label start-sm">{getResource('PersonalVerificationQuestions')}</h5>
            <p className="text">{getResource('Questions_Text1')}</p>
            <p className="text">{getResource('Questions_Text2')}</p>
          </div>
          <div className="row">
            <div className="col-md-7 col-xs-10">
              <span className="input-label start-sm start-xs">{getResource('ShowChanllengeQuestions')}</span>
            </div>
            <div className="col-md-5 col-xs-2 start-sm start-xs">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={false}
                checked={showChallengeQuestions}
                onChange={GetNeedAnswer}
              />
            </div>
          </div>
        </div>
        <div className="col-md-7 col-xs-12" style={{ marginTop: '10px' }}>
          <div>
            {QuestionsAndAnswertLikeElements(listOfQuestionsAndAnswers)}
            <div className="row-actions">
                  <Button onClick={(event: any) => saveAnswers()}>{getResource('Button.Save')}</Button>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Questions;
