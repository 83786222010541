import { CaretDownOutlined, CaretUpOutlined, DollarCircleOutlined, EditOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { getListOfRegularPayments } from '../../../api/NetworkingPayments';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import useWindowSize from '../../../api/hooks/useWindowResize';
import { formatToCurrency } from '../../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from '../../inputs/Button';
import DownloadTableRows from '../../shared/DownloadTableRows';
import DataGrid from '../../table/DataGrid';
import AddEditRegularPayment from './AddEditRegularPayment';

export interface RegularPayment {
  'Beneficiary Name': string;
  'Confirmed By Client': number;
  'Currency ID': string;
  'Customer Ref No': string;
  'External Comments': string;
  'Issue Date': string; //"2021/07/22"
  'Next Execute': string;
  'Notification Method': string;
  'Payment Amount': string;
  'Payment Beneficary ID': number; //13905
  'Payment Beneficiary BankAccount ID': string; //1012281
  'Payment Beneficiary Type': number; //0
  'Payment Currency ID': string;
  'Regular Payment ID': number; //3086
  'Regular Payment Origin': string; //"Online Deal"
  'Settlement Bank Account': string; //1012378
  'Settlement CurrencyID': string;
  'Settlement Delivery Methodas': string; //note key name is not written correctly
  'Settlement Instrument': string; //"Direct Debit"
  'Settlement Nostro Account': number; //1005356
  Status: '';
  'Third Party Beneficiary Payment': number; //0
  'Transfer Day': string; //"Monday"
  'Transfer Monthly On Day': string;
  'Transfer Ending After Occurrences': number; //1
  'Transfer Ending Type': string; //"After"
  'Transfer Ending On': string; // "2021/08/27"
  'Transfer Starting Date': string; //"2021/07/23"
  'Transfer Type': string; //"Weekly"
  UpdateToken: string;
}

interface ExpandIcon {
  expanded: boolean;
  record: RegularPayment;
  onExpand: (record: RegularPayment, e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
}

interface RegularPaymentsProps {
  onMakeOrder: (payment: RegularPayment) => void;
}

const RegularPayments: React.FC<RegularPaymentsProps> = ({ onMakeOrder }) => {
  type Action = 'add' | 'edit';
  const [payment, setPayment] = useState<RegularPayment | null>(null);
  const [regularPayments, setRegularPayments] = useState<RegularPayment[]>([]);
  const [updating, setUpdating] = useState(false);
  const [showRegularPayment, setShowRegularPayment] = useState(false);
  const [action, setAction] = useState<Action>('add');
  const size = useWindowSize();

  //rights
  let OL_RegularPaymentAddNew = useOnlineRights(OnlineRightsNames.OL_RegularPaymentAddNew);
  let OL_RegularPaymentEdit = useOnlineRights(OnlineRightsNames.OL_RegularPaymentEdit);
  let OL_OrderAccess = useOnlineRights(OnlineRightsNames.OL_OrderAccess);

  useEffect(() => {
    const loadRegularPayments = async () => {
      const customerID = JSON.parse(localStorage.getItem('UserSession')!).CustomerID;
      const json = await getListOfRegularPayments(customerID, true);
      if (json != null && json.RegularPayment != null) {
        const payments = json.RegularPayment.map((p: RegularPayment) => ({ ...p, key: p['Regular Payment ID'] }));
        setRegularPayments(payments);
      }
    };
    loadRegularPayments();
  }, [updating]);

  const showAddRegularPayment = () => {
    setAction('add');
    setShowRegularPayment(true);
  };

  const editRegularPayment = (payment: RegularPayment) => {
    setAction('edit');
    setPayment(payment);
    setShowRegularPayment(true);
  };

  const makeOrder = (payment: RegularPayment) => {
    onMakeOrder(payment);
  };

  const onCancel = () => {
    setPayment(null);
    setShowRegularPayment(false);
    setUpdating(!updating);
  };

  const expandableRowRender = (dataSource: RegularPayment[]) => {
    const columns = [
      {
        title: getResource('Js_TH_Currency'),
        dataIndex: 'Currency',
        key: 'Currency',
        align: 'center',
        render: (_: any, record: RegularPayment) => <Text>{`Currency: ${record['Settlement CurrencyID']}/${record['Payment Currency ID']}`}</Text>
      },
      {
        title: getResource('Js_TH_Beneficiary'),
        dataIndex: 'Beneficiary',
        key: 'Beneficiary',
        align: 'center',
        render: (_: any, record: RegularPayment) => <Text>{`Beneficiary: ${record['Beneficiary Name']}`}</Text>
      }
    ];
    return <DataGrid columns={columns} showHeader={false} dataSource={dataSource} pagination={false} />;
  };

  const columns = [
    {
      title: getResource('Description'),
      dataIndex: 'External Comments',
      key: 'Description',
      align: 'center',
      render: (_: any, record: RegularPayment) => <Text type="success">{record['External Comments']}</Text>
    },
    {
      title: getResource('Amount'),
      dataIndex: 'Payment Amount',
      key: 'Amount',
      align: 'center',
      render: (_: any, record: RegularPayment) => <Text>{`${formatToCurrency(record['Payment Amount'], record['Currency ID'])} ${record['Currency ID']}`}</Text>
    },
    {
      title: getResource('Js_TH_Status'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      responsive: ['md']
    },
    {
      title: getResource('Details'),
      dataIndex: 'Next Execute',
      key: 'Details',
      align: 'center',
      className: 'cell-date',
      render: (_: any, record: RegularPayment) => <Text>{record['Next Execute'] != null ? record['Next Execute'] : 'Next Execute: Not set'}</Text>
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_Actions') : "",
      key: 'action',
      align: 'center',
      render: (_: any, record: RegularPayment) => (
        <React.Fragment>
          {OL_RegularPaymentEdit && <Tooltip title={getResource('Js.Link.Edit')}>
            <Typography.Link className="column-actions" key={`${record['Regular Payment ID']}`} id={`${record['Regular Payment ID']}-edit`} onClick={() => editRegularPayment(record)}>
              <EditOutlined style={{ fontSize: 18 }} />
            </Typography.Link>
          </Tooltip>}
          {OL_OrderAccess &&
            <Tooltip title={getResource('Js.Link.UseNow')}>
              <Typography.Link className="column-actions" key={`${record['Regular Payment ID']}`} id={`${record['Regular Payment ID']}-use`} onClick={() => makeOrder(record)}>
              <DollarCircleOutlined style={{ fontSize: 18 }} />
            </Typography.Link>
            </Tooltip>}
        </React.Fragment>
      )
    }
  ];

  if (showRegularPayment) {
    return <AddEditRegularPayment action={action} payment={payment} onCancel={onCancel} />;
  }

  return (
    <div>

      <div className="row-actions-1">
        <DownloadTableRows data={regularPayments} fileName={'payments'} />
      </div>
      <DataGrid
        className=""
        columns={columns}
        dataSource={regularPayments}
        expandable={{
          expandedRowRender: (record: RegularPayment) => expandableRowRender(regularPayments.filter((p: RegularPayment) => p['Regular Payment ID'] === record['Regular Payment ID'])),
          expandIcon: ({ expanded, onExpand, record }: ExpandIcon) => {
            if (expanded) {
              return <CaretUpOutlined onClick={(e) => onExpand(record, e)} />;
            } else {
              return <CaretDownOutlined onClick={(e) => onExpand(record, e)} />;
            }
          }
        }}
      />
      <div className="row-actions">
        {OL_RegularPaymentAddNew && 
          <Button type="primary" onClick={showAddRegularPayment}>
            {getResource('AddNewRegularPayment')}
          </Button>
        }
      </div>
    </div>
  );
};

export default RegularPayments;
