import { ZoomInOutlined } from '@ant-design/icons';
import { Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { Message, dismissMessage, getListOfMessages } from '../../api/NetworkingMessages';
import useWindowSize from '../../api/hooks/useWindowResize';
import { getResource } from '../../helpers/ResourcesHelper';
import MessageContent from './messages/MessageContent';

const MyMessages = () => {
  const { showModal, updateMessages } = useContext(AppContext);
  const [messages, setMessages] = useState<Message[]>([]);
  const [updating, setUpdating] = useState(false);
  const [viewMessageContent, setViewMessageContent] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<Message | null>(null);
  const size = useWindowSize();

  useEffect(() => {
    const loadMessages = async () => {
      const json = await getListOfMessages();
      if (json != null && json.messages != null) {
        setMessages(json.messages);
      }
    };
    loadMessages();
  }, [updating]);

  const selectMessageToReply = async (record: Message) => {
    const json = await dismissMessage(record.MessageId);
    if (json != null) {
      if (json.httpStatusCode === 200) {
        setSelectedMessage(record);
        setViewMessageContent(true);
        updateMessages();
      } else {
        showModal(getResource('Js_MessageError_TitleError'), getResource('Message_Error_Read_Message'));
      }
    }


  };

  const handleBackLink = (showSuccessAlert: boolean = false) => {
    if (showSuccessAlert) {
      showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Message_Successfully_Sent'));
    }
    setSelectedMessage(null);
    setViewMessageContent(false);
    setUpdating(!updating);
  };

  const columns: ColumnsType<Message> = [
    {
      title: getResource('Table_TH_Date'),
      dataIndex: 'SentOn',
      key: 'sentOn',
      width: '15%',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a: Message, b: Message) => {
        return a['MessageId'] - b['MessageId'];
      },
    },
    {
      title: getResource('Table_TH_Sender'),
      dataIndex: 'Sender',
      key: 'sender',
      width: '10%',
      align: 'center',
      sorter: (a: Message, b: Message) => {
        return a['Sender'].localeCompare(b['Sender']);
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: getResource('Table_TH_Subject'),
      dataIndex: 'Subject',
      key: 'subject',
      width: '10%',
      align: 'center',
      sorter: (a: Message, b: Message) => {
        return a['Subject'].localeCompare(b['Subject']);
      },
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Body',
      dataIndex: 'Content',
      width: '55%',
      align: 'center',
      sorter: (a: Message, b: Message) => {
        return a['Content'].localeCompare(b['Content']);
      },
      sortDirections: ['descend', 'ascend'],
      responsive: ['md'],
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_Actions') : "",
      key: 'action',
      width: '10%',
      align: 'center',
      render: (_: any, record: Message) => (
        <div>
          <Tooltip title={getResource('Js_Link_ViewMessage')}>
            <Typography.Link className="column-actions" onClick={() => selectMessageToReply(record)}>
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
        </div>
      )
    }
  ];

  if (viewMessageContent) {
    return <MessageContent message={selectedMessage} handleBackLink={handleBackLink} />;
  }

  return (
    <>
      <div className="title-text"><h2>{getResource('Tab_MyMessages')}</h2></div>
      <div className="screen-container">
      <Table columns={columns} dataSource={messages}
        rowClassName={(record, index) => record.Status === 'Send' ? 'readMessage' : ''}
      />
    </div>
    </>
  );
};

export default MyMessages;
