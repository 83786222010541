import { DownloadOutlined } from '@ant-design/icons';
import { Table } from 'ant-table-extensions';
import { Collapse } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useState } from 'react';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import { UploadErrors } from '../models/UploadErrors';

const { Panel } = Collapse;

const UploadTransactionFileErrors = (props: any) => {

  const [errors, setErrors] = useState<string[]>([]);
  const [likePopup, setlikePopup] = useState<boolean>(props.likePopup);

  const errorsColumn: ColumnsType<UploadErrors> = [
    {
      title: getResource('Table_TH_Row'),
      dataIndex: 'row_n',
      key: 'row_n',
      align: 'center',
      sorter: (a, b) => a.row_n.localeCompare(b.row_n)
    },
    {
      title: getResource('Table_TH_Column'),
      dataIndex: 'column_name',
      key: 'column_name',
      align: 'center',
      sorter: (a, b) => a.column_name.localeCompare(b.column_name)
    },
    {
      title: getResource('Table_TH_Error'),
      dataIndex: 'message_error',
      key: 'message_error',
      align: 'center',
      sorter: (a, b) => a.message_error.localeCompare(b.message_error)
    }
  ];

  const onClose = () => {
    props.onErrorsClose([], false);
  }

  return (
    <React.Fragment>
      {!likePopup && <div className="navbar-horizontal">
        <span>{getResource('Table_TH_ErrorList')}</span>
      </div>
        &&
      <div className="col-md-3 col-xs-12" style={{ paddingTop: 1 }}>
        <Button type="secondary" onClick={() => { onClose(); }}>
          {getResource('Button.Close')}
        </Button>
        </div>}
      <div className="navbar-horizontal">
        <Table
          className=""
          key={'errors-table'}
          columns={errorsColumn}
          dataSource={props.errors}
          exportableProps={{
            fileName: 'Transactions',
            btnProps: {
              type: "default",
              icon: <DownloadOutlined />,
            }
          }}
        />
      </div>
      {likePopup && <div className="col-md-3 col-xs-12" style={{ paddingTop: 1 }}>
        <Button type="secondary" onClick={() => { onClose(); }}>
          {getResource('Button.Close')}
        </Button>
      </div>}
    </React.Fragment>
  );
};

export default UploadTransactionFileErrors;