import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import Switch from "antd/lib/switch";
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import { getRandomChallengeQuestions, validateChallengeQuestionAnswers } from '../../../api/NetworkingUsers';
import { getResource } from '../../../helpers/ResourcesHelper';
import Button from "../../inputs/Button";
import Input from "../../inputs/Input";

interface ValidateProps {
  userSession?: any,
  answersValidated?: any,
}
const ValidateQuestions: FunctionComponent<ValidateProps> = (props) => {
  const [updating, setUpdating] = useState<boolean>(false);
  const { showModal } = useContext(AppContext);
  const [showChallengeQuestionsNextTime, setShowChallengeQuestionsNextTime] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [saving, setSaving] = useState<boolean>(false);

  useEffect(() => {
    getRandomChallengeQuestions().then(
      (json) => {
        if (json != null && json.Questions != null) {
          const newAnswers = json.Questions.map((q: any) => ({ QuestionID: q.QuestionID, AnswerText: '' }));
          setQuestions(json.Questions);
          setAnswers(newAnswers);
        }
      }
    )
  }, [updating])

  const validateAnswers = async () => {
    const model = {
      answers: answers,
      showChallengeQuestionsNextTime: showChallengeQuestionsNextTime
    };
    if (props.userSession != null) props.userSession.ShowChallengeQuestions = showChallengeQuestionsNextTime;
    const json = await validateChallengeQuestionAnswers(model);
    if (json != null && json.Result != null) {
      if (json.Result) {
        if (props.userSession != null) localStorage.setItem('UserSession', JSON.stringify(props.userSession));
        if (props.answersValidated != null) {
          props.answersValidated();
        }
      } else {
        showModal(getResource('Js_MessageError_TitleError'), getResource('VerificationChallengeAnswers.Error'));
      }
    }
  }

  const ShowChallengeQuestionsNextTime = (value: any) => {
    setShowChallengeQuestionsNextTime(value);
  }

  const handleAnswerUpdate = (event: any, question: any) => {
    let newAnswers = answers.map(
      (a: any) => a.QuestionID === question.QuestionID ?
        { QuestionID: a.QuestionID, AnswerText: event.target.value } : a
    ) as any;
    setAnswers(newAnswers);
  }

  return (
    <div>
      <div className="row-xs">
        <div className="col-md-6 col-xs-12 start-xs">
          <div className="board">
            <h5 className="input-label start-sm">{getResource('PersonalVerificationQuestions')}</h5>
            <p>{getResource('Verification_Questions_Text1')}</p>
            <p>{getResource('Verification_Questions_Text2')}</p>
          </div>
          <div className="row">
            <div className="col-md-7 col-xs-12">
              <span className="input-label start-sm start-xs">{getResource('ShowChanllengeQuestions')}</span>
            </div>
            <div className="col-md-5 col-xs-12 start-sm start-xs">
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={false}
                checked={showChallengeQuestionsNextTime}
                onChange={ShowChallengeQuestionsNextTime}
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-xs-12">
          <div className="screen-container">
            {
              questions.map(
                (question: any, i: number) => (
                  <div className="row center-xs">
                    <div className="col-md-9 col-xs-12">
                      <div className="row">
                        <span className="input-label">{question.QuestionText}</span>
                      </div>
                      <div className="row">
                        <Input
                          type="text"
                          //value={answers[i]['AnswerText']}
                          submit={saving}
                          onChange={(event: any) => {
                            return handleAnswerUpdate(event, question);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )
              )
            }
          </div>
          <div className="row-actions">
            <Button onClick={validateAnswers}>{getResource('Button.Validate')}</Button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ValidateQuestions;