import { DollarOutlined, DollarTwoTone, DownloadOutlined, LikeTwoTone, RocketTwoTone, ZoomInOutlined } from "@ant-design/icons";
import { Steps, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import * as mime from 'mime-types';
import moment from "moment";
import React, { FunctionComponent, useContext, useEffect, useState } from "react";
import AppContext from "../../../App.context";
import { getConfirmation, getListOfDeals } from "../../../api/NetworkingDeals";
import useLookUpTables from "../../../api/hooks/useLookUpTables";
import useOnlineRights from "../../../api/hooks/useOnlineRights";
import useWindowSize from "../../../api/hooks/useWindowResize";
import { formatToCurrency } from "../../../helpers/FormatHelper";
import { OnlineRightsNames } from "../../../helpers/OnlineRightsHelper";
import { getResource } from "../../../helpers/ResourcesHelper";
import Button from "../../inputs/Button";
import { Deal } from "../../models/Deal";
import DownloadTableRows from "../../shared/DownloadTableRows";
import Summary from "../summary/Summary";
import DrawdownMultipleBeneficiaries from "./Drawdown.tsx/DrawdownMultipleBeneficiaries";
import Forward from "./Forward";

const { Step } = Steps;

interface ForwardContainerProps {
  fromOtherScreenDealObj: Deal;
  fromOtherScreenForward: string;
  objOrderScreen?: {} | null;
}

const ForwardContainer: FunctionComponent<ForwardContainerProps> = (props) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [updating, setUpdating] = useState(false);
  const [currentScreen, setCurrentScreen] = useState(0);
  const [dealID, setDealID] = useState(0);
  const [fromOtherScreenDealObj, setFromOtherScreenDealObj] = useState<Deal>(new Deal(0, '', '', '', 0, 0, '', '', ''));
  const [fromOtherScreen, setfromOtherScreen] = useState<string>('');

  const [showNewForward, setShowNewForward] = useState(false);
  const [forwards, setForwards] = useState<any[]>([]);

  const lookUpTables = useLookUpTables('Forward Types');

  const size = useWindowSize();

  //rights
  let OL_ForwardAddNew = useOnlineRights(OnlineRightsNames.OL_ForwardAddNew);
  let OL_DrawdownAddNew = useOnlineRights(OnlineRightsNames.OL_DrawdownAddNew);
  let OL_Disburse = useOnlineRights(OnlineRightsNames.OL_Disburse);

  useEffect(() => {
    getListOfDeals('', 'Forward', '', (new Date().getFullYear() + '/01/01'), moment(new Date).format('YYYY/MM/DD'), JSON.parse(localStorage.getItem('UserSession')!).CustomerID, 0, 1, 0).then((response) => {
      if (response != null && response.deals != null) {
        response.deals.forEach((deal: any) => {
          deal.key = deal.DealID;
        });
        setForwards(response.deals);
      } else {
        setForwards([]);
      }
    });
  }, [updating]);

  useEffect(() => {
    if (props.objOrderScreen) {
      setShowNewForward(true);
      setCurrentScreen(0);
    }
  }, [props.objOrderScreen]);

  const onAcceptForward = (forwardType: string, dealHeaderID: number) => {
    if (dealHeaderID > 0) {
      setDealID(dealHeaderID);
      if (forwardType == 'Closed') {
      setCurrentScreen(1);
      }
      else {
        setCurrentScreen(2);
      }
    }
  }

  const onNewTradeClick = () => {
    setCurrentScreen(0);
  };

  const IconDealStatus = (status: string) => {
    switch (status) {
      case 'Pending for Approval':
        return (
          <Tooltip title={status} color="#f90" className="column-actions">
            <LikeTwoTone twoToneColor="#f90" />
          </Tooltip>
        );
      case 'Pending':
        return (
          <Tooltip title={status} color="#ff0000" className="column-actions">
            <LikeTwoTone twoToneColor="#ff0000" />
          </Tooltip>
        );
      case 'Accepted':
        return (
          <Tooltip title={status} color="#52c41a" className="column-actions">
            <LikeTwoTone twoToneColor="#52c41a" />
          </Tooltip>
        );
      case 'Voided':
        return (
          <Tooltip title={status} color="#808080" className="column-actions">
            <LikeTwoTone twoToneColor="#808080" />
          </Tooltip>
        );
      case 'Work in Progress':
        return (
          <Tooltip title={status} color="#1f94a1" className="column-actions">
            <LikeTwoTone twoToneColor="#1f94a1" />
          </Tooltip>
        );
    }
  };

  const IconDealPaidStatus = (status: string) => {
    switch (status) {
      case 'Partially Paid':
        return (
          <Tooltip title={status} color="#f90" className="column-actions">
            <DollarTwoTone twoToneColor="#f90" />
          </Tooltip>
        );
      case 'Unpaid':
        return (
          <Tooltip title={status} color="#ff0000" className="column-actions">
            <DollarTwoTone twoToneColor="#ff0000" />
          </Tooltip>
        );
      case 'Fully Paid':
        return (
          <Tooltip title={status} color="#52c41a" className="column-actions">
            <DollarTwoTone twoToneColor="#52c41a" />
          </Tooltip>
        );
    }
  };

  const IconDealDisbursementStatus = (status: string) => {
    switch (status) {
      case 'Partially Disbursed':
        return (
          <Tooltip title={status} color="#f90" className="column-actions">
            <RocketTwoTone twoToneColor="#f90" />
          </Tooltip>
        );
      case 'Not Disbursed':
        return (
          <Tooltip title={status} color="#ff0000" className="column-actions">
            <RocketTwoTone twoToneColor="#ff0000" />
          </Tooltip>
        );
      case 'Fully Disbursed':
        return (
          <Tooltip title={status} color="#52c41a" className="column-actions">
            <RocketTwoTone twoToneColor="#52c41a" />
          </Tooltip>
        );
    }
  };

  const columns: ColumnsType<Deal> = [
    {
      title: size[0] > 768 ? getResource('Table_TH_DealNo') : getResource('Deal'),
      dataIndex: 'DealID',
      key: 'deal_dealid',
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.DealID - b.DealID
    },
    {
      title: getResource('Table_TH_Type'),
      dataIndex: 'ForwardType',
      key: 'deal_forwardType',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) => a.ForwardType!.localeCompare(b.ForwardType!)
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_ValueFarDate') : getResource('Table_TH_Date'),
      dataIndex: 'ValueDate',
      key: 'deal_valuedate',
      align: 'center',
      className: 'cell-date',
      sorter: (a, b) => new Date(a.ValueDate).getTime() - new Date(b.ValueDate).getTime()
    },
    {
      title: getResource('Table_TH_IssueDate'),
      dataIndex: 'IssuedDate',
      key: 'deal_issuedDate',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) => new Date(a.IssuedDate).getTime() - new Date(b.IssuedDate).getTime()
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_BuyAmount') : getResource('Label.Buy'),
      dataIndex: 'SellCurrency',
      key: 'deal_buyamount',
      align: 'right',
      sorter: (a, b) => a.SellAmount! - b.SellAmount!,
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.SellAmount, obj.SellCurrency)} {obj.SellCurrency}
        </span>
      )
    },
    {
      title: getResource('Table_TH_Rate'),
      dataIndex: 'CustomerRate',
      key: 'deal_customerrate',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) => a.CustomerRate! - b.CustomerRate!,
      render: (value: any, obj: any, index: any) => <span>{obj.CustomerRate}</span>
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_SellAmount') : getResource('Label.Sell'),
      dataIndex: 'BuyCurrency',
      key: 'deal_sellamount',
      align: 'right',
      sorter: (a, b) => a.BuyAmount! - b.BuyAmount!,
      render: (value: any, obj: any, index: any) => (
        <span>
          {formatToCurrency(obj.BuyAmount, obj.BuyCurrency)} {obj.BuyCurrency}
        </span>
      )
    },
    {
      title: getResource('Table_TH_Status'),
      dataIndex: 'Status',
      key: 'Status',
      align: 'center',
      responsive: ['md'],
      render: (value: any, obj: any, index: any) => (
        <div className="column-actions">
          {IconDealStatus(obj.Status)}
          {IconDealPaidStatus(obj.DealPaidStatus)}
          {IconDealDisbursementStatus(obj.DealDisbursementStatus)}
        </div>
      )
    },
    {
      title: size[0] > 768 ? getResource('Table_TH_Actions') : "",
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('DownloadConfirmation')}>
            <Typography.Link
              className="column-actions"
              onClick={
                async () => {
                  showLoadingModal();
                await getConfirmation(obj.DealID).then((value) => {
                  const httpStatusCode = value.httpStatusCode;
                  switch (httpStatusCode) {
                    case 200:
                      if (value != null && value.data != null) {
                        let fileName = 'Deal Confirmation ' + obj.DealID + '.pdf';
                        var byteCharacters = atob(value.data);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                          byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var bytes = new Uint8Array(byteNumbers);
                        var blob = new Blob([bytes], {
                          type: mime.lookup(fileName) as string
                        });
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                      }
                      break;
                    case 500:
                    case 503:
                      showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + value.Message);
                      break;
                    default:
                      showModal(getResource('Js_MessageError_TitleFormValidation'), getResource('SomethingWentWrong'));
                      break;
                  }
                  hideLoadingModal();
                });
              }}
              disabled={obj.Status !== 'Accepted'}
            >
              <DownloadOutlined />
            </Typography.Link>
          </Tooltip>
          <Tooltip title={getResource('Js_Link_ViewDeal')}>
            <Typography.Link
              className="column-actions"
              onClick={() => {
                setShowNewForward(true);
                setDealID(obj.DealID);
                setCurrentScreen(2);
              }}
            >
              <ZoomInOutlined />
            </Typography.Link>
          </Tooltip>
          {(//obj.Status !== 'Pending for Approval' && 
            obj.Status !== 'Voided' && (obj.OutstandingBalance > 0 || (obj.BeneficiaryPayments != null && obj.DealDisbursementStatus !== 'Fully Disbursed'))) && (
            <>
              {((obj.ForwardType === 'Open' && OL_DrawdownAddNew) || (obj.ForwardType === 'Closed' && OL_Disburse))
                && (<Tooltip title={obj.DealType === 'Forward' && (obj.ForwardType != null && obj.ForwardType === 'Open') ? getResource('Js.Link.Drawdown') : getResource('Js.Link.Disburse')}>
                <Typography.Link
                  className="column-actions"
                  onClick={obj.DealType === 'Forward' && (obj.ForwardType != null) ?
                    () => {
                      setShowNewForward(true);
                      setDealID(obj.DealID);
                      setCurrentScreen(1);
                    } :
                    () => {
                      //props.selectToDisburse(obj);
                    }}
                >
                  <DollarOutlined />
                </Typography.Link>
              </Tooltip>
                )}
            </>
          )}
        </>
      )
    }
  ];

  return (
    <>
      {!showNewForward && <div className="">
        <div className="table-container">
        <div className="row-actions-1">
          <DownloadTableRows data={forwards} fileName={'forwards'} />
        </div>
        <Table
          className=""
          key={'transactions-table'}
          columns={columns}
          dataSource={forwards}

        />
        </div>
        <div className="row-actions">
          {OL_ForwardAddNew &&
            <Button
              type={'primary'}
              onClick={() => {
                setShowNewForward(true);
                setCurrentScreen(0);
              }}
            >{getResource('AddNewForward')}</Button>
          }
        </div>
      </div>}
      {showNewForward && <React.Fragment>
        <div className="screen-container">
          <Steps current={currentScreen}>
            <Step title={getResource('Btn_GetQuote')} />
            {currentScreen === 1 && <Step title={'Disburse'} />}
            <Step title={getResource('ViewPrintSummary')} />
        </Steps>
        </div>
        {currentScreen === 0 && <Forward
          onAcceptTrade={onAcceptForward}
          dealObj={fromOtherScreenDealObj}
          fromOtherScreen={fromOtherScreen}
          forwardTypes={lookUpTables.ForwardTypes}
          objOrderScreen={props.objOrderScreen}
          onBack={() => { setForwards([]); setUpdating(!updating); setShowNewForward(false); }}
        />}
        {currentScreen === 1 && <DrawdownMultipleBeneficiaries
          dealHeaderID={dealID}
          onExit={() => { setCurrentScreen(0); setDealID(0) }}
          onFinish={(dealID: number) => { setCurrentScreen(2); setDealID(dealID); }}
        />}
        {currentScreen === 2 && <Summary
          dealHeaderID={dealID}
          useNewTradeButton={true}
          onClickNewTrade={onNewTradeClick}
          useDepositRequired={true}
          useConfirmationButton={true}
          showExitButton={true}
          onClickExit={() => { setForwards([]); setUpdating(!updating); setShowNewForward(false); }}
        />}
      </React.Fragment>}
    </>
  );
}

export default ForwardContainer;