import { DeleteTwoTone, FilterFilled, FilterOutlined, ReloadOutlined } from '@ant-design/icons';
import { Collapse, Divider, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { searchCustomerMSB } from '../../api/NetworkingCustomers';
import { deleteTransactionUpload, searchTransactionUpload } from '../../api/NetworkingTransactionUpload';
import { getResource } from '../../helpers/ResourcesHelper';
import Button from '../inputs/Button';
import DatePicker from '../inputs/DatePicker';
import { UploadTransactionFile } from '../models/UploadTransactionFile';
import DownloadTableRows from '../shared/DownloadTableRows';
import ModalFilter from '../shared/ModalFilter';

const { Panel } = Collapse;

const UploadFileTable = (props: any) => {
  const { showModal, showLoadingModal, hideLoadingModal } = useContext(AppContext);
  const [transactions, setTransactions] = useState<UploadTransactionFile[]>([]);
  const [updating, setUpdating] = useState<boolean>(false);
  const [transactionDate, setTransactionDate] = useState<string>('');
  const [momentTransactionDate, setMomentTransactionDate] = useState<Moment>();
  const [momentFromDate, setMomentFromDate] = useState<Moment>(moment().subtract(6, 'months'));
  const [momentToDate, setMomentToDate] = useState<Moment>(moment());
  const [fromDate, setFromDate] = useState<string>(moment().subtract(6, 'months').format('YYYY/MM/DD'));
  const [toDate, setToDate] = useState<string>(moment().format('YYYY/MM/DD'));
  const [msb, setMsb] = useState<string>('');
  const [msbId, setMsbId] = useState<number>(0);
  const [transactionID, setTransactionID] = useState('');
  const [msbs, setMsbs] = useState([{ label: '', value: '' }]);
  const [uploadTransactionsTemplate, setUploadTransactionsTemplate] = useState([]);
  const [openFilter, setOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    let tempMsb = 0;
    if (msbId !== 0) tempMsb = msbId;
    let tempDate = null;
    if (transactionDate !== '') tempDate = transactionDate;
    let tempFromDate = null;
    if (fromDate !== '') tempFromDate = fromDate;
    let tempToDate = null;
    if (toDate !== '') tempToDate = toDate;

    let dateRangeValid = true;
    if (fromDate !== '' && toDate !== '') {
      if (fromDate > toDate) dateRangeValid = false;
    }

    if (dateRangeValid) {
      let userSession = JSON.parse(localStorage.getItem('UserSession')!);
      searchTransactionUpload(userSession.CustomerID, tempFromDate, tempToDate).then((response) => {
        if (response != null && response.TransactionUpload != null) {
          response.TransactionUpload.forEach((transaction: any) => {
            transaction.key = transaction.Id;
          });
          setTransactions(response.TransactionUpload);
        } else {
          setTransactions([])
        }
      });
    } else {
      showModal(getResource('Title.Warning'), getResource('DateGreaterThanOrEqualToFromDate'));
    }
    /*if (!updating) {
      getCustomerMSB().then((response) => {
        if (response != null && response.msbs != null) {
          setMsbs(response.msbs);
        } else {
          setMsbs([])
        }
      })
    }*/
  }, [updating]);

  useEffect(() => {
    let today = new Date();
    let yearString = today.getFullYear().toString();
    let monthString = (today.getMonth() + 1).toString().padStart(2, '0');
    let dayString = today.getDate().toString().padStart(2, '0');
    let hourString = today.getHours().toString().padStart(2, '0');
    let minuteString = today.getMinutes().toString().padStart(2, '0');
    let timeStamp = yearString + monthString + dayString + hourString + minuteString;

    let newFileName = 'TransactionsTemplate' + timeStamp + ".xlsx";
    //setFileName(newFileName);
    //setUploadTransactionsTemplate(data);
  }, [])

  useEffect(() => {
    if (props.reload) {
      let tempMsb = 0;
      if (msbId !== 0) tempMsb = msbId;
      let tempDate = null;
      if (transactionDate !== '') tempDate = transactionDate;
      let tempFromDate = null;
      if (fromDate !== '') tempFromDate = fromDate;
      let tempToDate = null;
      if (toDate !== '') tempToDate = toDate;

      let dateRangeValid = true;
      if (fromDate !== '' && toDate !== '') {
        if (fromDate > toDate) dateRangeValid = false;
      }

      if (dateRangeValid) {
        let userSession = JSON.parse(localStorage.getItem('UserSession')!);
        searchTransactionUpload(userSession.CustomerID, tempFromDate, tempToDate).then((response) => {
          if (response != null && response.TransactionUpload != null) {
            response.TransactionUpload.forEach((transaction: any) => {
              transaction.key = transaction.Id;
            });
            setTransactions(response.TransactionUpload);
          } else {
            setTransactions([])
          }
        });
      } else {
        showModal(getResource('Title.Warning'), getResource('DateGreaterThanOrEqualToFromDate'));
      }
    }
  }, [props.reload])

  const columns: ColumnsType<UploadTransactionFile> = [
    {
      title: getResource('Table_TH_TransactionDate'),
      dataIndex: 'Date',
      key: 'transaction_valuedate',
      align: 'center',
      className: 'cell-date',
      sorter: (a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime()
      //sorter: (a, b) => a.Date.localeCompare(b.MSB)
    },
    {
      title: getResource('Table_TH_MSB'),
      dataIndex: 'MSB',
      key: 'transaction_msb',
      align: 'center',
      sorter: (a, b) => a.MSB.localeCompare(b.MSB)
    },
    {
      title: getResource('Table_TH_Status'),
      dataIndex: 'Status',
      key: 'transaction_status',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) => a.Status.localeCompare(b.Status)
    },
    {
      title: getResource('Table_TH_FileName'),
      dataIndex: 'FileName',
      key: 'transaction_filename',
      align: 'center',
      sorter: (a, b) => a.FileName.localeCompare(b.FileName)
    },
    {
      title: getResource('Table_TH_RecordsNumber'),
      dataIndex: 'RecordsNumber',
      key: 'transaction_recordsnumber',
      align: 'center',
      responsive: ['md'],
      sorter: (a, b) => a.RecordsNumber.localeCompare(b.RecordsNumber)
    },
    {
      title: getResource('Table_TH_TotalAmount'),
      dataIndex: 'TotalAmount',
      key: 'transaction_totalamount',
      align: 'center',
      sorter: (a, b) => a.TotalAmount.localeCompare(b.TotalAmount)
    },
    {
      title: getResource('Table_TH_Actions'),
      dataIndex: 'actions',
      key: 'actions',
      align: 'left',
      render: (value: any, obj: any, index: any) => (
        <>
          <Tooltip title={getResource('Label.Refresh')}>
            <Typography.Link
              className="column-actions"
              onClick={() => {
                selectRow(obj);
              }}
            >
              <ReloadOutlined />
            </Typography.Link>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title={getResource('Delete')}>
            <Typography.Link
              className="column-actions"
              onClick={
                async () => {
                  if (obj.Status === 'Pending') {
                    showLoadingModal();
                    await deleteTransactionUpload(obj.Id).then((value) => {
                      const httpStatusCode = value.httpStatusCode;
                      switch (httpStatusCode) {
                        case 200:
                          setUpdating(true);
                          showModal(getResource('Js_MessageError_TitleSuccess'), getResource('Js_Message_DeleteSuccessfully'));
                          break;
                        case 500:
                        case 503:
                          showModal(getResource('Js_MessageError_TitleError'), getResource('InternalServerError') + value.Message);
                          break;
                      }
                      hideLoadingModal();
                    });
                  } else {
                    showModal(getResource('Title.Warning'), getResource('Js_Message_WarningPendingRecords'));
                  }
                }}
            >
              <DeleteTwoTone />
            </Typography.Link>
          </Tooltip>
        </>
      )
    }
  ];

  const selectRow = (record: any) => {
    props.selectRow(record.Id);
  };

  const onResetFilters = () => {
    setTransactionID('');
    //setTransactionDate('');
    setFromDate(moment().subtract(6, 'months').format('YYYY/MM/DD'));
    setMomentFromDate(moment().subtract(6, 'months'));
    setToDate(moment().format('YYYY/MM/DD'));
    setMomentToDate(moment());

    setMsb('');
    setMsbId(0);
    setUpdating(!updating);
  };

  const onChangeTransactionDate = (date: Moment | null, dateString: string) => {
    setTransactionDate(dateString);
    setMomentTransactionDate(date as Moment);
  };

  const onChangeFromDate = (date: Moment | null, dateString: string) => {
    setFromDate(dateString);
    setMomentFromDate(date as Moment);
  };

  const onChangeToDate = (date: Moment | null, dateString: string) => {
    setToDate(dateString);
    setMomentToDate(date as Moment);
  };

  const msbChangeHandler = (item: any, option: any) => {
    if (option != null && !option.label.includes('Select')) {
      setMsbId(option.value);
      setMsb(option.label);
    }
  }

  const onSearch = (value: any) => {
    setMsb(value);
    searchCustomerMSB(value).then(
      (json) => {
        if (json.customers != null) {
          setMsbs(json.customers);
        }
      }
    )
  }

  const onChange = (data: string, option: any) => {
    setMsb(data);
  };


  const closeFilter = () => {
    setOpenFilter(false);
  }

  const filtered = momentFromDate || momentToDate

  const filterOptions = <>
    {!momentFromDate && !momentToDate && getResource('Title.FilterOptions')}
    {momentFromDate && <span className="input-label">{getResource('DateFrom')}: {fromDate}</span>}
    {momentToDate && <span className="input-label">{getResource('DateTo')}: {toDate}</span>}
  </>

  const filter = <>
    <div >

      <div>
        <div className="row">
          <span className="input-label">{getResource('DateFrom')}:</span>
        </div>
        <div className="row">
          <DatePicker
            id="uploadfile-datepicker-fromdate"
            key="uploadfile-datepicker-fromdate"
            onChange={onChangeFromDate}
            value={momentFromDate}
            submit={true}
            errorMessage={getResource('DataMsgRequired')}
            valueValidation={fromDate}
          />
        </div>
      </div>

      <div>
        <div className="row">
          <span className="input-label">{getResource('DateTo')}:</span>
        </div>
        <div className="row">
          <DatePicker
            id="uploadfile-datepicker-todate"
            key="uploadfile-datepicker-todate"
            onChange={onChangeToDate}
            value={momentToDate}
            submit={true}
            errorMessage={getResource('DataMsgRequired')}
            valueValidation={toDate}
          />
        </div>
      </div>


      <div className="row-actions">
          <Button type="secondary" onClick={() => { setUpdating(!updating); closeFilter(); }}>
            {getResource('ApplyFilter')}
        </Button>
          <Button type="primary" onClick={() => { onResetFilters(); closeFilter(); }}>
            {getResource('Reset')}
        </Button>
      </div>
    </div></>

  return (
    <React.Fragment>
      <div className="row-actions-1">

        <DownloadTableRows data={transactions} fileName={'transactions'} />

        <Tooltip title={filterOptions}>
          <Typography.Link onClick={() => setOpenFilter(true)} style={{ marginRight: '10px' }}>
            {filtered && <FilterFilled />}
            {!filtered && <FilterOutlined />}
          </Typography.Link>
        </Tooltip>
      </div>
      <Table
        className=""
        key={'upload-transactions-table'}
        columns={columns}
        dataSource={transactions}
      />
      <ModalFilter title={getResource('Title.FilterOptions')} open={openFilter} close={closeFilter} panel={filter} />
    </React.Fragment>
  );
};

export default UploadFileTable;