import { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../../../App.context';
import useOnlineRights from '../../../api/hooks/useOnlineRights';
import { formatValidDateClass, formatValidDateRangeClass, formatValidInputClass, validInputClass } from '../../../helpers/FormatHelper';
import { OnlineRightsNames } from '../../../helpers/OnlineRightsHelper';
import { getResource } from '../../../helpers/ResourcesHelper';
import DatePicker from '../../inputs/DatePicker';
import Dropdown from '../../inputs/Dropdown';
import Input from '../../inputs/Input';
import RadioButton from '../../inputs/RadioButton';

interface OrderTypeProps {
  validationField: number;
  [x: string]: any;
  //props: any;
}

const moment = require('moment');

const OrderType: React.FC<OrderTypeProps> = ({ validationField, ...props }) => {

  const { showModal } = useContext(AppContext);

  const [updating, setUpdating] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false)

  const [rateWatchId, setRateWatchId] = useState(0);

  const [ratewatchtype, setRateWatchtype] = useState<string>('Order');
  const [status, setStatus] = useState<string>('Outstanding');
  const [goodTillCancelled, setGoodTillCancelled] = useState<string>('true');
  const [expiresOn, setExpiresOn] = useState<string | null>('');
  const [notifyMeVia, setNotifyMeVia] = useState<string>('Email');
  const [dealType, setDealType] = useState<string>('Spot');
  const [fordwardOption, setFordwardOption] = useState<string>('days');
  const [forDays, setForDays] = useState<number | null | string>(null);
  const [forStartDate, setForStartDate] = useState<string | null>('');
  const [forEndDate, setForEndDate] = useState<string | null>('');
  const [dealHeaderID, setDealHeaderID] = useState<number | null | string>(0);

  //rights
  let OL_RatesForwardAddNew = useOnlineRights(OnlineRightsNames.OL_RatesForwardAddNew);
  let OL_RatesForwardEdit = useOnlineRights(OnlineRightsNames.OL_RatesForwardEdit);
  let OL_RatesSpotAddNew = useOnlineRights(OnlineRightsNames.OL_RatesSpotAddNew);
  let OL_RatesSpotEdit = useOnlineRights(OnlineRightsNames.OL_RatesSpotEdit);

  //lookups
  let orderStatus = props.lookupTables.RateWatchStatusNames.filter((x: { value: string; }) => !['Select Rate Watch Status Names...', 'Executed'].includes(x.value))
  let orderStatusExecuted = props.lookupTables.RateWatchStatusNames.filter((x: { value: string; }) => ['Executed'].includes(x.value))
  let orderTypes = props.lookupTables.RateWatchType.filter((x: { value: string; }) => !['Select Rate Watch Type...'].includes(x.value))
  let notificationMethods = props.lookupTables.NotificationMethod.filter((x: { value: string; }) => !['Select a Method...'].includes(x.value))

  const [validations, setValidations] = useState({
    ratewatchtype: true,
    status: true,
    goodTillCancelled: true,
    notifyMeVia: true,
    dealType: true,
    forDays: true,
    forDateRangeValid: true
  });

  const validationsFields = () => {
    var valRatewatchtype = validInputClass(ratewatchtype);
    var valStatus = validInputClass(status);
    var valGoodTillCancelled = true;
    var valNotifyMeVia = validInputClass(notifyMeVia);
    var valDealType = true;
    var valForDays = true;
    var valForDateRangeValid = true;

    if (goodTillCancelled === '') { valGoodTillCancelled = false; }
    if (goodTillCancelled === 'false' && (expiresOn === '' || expiresOn === null)) { valGoodTillCancelled = false; }
    if (dealType === '') { valDealType = false; }
    if (dealType === 'Forward' && fordwardOption === 'days' && (forDays === '' || forDays === 0 || forDays === null || forDays?.toString() == 'NaN')) { valForDays = false; }
    if (dealType === 'Forward' && fordwardOption === 'daterange' && (forStartDate === '' || forStartDate === null || forEndDate === null || forEndDate === '')) { valForDateRangeValid = false; }


    setValidations({
      ratewatchtype: valRatewatchtype,
      status: valStatus,
      goodTillCancelled: valGoodTillCancelled,
      notifyMeVia: valNotifyMeVia,
      dealType: valDealType,
      forDays: valForDays,
      forDateRangeValid: valForDateRangeValid,
    });

    var isValid = valRatewatchtype && valStatus && valGoodTillCancelled && valNotifyMeVia && valDealType && valForDays && valForDateRangeValid;
    if (!isValid) { showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted')); }
    else {
      let mobile = JSON.parse(localStorage.getItem('UserSession')!).MobileNumber;
      if (notifyMeVia.toLowerCase().includes('sms')) {
        if (!mobile || mobile.length === 0) {
          showModal(getResource('Js_MessageError_TitleError'), getResource('Message_Ratewatch_SMS_Validation'));
        }
        else {
            props.onUpdateStep(1);
          }
        }
        else {
          props.onUpdateStep(1);
        }
    }

  }

  useEffect(() => {
    if (validationField != null && validationField > 0) {
      validationsFields();
    }
  }, [validationField])

  useEffect(() => {
    if (props.rateWatch != null) {
      setRateWatchId(props.rateWatch.RateWatchID);
      setRateWatchtype(props.rateWatch.RateWatchType);
      setStatus(props.rateWatch.RateWatchStatus);
      setGoodTillCancelled(props.rateWatch.GoodTillCancelled!.toString());
      setExpiresOn(props.rateWatch.ExpirationDate);
      setNotifyMeVia(props.rateWatch.NotificationMethod);
      setDealType(props.rateWatch.DealTypeID === 1 ? 'Spot' : 'Forward');
      setForDays(props.rateWatch.OpenFor);
      setForStartDate(props.rateWatch.NearDate);
      setForEndDate(props.rateWatch.FarDate);
      if (props.rateWatch.RateWatchStatus == 'Executed' && props.rateWatch.DealHeaderID != null) {
        setDealHeaderID(props.rateWatch.DealHeaderID);
      }
    }
  }, [props.rateWatch]);


  const radioButtonsActions = (event: any, type: string) => {
    let objSetters: { [key: string]: any } = {
      ['goodTillCancelled']: () => {
        setGoodTillCancelled(event.target.value); setExpiresOn('');
        setValidations({ ...validations, goodTillCancelled: true });
        props.onUpdateRateWatch('GoodTillCancelled', event.target.value);
      },
      ['dealType']: () => {
        setDealType(event.target.value);
        let dealtype = event.target.value === 'Spot' ? 1 : 4
        props.onUpdateRateWatch('DealTypeID', dealtype);

        if (event.target.value === 'Spot') {
          setFordwardOption('days');
          setForDays(null);
          setForStartDate('');
          setForEndDate('');
          setValidations({ ...validations, dealType: true });
          setValidations({ ...validations, forDays: true });
          setValidations({ ...validations, forDateRangeValid: true });
          props.onUpdateRateWatch('OpenFor', null);
          props.onUpdateRateWatch('NearDate', '');
          props.onUpdateRateWatch('FarDate', '');
        }
      },
      ['fordwardDays']: () => {

        setFordwardOption(event.target.value);

        if (event.target.value === 'days') {
          setForStartDate('');
          setForEndDate('');
          setValidations({ ...validations, forDays: true });
          setValidations({ ...validations, forDateRangeValid: true });
          props.onUpdateRateWatch('NearDate', '');
          props.onUpdateRateWatch('FarDate', '');
        } else {
          setForDays(0);
        }
      },
    }
    return (objSetters[type]())
  }

  const dropdownsActions = (item: any, type: string) => {
    let objSetters: { [key: string]: any } = {
      ['ratewatchtype']: () => {
        setRateWatchtype(item.value);
        setValidations({ ...validations, ratewatchtype: true });
        props.onUpdateRateWatch('RateWatchType', item.value);
      },
      ['status']: () => {
        setStatus(item.value);
        setValidations({ ...validations, status: true });
        props.onUpdateRateWatch('RateWatchStatus', item.value);
      },
      ['notifymevia']: () => {
        setNotifyMeVia(item.value);
        setValidations({ ...validations, notifyMeVia: true });
        props.onUpdateRateWatch('NotificationMethod', item.value);
      },
    }
    return (objSetters[type]())
  }

  const onChangeOpenFor = (value: number) => {
    setForDays(value);
    setValidations({ ...validations, forDays: true });
    props.onUpdateRateWatch('OpenFor', value);
  }

  const datesAcctions = (date: Moment | null, dateString: string, type: string) => {
    let objSetters: { [key: string]: any } = {
      ['expireson']: () => {
        setExpiresOn(date === null ? '' : date.format('YYYY/MM/DD'))
        setValidations({ ...validations, goodTillCancelled: true });
        props.onUpdateRateWatch('ExpirationDate', date === null ? '' : date.format('YYYY/MM/DD'));
      },
    }
    return (objSetters[type]())
  };

  const datesRangeAcctions = (date: ([Moment, Moment] | null), dateString: [string, string], type: string) => {
    let objSetters: { [key: string]: any } = {
      ['fordwardRange']: () => {
        setForStartDate(date === null ? '' : date[0].format('YYYY/MM/DD'));
        setForEndDate(date === null ? '' : date[1].format('YYYY/MM/DD'));
        setValidations({ ...validations, forDateRangeValid: true });
        props.onUpdateRateWatch('NearDate', date === null ? '' : date[0].format('YYYY/MM/DD'));
        props.onUpdateRateWatch('FarDate', date === null ? '' : date[1].format('YYYY/MM/DD'));
      },
    }
    return (objSetters[type]())
  };

  return (
    <div className='row wizard-container'>
      <div className='col-md-2 col-xs-0'> </div>
      <div className='col-md-8 col-xs-12'>
      {Number(dealHeaderID!) > 0 && <div className="row right-xs">
        <div className="col-md-6 col-xs-6">
          <span>{"Deal ID: " + dealHeaderID}</span>
        </div>
      </div>}
      <div className="row center">
        <div className="col-xs-11 col-md-10 col-lg-8">
          {/*Rate watch type - Status*/}
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Title.RateWatchType')}</span>
              </div>
              <div className="row">
                <Dropdown
                  name="ratewatchtypes"
                  title={getResource('SelectType')}
                  list={orderTypes}
                  onChange={(item: any) => { dropdownsActions(item, 'ratewatchtype') }}
                  select={{ value: ratewatchtype }}
                  submit={saving}
                  errorMessage={getResource('DataMsgRequired')}
                  className={formatValidInputClass(validations.ratewatchtype)}
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.Status')}</span>
              </div>
              <div className="row">
                <Dropdown
                  name="status"
                  title={getResource('SelectStatus')}
                  list={status == 'Executed' ? orderStatusExecuted : orderStatus}
                  onChange={(item: any) => { dropdownsActions(item, 'status') }}
                  select={{ value: status }}
                  submit={saving}
                  errorMessage={getResource('DataMsgRequired')}
                  className={formatValidInputClass(validations.status)}
                />
              </div>
            </div>
          </div>
          {/*Good Till Cancelled + Expires On*/}
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('GoodTillCancelled')}</span>
              </div>
              <div className="row">
                <div className='start col-md-3 col-xs-3 padding-top-5' style={{ marginLeft: '10px' }}>
                  <RadioButton
                    changed={(event: any) => { radioButtonsActions(event, "goodTillCancelled") }}
                    id='radiobutton-yes'
                    label={getResource('Yes')}
                    value="true"
                    isSelected={goodTillCancelled == 'true'}
                    className="start RadioButton"
                  />
                </div>
                <div className='start col-md-3 col-xs-3 padding-top-5'>
                  <RadioButton
                    changed={(event: any) => { radioButtonsActions(event, "goodTillCancelled") }}
                    id='radiobutton-no'
                    label={getResource('No')}
                    value="false"
                    isSelected={goodTillCancelled == 'false'}
                    className="start RadioButton"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Table_TH_Expires')}</span>
              </div>
              <div className="row va-top">
                <DatePicker
                  id="addedditratewatch-datepicker-expireson"
                  key="addedditratewatch-datepicker-expireson"
                  onChange={(date: Moment | null, dateString: string) => { datesAcctions(date, dateString, 'expireson') }}
                  //value     = {moment(expiresOn, dateFormat)}
                  disabled={goodTillCancelled != 'false'}
                  className={formatValidDateClass(validations.goodTillCancelled)}
                />
              </div>
            </div>

          </div>
          {/*Notify me via*/}
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('NotifyMeVia')}</span>
              </div>
              <div className="row">
                <Dropdown
                  name="notifymevia"
                  title={getResource('SelectDeliveryMethod')}
                  list={notificationMethods}
                  onChange={(item: any) => { dropdownsActions(item, 'notifymevia') }}
                  select={{ value: notifyMeVia }}
                  submit={saving}
                  errorMessage={getResource('DataMsgRequired')}
                  className={formatValidInputClass(validations.notifyMeVia)}
                />
              </div>
            </div>
          </div>
          {/*Deal Type*/}
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Table_TH_DealType')}</span>
              </div>
              <div className="row">
                <div className="col-md-1°5 col-xs-3 padding-top-5" style={{ marginLeft: '10px' }}>
                  {(OL_RatesSpotAddNew || OL_RatesSpotEdit) && <RadioButton
                    changed={(event: any) => { radioButtonsActions(event, "dealType") }}
                    id='radiobutton-spot'
                    label={getResource('Title.Spot')}
                    value="Spot"
                    isSelected={dealType == 'Spot'}
                    className="start RadioButton"
                  />}
                </div>
              <div className="col-md-2 col-xs-3 padding-top-5">
                  {(OL_RatesForwardAddNew || OL_RatesForwardEdit) && <RadioButton
                    changed={(event: any) => { radioButtonsActions(event, "dealType") }}
                    id='radiobutton-fordward'
                    label={getResource('Label.Forward')}
                    value="Forward"
                    isSelected={dealType == 'Forward'}
                    className="start RadioButton"
                />}
              </div>
            </div>
          </div>
          {/*Fordward Options: Open For/ Range*/}
            <div className="col-md-12 col-xs-12">
          <div className="row" style={dealType != 'Forward' ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
            {/*Open For*/}
                <div className="col-md-1°5 col-xs-3 padding-top-5" style={{ marginLeft: '10px' }}></div>
                <div className="col-md-2 col-xs-4" >
                <RadioButton
                  changed={(event: any) => { radioButtonsActions(event, "fordwardDays") }}
                  id='radiobutton-fordward-days'
                  label={getResource('Label.OpenFor')}
                  value="days"
                  isSelected={fordwardOption === 'days'}
                  className={'RadioButton start'}
                />
              </div>
            <div className="col-md-3 col-xs-4">
                <div className="row">
                  <Input
                    type='number'
                    disabled={fordwardOption !== 'days'}
                    onValueChange={(value: number) => onChangeOpenFor(value)}
                    value={forDays === null ? '' : forDays}
                    submit={fordwardOption === 'days' && saving}
                    errorMessage={getResource('DataMsgRequired')}
                    decimalScale={0}
                    className={formatValidInputClass(validations.forDays)}
                  />
                </div>
              </div>
            <div className="col-md-1 col-xs-4">
              <span className='input-label start-xs'>{getResource('Days')}  </span>
            </div>
          </div>
            </div>
          {/*Range*/}
            <div className="col-md-12 col-xs-12">
          <div className="row" style={dealType != 'Forward' ? { pointerEvents: 'none', opacity: '0.4' } : {}}>
                <div className="col-md-1°5 col-xs-3 padding-top-5" style={{ marginLeft: '10px' }}></div>
                <div className="col-md-2 col-xs-3">
              <RadioButton
                changed={(event: any) => { radioButtonsActions(event, "fordwardDays") }}
                id='radiobutton-fordward-daterange'
                label={getResource('Label.Range')}
                value="daterange"
                isSelected={fordwardOption === 'daterange'}
                className={'start RadioButton'}
              />
            </div>
            <div className="col-md-8 col-xs-6">
              <DatePicker
                id="addedditratewatch-datepicker-rangeFordward"
                key="addedditratewatch-datepicker-rangeFordward"
                isRange
                onChangeRange={(date: [Moment, Moment] | null, dateString: [string, string]) => { datesRangeAcctions(date, dateString, 'fordwardRange') }}
                disabledRange={[fordwardOption !== 'daterange', fordwardOption !== 'daterange']}
                className={formatValidDateRangeClass(validations.forDateRangeValid)}
              //valueRange    = {[moment(forStartDate), moment(forEndDate)]}
              />
            </div>
          </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className='col-md-2 col-xs-0'> </div>
    </div>
  );
}

export default OrderType