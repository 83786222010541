import { DownloadOutlined } from "@ant-design/icons";
import { Tooltip, Typography } from "antd";
import useWindowSize from "../../api/hooks/useWindowResize";
import { getResource } from "../../helpers/ResourcesHelper";

export function downloadData(data: any, fileName: string) {

  let csvContent = "data:text/plain;charset=utf-8,";
  let csvDataAsRows = data
  if (csvDataAsRows && csvDataAsRows.length > 0) {
    let header = [...Object.keys(csvDataAsRows[0])];
    for (let i = 0; i < csvDataAsRows.length; i++) {
      delete csvDataAsRows[i].Action;
      delete csvDataAsRows[i].Key;

      for (let j = 0; j < header.length; j++) {
        if (header[j].includes('$')) {
          delete csvDataAsRows[i][header[j]];
        }
      }
    }
    let encodedUri = '';
    let link: HTMLElement;

    csvDataAsRows = data != null ?
      data
        .map((e: any) => Object.values(e))
        .reduce(function (csvString: any, row: any) {
          csvString += "\r\n";
          csvString += row.join(',');
          return csvString;
        }) : [];
    let today = new Date();
    let yearString = today.getFullYear().toString();
    let monthString = (today.getMonth() + 1).toString().padStart(2, '0');
    let dayString = today.getDate().toString().padStart(2, '0');
    let hourString = today.getHours().toString().padStart(2, '0');
    let minuteString = today.getMinutes().toString().padStart(2, '0');
    let timeStamp = yearString + monthString + dayString + hourString + minuteString;

    let newFileName = (fileName ?? 'data_') + timeStamp + ".csv";

    encodedUri = encodeURI(csvContent + header + "\r\n" + csvDataAsRows);
    link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", newFileName);
    link.click();
    document.body.appendChild(link);
  }
}

const DownloadTableRows = (props: any) => {

  const size = useWindowSize();
  return <>
    <Tooltip title={getResource('Message_ExportToExcel')}>
      <Typography.Link
        className="column-actions"
        onClick={() => {
          downloadData(props.data, props.fileName)
        }}
      >
        <DownloadOutlined style={{ fontSize: 18, visibility: (size[0] > 768) ? "visible" : "hidden" }} />
      </Typography.Link>
    </Tooltip>
  </>
}

export default DownloadTableRows;