import { AutoComplete } from 'antd';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../App.context';
import { getAllCountries, getCountryByName } from '../../api/NetworkingCountries';
import { getStatesByCountryMin } from '../../api/NetworkingStates';
import { LookUpTableDetail } from '../../api/hooks/useLookUpTables';
import { formatValidInputClass, validInputClass } from '../../helpers/FormatHelper';
import { getResource } from '../../helpers/ResourcesHelper';
import Dropdown from '../inputs/Dropdown';
import Input from '../inputs/Input';
interface BeneProps {
  beneFields: number;
  [x: string]: any;
}
const BeneficiayInformation: React.FC<BeneProps> = ({ beneFields, ...props }) => {
  const { showModal } = useContext(AppContext);
  const [beneficiaryID, setBeneficiaryID] = useState(0);
  const [beneficiaryType, setBeneficiaryType] = useState('');
  const [beneficiaryName, setBeneficiaryName] = useState('');
  const [beneficiaryFirstName, setBeneficiaryFirstName] = useState('');
  const [beneficiaryLastName, setBeneficiaryLastName] = useState('');
  const [beneficiaryShortName, setBeneficiaryShortName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [status, setStatus] = useState('');
  const [city, setCity] = useState('');
  const [countryID, setCountryID] = useState('');
  const [countryIDName, setCountryIDName] = useState('');
  const [stateID, setStateID] = useState(0);
  const [stateName, setStateName] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [load, setLoad] = useState(true);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState([]);

  const [validations, setValidations] = useState({
    beneficiaryType: true,
    beneficiaryFirstName: true,
    beneficiaryName: true,
    beneficiaryLastName: true,
    addressLine1: true,
    stateID: true,
    countryID: true
  });

  const validationsFields = () => {
    var type = validInputClass(beneficiaryType);
    var firstName = validInputClass(beneficiaryFirstName);
    var name = validInputClass(beneficiaryName);
    var lastName = validInputClass(beneficiaryLastName);
    var address = validInputClass(addressLine1);
    var country = validInputClass(countryID);
    var state = validInputClass(stateID.toString());
    setValidations({
      beneficiaryType: type,
      beneficiaryFirstName: firstName,
      beneficiaryName: name,
      beneficiaryLastName: lastName,
      addressLine1: address,
      countryID: country,
      stateID: state
    });
    var nameValid = (beneficiaryType == 'Personal') ? firstName && lastName : name;
    var isValid = type && nameValid && address && country && state;
    if (!isValid) { showModal(getResource('Js_MessageError_TitleError'), getResource('Js_MessageError_FormValidationNFieldNHighlighted')); }
    else {
      props.onUpdateStep(1);
    }
  }
  useEffect(() => {
    const loadCountries = async () => {
      const json = await getAllCountries();
      if (json != null && json.countries != null) {
        console.log(json);
        const formattedCountries = json.countries.map((c: any) => ({ ...c, label: `${c.Country} - ${c['Country Name']}`, value: c.Country }));
        formattedCountries.push({ label: getResource('SelectCountry'), value: '' });
        setCountries(formattedCountries);
      } else {
        setCountries(props.countries);
      }
    }
    loadCountries();
  }, [props.countries]);

  useEffect(() => {
    const loadStates = async () => {
      const json = await getStatesByCountryMin(props.beneficiary.CountryID);
      if (json != null && json.states != null) {
        const formattedStates = json.states.map((x: any) => ({ label: x['State Name'], value: x['State ID'] }));
        //let state = formattedStates.find((x: typeof states) => x.value === stateID);
        setStates(formattedStates);
      } else {
        setStates([]);
      }
      if (props.beneficiary.CountryID !== '' && props.beneficiary.CountryName != null && props.beneficiary.CountryName !== '') setCountryID(props.beneficiary.CountryID + ' - ' + props.beneficiary.CountryName);
    }
    loadStates();
  }, [props.beneficiary.CountryID])

  useEffect(() => {
    if (props.beneficiary != null) {
      setBeneficiaryID(props.beneficiary.BeneficiaryID);
      if (props.beneficiary.CountryID !== '' && props.beneficiary.CountryName != null && props.beneficiary.CountryName !== '') setCountryID(props.beneficiary.CountryID + ' - ' + props.beneficiary.CountryName);
      setBeneficiaryType(props.beneficiary.BeneficiaryType);
      setBeneficiaryName(props.beneficiary.BeneficiaryName);
      setBeneficiaryFirstName(props.beneficiary.BeneficiaryFirstName);
      setBeneficiaryLastName(props.beneficiary.BeneficiaryLastName);
      setBeneficiaryShortName(props.beneficiary.BeneficiaryShortName);
      setAddressLine1(props.beneficiary.AddressLine1);
      setAddressLine2(props.beneficiary.AddressLine2);
      setCity(props.beneficiary.City);
      setPostalCode(props.beneficiary.PostalCode);
      setStateID(props.beneficiary.StateID);
      setStateName(props.beneficiary.StateName);
      setStatus(props.beneficiary.Status);
    }
  }, [props.beneficiary]);

  useEffect(() => {
    if (beneFields != null && beneFields > 0) {
      validationsFields();
    }
  }, [beneFields]);

  const updateStates = (countryID: string) =>
  {
    getStatesByCountryMin(countryID).then(response => {
    
      if (response != null && response.httpStatusCode === 200 && response.states != null) {
          let states = response.states
          states = states.map((x: typeof states) => ({ label: x['State Name'], value: x['State ID'] }));
        let state = states.find((x: typeof states) => x.value == stateID);
        if (state == null) {
          setStateID(0);
          setStateName('');
        }
        setStates(states);
      }
  });
  }

  const beneficiaryTypeChangeHandler = (detail: LookUpTableDetail) => {
    if (detail != null) {
      setBeneficiaryType(detail.value);
      setValidations({ ...validations, beneficiaryType: true });
      props.onUpdateBeneficiary('BeneficiaryType', detail.value);
    }
  };

  const countryChangeHandler = (item: any, option: any) => {
    if (option != null && !option.label.includes('Select')) {
      setCountryID(option.label);
      props.onUpdateBeneficiary('CountryID', item);
      props.onUpdateBeneficiary('CountryName', option['Country Name']);
      setValidations({ ...validations, countryID: true });
      updateStates(item);
      if (!load) {
        //setStateID(0);
      } else {
        setLoad(false);
      }
    }
  }

  const onSearch = (value: any) => {
    setCountryID(value);
    let newValue = value.split(' -');
    if (value === '') setStates([]);
    getCountryByName(newValue[0]).then(
      (json) => {
        if (json.countries != null) {
          const formattedCountries = json.countries.map((c: any) => ({ ...c, label: c.CountryIdName, value: c.CountryId }));
          //formattedCountries.push({ label: 'Select a Country...', value: '' });
          setCountries(formattedCountries);
        }
      }
    )
  }

const stateChangeHandler = (item: any, name: string) => {
    if(item != null)
    {
        setStateID(item.value);
      props.onUpdateBeneficiary('StateID', item.value);
      setValidations({ ...validations, stateID: true });
    }
}

const onChangeName = (e: any) =>
{ 
    setBeneficiaryName(e.target.value);
  setValidations({ ...validations, beneficiaryName: true });
    props.onUpdateBeneficiary('BeneficiaryName', e.target.value);
}

const onChangeFirstName = (e: any) =>
{ 
    setBeneficiaryFirstName(e.target.value);
  setValidations({ ...validations, beneficiaryFirstName: true });
    props.onUpdateBeneficiary('BeneficiaryFirstName', e.target.value);
}

const onChangeLastName = (e: any) =>
{ 
    setBeneficiaryLastName(e.target.value);
  setValidations({ ...validations, beneficiaryLastName: true });
    props.onUpdateBeneficiary('BeneficiaryLastName', e.target.value);
}

const onChangeShortName = (e: any) =>
{ 
    setBeneficiaryShortName(e.target.value);
    props.onUpdateBeneficiary('BeneficiaryShortName', e.target.value);
}

const onChangeAddress1 = (e: any) =>
{ 
    setAddressLine1(e.target.value);
  setValidations({ ...validations, addressLine1: true });
    props.onUpdateBeneficiary('AddressLine1', e.target.value);
}

const onChangeAddress2 = (e: any) =>
{ 
    setAddressLine2(e.target.value);
    props.onUpdateBeneficiary('AddressLine2', e.target.value);
}

const onChangeCity= (e: any) =>
{ 
    setCity(e.target.value);
    props.onUpdateBeneficiary('City', e.target.value);
}

const onChangePostalCode = (e: any) =>
{ 
    setPostalCode(e.target.value);
    props.onUpdateBeneficiary('PostalCode', e.target.value);
}

    return (
      <div className={props.canEdit ? '' : 'readonly'}>
        <div className="row">
          <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.BeneficiaryType') + '(*)'}</span>
              </div>
              <div className="row">
                <Dropdown
                  name="beneficiaryType"
                  title={getResource('SelectType') + '...'}
                  list={props.beneficiaryTypes.filter((x: { value: string; }) => !['Select a Type...'].includes(x.value))}
                  select={{ value: beneficiaryType }}
                  errorMessage={getResource('DataMsgRequired')}
                  className={formatValidInputClass(validations.beneficiaryType)}
                  onChange={beneficiaryTypeChangeHandler}
                />
              </div>
            </div>
          <div className="col-md-6 col-xs-12">
            {beneficiaryID > 0 && <>
              <div className="row">
                <span className="input-label">{getResource('Label.Status')}</span>
              </div>
              <div className="row readonly">
                <Input type="text" value={status} ></Input>
              </div>
            </>}

          </div>
          </div>
          {beneficiaryType !== 'Corporate' && (
          <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.FirstName') + '(*)'}</span>
                </div>
                <div className="row">
                  <Input type="text" onChange={onChangeFirstName} value={beneficiaryFirstName} errorMessage={getResource('DataMsgRequired')}
                    className={formatValidInputClass(validations.beneficiaryFirstName)}></Input>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Label.LastName') + '(*)'}</span>
                </div>
                <div className="row">
                  <Input type="text" onChange={onChangeLastName} value={beneficiaryLastName} errorMessage={getResource('DataMsgRequired')}
                    className={formatValidInputClass(validations.beneficiaryLastName)}></Input>
                </div>
              </div>
            </div>
          )}
          {beneficiaryType === 'Corporate' && (
          <div className="row">
              <div className="col-md-6 col-xs-12">
                <div className="row">
                  <span className="input-label">{getResource('Js_TH_Name') + '(*)'}</span>
                </div>
                <div className="row">
                  <Input type="text" onChange={onChangeName} value={beneficiaryName} errorMessage={getResource('DataMsgRequired')}
                    className={formatValidInputClass(validations.beneficiaryName)}></Input>
                </div>
              </div>
            </div>
          )}
        <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.ShortName')}</span>
              </div>
              <div className="row">
                <Input type="text" onChange={onChangeShortName} value={beneficiaryShortName}></Input>
              </div>
            </div>
          </div>
        <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.Address1') + '(*)'}</span>
              </div>
              <div className="row">
                <Input type="text" onChange={onChangeAddress1} value={addressLine1} errorMessage={getResource('DataMsgRequired')}
                  className={formatValidInputClass(validations.addressLine1)}></Input>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.Address2')}</span>
              </div>
              <div className="row">
                <Input type="text" onChange={onChangeAddress2} value={addressLine2}></Input>
              </div>
            </div>
          </div>
        <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.City')}</span>
              </div>
              <div className="row">
                <Input type="text" onChange={onChangeCity} value={city}></Input>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.Country') + '(*)'}</span>
              </div>
              <div className="row">                
                <AutoComplete
                  allowClear
                  options={countries}
                  className={formatValidInputClass(validations.countryID)}
                  placeholder={getResource('SelectCountry')}
                  notFoundContent={getResource('PlaceHolder.NoResults')}
                  value={countryID}
                  onSelect={countryChangeHandler}
                  onSearch={onSearch}
                />
              </div>
            </div>
          </div>
        <div className="row">
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.ProvinceState') + '(*)'}</span>
              </div>
              <div className="row">
                <Dropdown name="state" title={getResource('SelectState')} list={states}
                  select={{ value: stateID }} onChange={stateChangeHandler}
                  errorMessage={getResource('DataMsgRequired')}
                  className={formatValidInputClass(validations.stateID)}
                />
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <div className="row">
                <span className="input-label">{getResource('Label.PostalZip')}</span>
              </div>
              <div className="row">
              <Input type="text" onChange={onChangePostalCode} value={postalCode}></Input>
            </div>
          </div>
        </div>
      </div>
    );
}
export default BeneficiayInformation;