import { Modal } from 'antd';
import { useContext, useState } from 'react';
import { sendPendingForApprovalEmail } from '../../api/NetworkingApprovals';
import AppContext from '../../App.context';
import Button from '../../components/inputs/Button';
import Dropdown from '../../components/inputs/Dropdown';
import { getResource } from '../../helpers/ResourcesHelper';

const ApprovalsNotification = (props: any) => {
  const { showModal } = useContext(AppContext);
  const [approverName, setApproverName] = useState('');
  const [approverEmail, setApproverEmail] = useState('');
  const [confirmationShowAlert, setConfirmationShowAlert] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [sucessModal, setSucessModal] = useState(false);
  const onClose = () => {
    props.onClose();
  };

  const approverChangeHandler = (detail: any) => {
    if (detail != null) {
      setApproverEmail(detail.value);
      setApproverName(detail.label);
    }
  };

  const onSendRequestForApproval = () => {
    if (approverName) {
      setConfirmationMessage(getResource('Js_Message_ConfirmSendMailApproval2') + approverName);
      setConfirmationShowAlert(true);
    }
  };

  const onCloseConfirmation = () => {
    setConfirmationShowAlert(false);
    props.onCloseDetails(true);
    setSucessModal(false);
  };

  const onOkConfirmation = () => {

    let emailTo = []

    if (approverName === 'All') {
      emailTo = props.contactsAbleToApprove.filter((x: any) => x.value !== 'All').map((x: any) => { return { 'Email': x.value, 'Name': x.label } })
    } else {
      let approver = props.contactsAbleToApprove.filter((x: any) => x.value === approverEmail)[0]
      emailTo.push({ 'Email': approver.value, 'Name': approver.label })
    }

    var userSession = JSON.parse(localStorage.getItem('UserSession')!);

    let approvalRequest = {
      InstallationID: userSession.InstallationID,
      FromEmail: userSession.UserName,
      FromName: userSession.ContactName,
      To: emailTo,
      EntityType: props.entityType,
      EntityName: props.entityName
    }
    sendPendingForApprovalEmail(approvalRequest)
      .then((response) => {
        if (response !== undefined && response !== null) {
          if (response.httpStatusCode === 200) {
            if (response.error === null || response.error === '') {
              //showModal(getResource('Js_Message_SubmitSucessful'), getResource('Js_Message_SaveSuccessfully'));
              setSucessModal(true);
            } else {
              showModal(getResource('Js_MessageError_TitleSubmit'), response.error);
            }
          } else if (response.httpStatusCode >= 500) {
            showModal(getResource('Js_MessageError_TitleSubmit'), getResource('InternalServerError') + response.Message);
          } else {
            showModal(getResource('Js_MessageError_TitleSubmit'), getResource('SomethingWentWrong'));
          }
        } else {
          showModal(getResource('Js_MessageError_TitleSubmit'), getResource('SomethingWentWrong'));
        }
      });
    setConfirmationShowAlert(false);

  };

  return (
    <div>
      <Modal title={<h3 className="component-title">{props.Title}</h3>} centered open={props.showApprovalsNotification} footer={null} onCancel={onClose} maskClosable={false} zIndex={1000}>
        <div>
          <div>
            <h4>{getResource('RequestApprovalTo')}:</h4>
            <Dropdown
              name="request-approval-list-save-for-approval"
              title={getResource('PlaceHolder.SelectMail')}
              list={props.contactsAbleToApprove}
              select={{ value: approverEmail }}
              onChange={approverChangeHandler}
            ></Dropdown>
          </div>
          <div className="row-actions-w">
            <Button type="primary" onClick={() => onSendRequestForApproval()}>
              {getResource('Button.Send')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal title={<h3 className="component-title">{getResource('Js_Message_TittleConfirmSendMailApprovalAll')}</h3>} centered open={confirmationShowAlert} footer={null} onCancel={onCloseConfirmation} maskClosable={false} zIndex={1010}>
        <div>
          <div>
            <h4>{confirmationMessage}</h4>
          </div>
          <div className="row-actions-w">
            <Button type="primary" onClick={() => onOkConfirmation()}>
              {getResource('Button.Ok')}
            </Button>
            <Button type="primary" onClick={() => onCloseConfirmation()}>
              {getResource('Button.Cancel')}
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        title={<h3 className="component-title">{getResource('Js_Message_SubmitSucessful')}</h3>}
        centered
        open={sucessModal}
        footer={null}
        onCancel={onCloseConfirmation}
        maskClosable={false}
        zIndex={1021}
        closable={false}
      >
        {getResource('Js_Message_SaveSuccessfully')}
        <div className="row-actions-w">
          <Button id="confirmation-OK" type="primary" onClick={onCloseConfirmation}>
            {getResource('Button.Ok')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ApprovalsNotification;
