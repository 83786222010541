import { genericCallWithBody, genericCallWithBodyAndResponseDecoded, genericGetWithParameters, genericGetWithParametersNoEncrypted } from './Networking';

export async function importInvoicesIssued(model: any) {
    var method = 'POST';
    var apiName = 'Invoices/ImportInvoicesIssued';
    console.log(model);
    let response = await genericCallWithBodyAndResponseDecoded(method, apiName, model);
    return response;
}

export async function saveInvoices(model: any) {
    var method = 'POST';
    var apiName = 'Invoices/Post';
    console.log(model);
    let response = await genericCallWithBody(method, apiName, model);
    return response;
}

export async function getInvoicesByDate(fromDate: any, toDate: any, customerID: string, invoiceType: string, status: string) {
    try {
        let params = {
            "fromDate": fromDate,
            "toDate": toDate,
            "customerID": customerID,
            "invoiceType": invoiceType,
            "status": status
        };
        let responseJWT = await genericGetWithParameters('Invoices/GetInvoicesIssuedByDate', params);
        return responseJWT
    } catch (error) {
        console.error(error);
    }
}

export async function getAttachmentInvoice(invoiceIssuedID: any) {
    try {
        let params = {
            'invoiceIssuedID': invoiceIssuedID,
        }
        let response = await genericGetWithParametersNoEncrypted('Invoices/GetAttachmentInvoice', params);
        return response;
    } catch (error) {
        console.error(error);
    }
}

export async function getInvoicesIssuedForImport(customerID: any) {
    try {
        let params = {
            "customerID": customerID
        };

        let responseJWT = await genericGetWithParameters('Invoices/GetInvoicesIssuedForImport', params);
        return responseJWT
    } catch (error) {
        console.error(error);
    }
}